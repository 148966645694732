import { environment } from 'environments/environment';
import {
  ValueList, StageList, IExpenseDivision,
  IExpenseDepartment, IExpensePaymentType, IIronApprovalStatus,
} from './common';

export const JobStatusInternalValues: ValueList[] = [
  { value: 'Invited', label: 'Invited' },
  { value: 'Decline', label: 'Decline' },
  { value: 'Accepted', label: 'Accepted' },
  { value: 'Assigned', label: 'Assigned' },
  { value: 'Started', label: 'Started' },
  { value: 'Delivered', label: 'Delivered' },
  { value: 'Completed', label: 'Completed' },
  { value: 'Invoiced', label: 'Invoiced' },
  { value: 'Paid', label: 'Paid' },
  { value: 'Closed', label: 'Closed' },
  { value: 'Cancelled', label: 'Cancelled' }
];

export const CaseCategoryFilterList: ValueList[] = [
  { value: '0121a0000006QndAAE', label: 'IT Service (3PS/FSE Dispatch) List' },
  { value: '0121a000000F1eoAAC', label: 'IT Service (3PS/FRU Dispatch) List' },
  // { value: '0121a0000006QniAAE', label: 'IT Service (3PS/FTE Dispatch) List' }
];

export const FTECaseCategoryFilterList: ValueList[] = [
  { value: 'IT Service (3PS) List', label: 'IT Service (3PS) List' },
  { value: 'Logistics (3PL) List', label: 'Logistics (3PL) List' }
];

export const FTEServiceContractCategoryFilterList: ValueList[] = [
  { value: 'All', label: 'All' },
  { value: 'All without HMS', label: 'All without HMS' },
  { value: 'FSE Contract', label: 'FSE Contract' },
  { value: 'FTE Contract - Fixed Hourly SO & Hourly PO - B1', label: 'FTE Contract - Fixed Hourly SO & Hourly PO - B1' },
  { value: 'FTE Contract - Monthly Fixed SO & Monthly PO - B2', label: 'FTE Contract - Monthly Fixed SO & Monthly PO - B2' },
  { value: 'FTE Contract - Monthly Fixed SO & Hourly PO - B3', label: 'FTE Contract - Monthly Fixed SO & Hourly PO - B3' },
  { value: 'FTE Contract - Monthly Hourly SO & Fixed PO - B4', label: 'FTE Contract - Monthly Hourly SO & Fixed PO - B4' },
  { value: 'Hardware Maintenance Support Service Contract (HMS)', label: 'Hardware Maintenance Support Service Contract (HMS)' },
  { value: 'Master', label: 'Master' }
];

export const PriorityFilterList: ValueList[] = [
  { value: 'P1 (SBD4H)', label: 'P1 (SBD4H)' },
  { value: 'P2 (SBD6H)', label: 'P2 (SBD6H)' },
  { value: 'P3 (NBD)', label: 'P3 (NBD)' },
  { value: 'P4 (2BD)', label: 'P4 (2BD)' },
  { value: 'P5 (3BD)', label: 'P5 (3BD)' },
  { value: 'P6 (5BD)', label: 'P6 (5BD)' },
  { value: 'BKFL', label: 'BKFL' },
  { value: 'NOBKFL', label: 'NOBKFL' }
];

export const EscalationTypeFilterList: ValueList[] = [
  { value: 'Job__c', label: 'Job' },
  { value: 'Work_Order__c', label: 'Work Order' },
  { value: 'PMS_Case__c', label: 'PMS Case' },
  { value: 'ICC_Case__c', label: 'ICC Case' },
  { value: 'Sales_Order__c', label: 'Sales Order' },
  { value: 'Purchase_Order__c', label: 'Purchase Order' },
];

export const JobTypesFilterList: ValueList[] = [
  { value: '0', label: 'FSE+FTEB (Dispatch FSE -All)<3PS/ICC Team>' },
  { value: '1', label: 'FTEP+FTEB (Primary+Backfill)<FTE Helpdesk>' },
  { value: '2', label: 'FSE Only (Dispatch FSE)<3PS/ICC Team>' },
  { value: '3', label: 'FTEB Only (FTE Backfill)<3PS/ICC Team>' },
  { value: '4', label: 'FTEP Only (Dedicated Primary)<FTE Helpdesk>' },
  { value: '5', label: 'xFRU only (Logistics 3PL/FRU)<3PL/ICC>' }
];

export const DispatchServiceResolutionStatuses: ValueList[] = [
  { value: 'New', label: 'New' },
  { value: 'In-Progress', label: 'In-Progress' },
  { value: 'Resolved', label: 'Resolved' },
  { value: 'Closed', label: 'Closed' }
];

export const PMSCaseStatus: ValueList[] = [
  { value: 'All', label: 'All'},
  { value: 'New', label: 'New' },
  { value: 'In Progress', label: 'In Progress' },
  { value: 'Pending (SLA Paused)', label: 'Pending (SLA Paused)'},
  { value: 'Resolved (Job Closed, PMS Open SLA Paused)', label: 'Resolved (Job Closed, PMS Open SLA Paused)'},
  { value: 'Resolved (Closed)', label: 'Resolved (Closed)' },
  { value: 'Cancelled (Closed) - PMS', label: 'Cancelled (Closed) - PMS' },
  { value: 'Cancelled (Closed) - MSP', label: 'Cancelled (Closed) - MSP' },
  { value: 'Cancellation Request - MSP', label: 'Cancellation Request - MSP'}
];

export const VisitIncompleteReasonCodes: ValueList[] = [
  { value: 'Customer not ready', label: 'Customer not ready' },
  { value: 'Customer rejected', label: 'Customer rejected' },
  { value: 'Emergency', label: 'Emergency' },
  { value: 'Missing Part', label: 'Missing Part' },
  { value: 'Missing Tool', label: 'Missing Tool' },
  { value: 'No access', label: 'No access' },
  { value: 'Weather hazard', label: 'Weather hazard' },
  { value: 'Other', label: 'Other' }
];
export const WorkerAppointmentConfirmation: ValueList[] = [
  { value: 'Pending', label: 'Pending' },
  { value: 'Rejected', label: 'Rejected' },
  { value: 'Approved', label: 'Approved' }
];

export const AppointmentScheduleCustomStatuses: ValueList[] = [
  { value: 'No or Phone Scheduling (1st Attempt)', label: 'No or Phone Scheduling (1st Attempt)' },
  { value: 'No or Phone Scheduling (2nd Attempt)', label: 'No or Phone Scheduling (2nd Attempt)' },
  { value: 'No or Phone Scheduling (3rd Attempt)', label: 'No or Phone Scheduling (3rd Attempt)' },
  { value: 'Other (Visit-not-Required)', label: 'Other (Visit-not-Required)' },
  { value: 'Yes', label: 'Yes' }
];

export const BackgroundColorCodes = [
  '#cfecfe',
  '#f6f6f6',
  '#f1effd',
  '#cfecfe',
  '#e8fdeb',
  '#efd3e7',
  '#c1dad6',
  '#f5fafa',
  '#acd1e9'
];

export const fileTypesOpenInBrowser = {
  'apng': 1,
  'bmp': 1,
  'gif': 1,
  'ico': 1,
  'cur': 1,
  'jpg': 1,
  'jpeg': 1,
  'jfif': 1,
  'pjpeg': 1,
  'pjp': 1,
  'png': 1,
  'svg': 1,
  'tif': 1,
  'tiff': 1,
  'webp': 1,
  'json': 1,
  'pdf': 1,
  'txt': 1
}


export const GovernanceDataObject = {
  'workOrderHealth': {
    title: 'Work Order Healthplan Status',
    data: [
      { label: 'Program Status', field: 'Health_SOP_Profile_Status__c' },
      { label: 'Customer Pricelist Available', field: 'Health_Pricelist_Status__c' },
      { label: 'Customer Price Available in Pricelist', field: 'Health_Customer_Price_Available__c' },
      { label: 'Vendor Price Available in Pricelist ', field: 'Health_Vendor_Price_Available__c' },
      { label: 'Appointment/Job Schedule Status', field: 'Health_Appointment_status__c' },
    ]
  },
  'pmsCaseHealth': {
    title: 'PMS Case Dispatch Health Check',
    data: [
      { label: '(A) Jobsite Selection Status', field: 'Health_Jobsite_Status__c' },
      { label: '(B) Project Selection Status', field: 'Health_Project_status__c' },
      { label: '(C) Order Item Selection Status', field: 'Health_OrderItem_Status__c' },
      { label: '(D) Approved Vendor Check Status', field: 'Health_Vendor_Status__c' },
      { label: '(E) Customer Pricelist Check Status', field: 'Health_Customer_Pricelist_Status__c' },
      { label: '(F) Vendor Pricelist Check Status', field: 'Health_Pricelist_Status__c' },
      { label: '(G) Partner Pricelist on Program Status', field: 'Health_Program_Pricelist__c' },
      { label: '(H) Asset SKU matchs Case SKU (Assetsite Case Only)', field: 'Health_Asset_Sku_Comparison__c' },
      { label: '(I) Jobsite Contact (Service Desk) Status', field: 'Health_SDesk_Contact__c' },
      { label: '(J) Jobsite Contact (Technical Escalation) Status', field: 'Health_TEsc_Contact__c' },
      { label: '(K) SKU Price availability in Pricelist', field: 'Health_SKU_Price_Status__c' },
    ]
  },
  'iccCaseHealth': {
    title: 'ICC Case Dispatch Health Check',
    data: [
      { label: '(A) Jobsite Selection Status', field: 'Health_Jobsite_Status__c' },
      { label: '(B) Project Selection Status', field: 'Health_Project_status__c' },
      { label: '(C) Order Item Selection Status', field: 'Health_OrderItem_Status__c' },
      { label: '(D) Approved Vendor Check Status', field: 'Health_Vendor_Status__c' },
      { label: '(E) Customer Pricelist Check Status', field: 'Health_Customer_Pricelist_Status__c' },
      { label: '(F) Vendor Pricelist Check Status', field: 'Health_Pricelist_Status__c' },
      { label: '(G) Partner Pricelist on Program Status', field: 'Health_Program_Pricelist__c' },
      { label: '(H) Asset SKU matchs Case SKU (Assetsite Case Only)', field: 'Health_Asset_Sku_Comparison__c' },
      { label: '(I) Jobsite Contact (Service Desk) Status', field: 'Health_SDesk_Contact__c' },
      { label: '(J) Jobsite Contact (Technical Escalation) Status', field: 'Health_TEsc_Contact__c' },
      { label: '(K) SKU Price availability in Pricelist', field: 'Health_SKU_Price_Status__c' },
    ]
  },
  'jobHealth': {
    title: 'Job Health Status',
    data: [
      { label: '(A) Vendor Assignment Status', field: 'Health_Vendor_Status__c' },
      { label: '(B) Project Availability Status', field: 'Health_Project_Status__c' },
      { label: '(C) Case Availability Status', field: 'Health_Case_Status__c' },
      { label: '(D) Jobsite Availability Status', field: 'Health_Jobsite_Status__c' },
      { label: '(E) Worker Assignment Status', field: 'Health_Worker_Status__c' },
      { label: '(F) List Price Availability Status', field: 'Health_List_Price_Status__c' },
      { label: '(G) FMS Submission Status', field: 'Health_FMS_Submission_Status__c' },
      { label: '(H) Job Completion Status', field: 'Health_Completion_Status__c' },
    ]
  }
};

export const MessageTypes: ValueList[] = [
  { value: 'dispatch-planner', label: 'Manager - Dispatch Planner' },
  { value: 'worker-visit-status', label: 'Work Status - Visit Update & Deliverables' },
  { value: 'pricing', label: 'Pricing - Service Quote & Price Book' },
  { value: 'billing', label: 'Billing - PO, Timecard, Invoice & Payment' }
];

export const JobStages: StageList[] = [
  { id: 1, value: 'invited', label: 'Invited' },
  { id: 2, value: 'accepted', label: 'Accepted' },
  { id: 3, value: 'assigned', label: 'Assigned' },
  { id: 4, value: 'started', label: 'Started' },
  { id: 5, value: 'pending', label: 'Pending' },
  { id: 6, value: 'invoiced', label: 'Invoiced' },
  { id: 7, value: 'paid', label: 'Paid' },
  { id: 8, value: 'closed', label: 'Closed' }
];


export const ServiceType: ValueList[] = [
  { value: 'Break/Fix Service', label: 'Break/Fix Service' },
  { value: 'Desk-side Support', label: 'Desk-side Support' },
  { value: 'IMAC', label: 'IMAC' }
];

export const allServiceType: ValueList[] = [
  { value: 'Break/Fix Service', label: 'Break/Fix Service' },
  { value: 'Desk-side Support Service', label: 'Desk-side Support Service' },
  { value: 'IMAC (SOP) Service', label: 'IMAC (SOP) Service' },
  { value: 'Audit Survey (SOP & Form) Service', label: 'Audit Survey (SOP & Form) Service' },
  { value: 'Project Management - "Onsite" Service', label: 'Project Management - "Onsite" Service' },
  { value: 'Project Management - "Remote" Service', label: 'Project Management - "Remote" Service' }
];



export const PaymentStatus: ValueList[] = [
  { value: 'Completed', label: 'Completed' },
  { value: 'Submitted', label: 'In-Progress' },
  { value: 'failed', label: 'failed' }
];

export const TimecardApprovedStatus: ValueList[] = [
  { value: 'Approved', label: 'Approved' },
  { value: 'Decline', label: 'Decline' },
  { value: 'Not Submitted', label: 'Not Submitted' },
];
export const TimecardFinalVisit: ValueList[] = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' }
];

export const ModelNames: ValueList[] = [
  { value: 'Job', label: 'Job' },
  { value: 'Case', label: 'Case' },
  { value: 'Account', label: 'Account' },
  { value: 'Worker', label: 'Worker' },
  { value: 'Program', label: 'Program' },
  { value: 'Project', label: 'Project' }
];

export const FileType = [
  { group: 'image', value: 'image/jpg', label: 'jpg' },
  { group: 'image', value: 'image/jpeg', label: 'jpeg' },
  { group: 'image', value: 'image/png', label: 'png' },
  { group: 'image', value: 'image/bmp', label: 'bmp' },
  { group: 'image', value: 'image/gif', label: 'gif' },
  { group: 'image', value: 'image/webp', label: 'webp' },
  { group: 'image', value: 'image/svg+xml', label: 'svg' },
  { group: 'text', value: 'text/plain', label: 'plainText' },
  { group: 'text', value: 'text/html', label: 'html' },
  { group: 'text', value: 'text/css', label: 'css' },
  { group: 'text', value: 'text/javascript', label: 'plain' },
  { group: 'text', value: 'text/x-log', label: 'X-log' },
  { group: 'application', value: 'application/octet-stream', label: 'octet-stream' },
  { group: 'application', value: 'application/pkcs12', label: 'pkcs12' },
  { group: 'application', value: 'application/vnd.mspowerpoint', label: 'mspowerpoint' },
  { group: 'application', value: 'application/xhtml+xml', label: 'xhtml+xml' },
  { group: 'application', value: 'application/xml', label: 'xml' },
  { group: 'application', value: 'application/pdf', label: 'pdf' },
  { group: 'application', value: 'application/vnd.oasis.opendocument.text', label: 'opendocument-text' },
  { group: 'application', value: 'application/vnd.ms-powerpoint', label: 'ms-powerpoint' },
  { group: 'application', value: 'application/msword', label: 'ms-word' },
  { group: 'application', value: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', label: 'document' },
  { group: 'application', value: 'application/vnd.oasis.opendocument.presentation', label: 'opendocument-presentation' },
  { group: 'application', value: 'application/vnd.openxmlformats-officedocument.presentationml.presentation', label: 'presentation' },
  { group: 'application', value: 'application/vnd.ms-excel', label: 'MS-excel' },
  { group: 'application', value: 'application/vnd.oasis.opendocument.spreadsheet', label: 'Opendocument-spreadsheet' },
  { group: 'application', value: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', label: 'spreadsheetml-sheet' },
  { group: 'application', value: 'application/rtf', label: 'RTF' },
  { group: 'audio', value: 'audio/midi', label: 'midi' },
  { group: 'audio', value: 'audio/mpeg', label: 'mpeg' },
  { group: 'audio', value: 'audio/webm', label: 'webm' },
  { group: 'audio', value: 'audio/ogg', label: 'ogg' },
  { group: 'audio', value: 'audio/wav', label: 'wav' },
  { group: 'video', value: 'video/webm', label: 'webm' },
  { group: 'video', value: 'video/ogg', label: 'ogg' }
]

export const FileTypeGroup = [
  { value: 'image/jpg;image/jpeg;image/png;image/bmp;image/gif;image/webp;image/svg+xml', label: 'image' },
  { value: 'text/plain;text/html;text/css;text/javascript;text/x-log', label: 'text' },
  {
    value: 'application/octet-stream;application/pkcs12;application/vnd.mspowerpoint;application/xhtml+xml;application/xml;application/pdf;' +
      'application/vnd.oasis.opendocument.text;application/vnd.ms-powerpoint;application/msword;application/vnd.openxmlformats-officedocument.wordprocessingml.document;' +
      'application/vnd.oasis.opendocument.presentation;application/vnd.openxmlformats-officedocument.presentationml.presentation;' +
      'application/vnd.ms-excel;application/vnd.oasis.opendocument.spreadsheet;application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' +
      'application/rtf', label: 'application'
  },
  { value: 'audio/midi;audio/mpeg;audio/webm;audio/ogg;audio/wav', label: 'audio' },
  { value: 'video/webm;video/ogg', label: 'video' },

]

export const ViewTypes = [
  {
    idx: 1,
    label: 'Jobsites View',
    value: 'jobsiteView',
    folderHiearchy: [
      {
        level: 'one',
        model: 'Account',
        loadFiles: false,
        loadFilesForModel: '',
        isUploadDisabled: true,
        isDetailDisabled: false
      },
      {
        level: 'two',
        model: 'Program',
        loadFiles: true,
        loadFilesForModel: 'Account',
        isUploadDisabled: false,
        isDetailDisabled: false
      },
      {
        level: 'three',
        model: 'Project',
        loadFiles: true,
        loadFilesForModel: 'Program',
        isUploadDisabled: false,
        isDetailDisabled: false
      },
      {
        level: 'four',
        model: 'static',
        loadFiles: false,
        loadFilesForModel: 'Project',
        isUploadDisabled: false,
        isDetailDisabled: true
      },
      {
        level: 'five',
        model: 'Job',
        loadFiles: false,
        loadFilesForModel: '',
        isUploadDisabled: true,
        isDetailDisabled: false
      },
      {
        level: 'five',
        model: 'Case',
        loadFiles: false,
        loadFilesForModel: '',
        isUploadDisabled: true,
        isDetailDisabled: false
      },
      {
        level: 'six',
        model: 'Document',
        loadFiles: true,
        loadFilesForModel: '',
        isUploadDisabled: false,
        isDetailDisabled: false
      }
    ]
  },
  {
    idx: 2,
    label: 'Department View',
    value: 'departmentView',
    folderHiearchy: [
      {
        level: 'one',
        model: 'Account',
        loadFiles: false,
        loadFilesForModel: '',
        isUploadDisabled: true,
        isDetailDisabled: false
      },
      {
        level: 'two',
        model: 'Program',
        loadFiles: true,
        loadFilesForModel: 'Account',
        isUploadDisabled: false,
        isDetailDisabled: false
      },
      {
        level: 'three',
        model: 'static',
        loadFiles: false,
        loadFilesForModel: '',
        isUploadDisabled: true,
        isDetailDisabled: true
      },
      {
        level: 'four',
        model: 'Project',
        loadFiles: true,
        loadFilesForModel: 'Program',
        isUploadDisabled: false,
        isDetailDisabled: false
      },
      {
        level: 'five',
        model: 'Job',
        loadFiles: false,
        loadFilesForModel: 'Project',
        isUploadDisabled: false,
        isDetailDisabled: false
      },
      {
        level: 'five',
        model: 'Case',
        loadFiles: false,
        loadFilesForModel: 'Project',
        isUploadDisabled: false,
        isDetailDisabled: false
      },
      {
        level: 'six',
        model: 'Document',
        loadFiles: true,
        loadFilesForModel: '',
        isUploadDisabled: false,
        isDetailDisabled: false
      }
    ]
  }
]

export const WorkerType: ValueList[] = [
  { value: 'Worker - Dispatch FE (Primary)', label: 'Worker - Dispatch FE (Primary)' },
  { value: 'Worker - Dispatch FE (Secondary 1)', label: 'Worker - Dispatch FE (Secondary 1)' },
  { value: 'Worker - Dispatch FE (Secondary 2)', label: 'Worker - Dispatch FE (Secondary 2)' },
  { value: 'Worker - Dispatch FE (Secondary 3)', label: 'Worker - Dispatch FE (Secondary 3)' },
  { value: 'Worker - Dispatch FE New', label: 'Worker - Dispatch FE New' },
  { value: 'FTE (With Backfill)', label: 'FTE (With Backfill)' },
  { value: 'FTE (Without Backfill)', label: 'FTE (Without Backfill)' },
  { value: 'FTE (Custom Backfill)', label: 'FTE (Custom Backfill)' },
  { value: 'PMS User', label: 'PMS User' },
  { value: 'MSP User', label: 'MSP User' },
  { value: 'VMS User', label: 'VMS User' }
];

export const WorkerTypeSub: ValueList[] = [
  { value: 'MSP', label: 'MSP' },
  { value: 'PMSA', label: 'PMSA' },
  { value: 'PMS', label: 'PMS' },
  { value: 'VMSA', label: 'VMSA' },
  { value: 'FSE', label: 'FSE' },
  { value: 'FTE', label: 'FTE' },
];

export const ServiceTechnicalLevel: ValueList[] = [
  { value: '', label: 'None' },
  { value: 'L1', label: 'L1' },
  { value: 'L2', label: 'L2' },
  { value: 'L3', label: 'L3' },
  { value: 'L4', label: 'L4' },
  { value: 'Not Applicable', label: 'Not Applicable' }
];

export const chatRoomTypes = [
  {
    idx: 0,
    id: 0,
    label1: 'All',
    label2: '',
    sfdcId: '',
    count: 0,
  },
  {
    idx: 1,
    id: 0,
    label1: 'Public',
    label2: '(PMS & MSP)',
    count: 0,
    value: '00094XX57',
    sfdcId: '',
    isPublic: true,
  },
  {
    idx: 2,
    id: 0,
    label1: 'Private PgMO & MVP',
    label2: '(PMC/Triage)',
    count: 0,
    value: '00094XX57',
    sfdcId: '',
    isPublic: false,
  },
  {
    idx: 3,
    id: 0,
    label1: 'Private',
    label2: '(3PS/ICC)',
    count: 0,
    value: '00094XX57',
    sfdcId: '',
    isPublic: false,
  },
  {
    idx: 4,
    id: 0,
    label1: 'Private',
    label2: '(3PL/ICC)',
    count: 0,
    value: '00094XX54',
    sfdcId: '',
    isPublic: false,
  },
  {
    idx: 5,
    id: 0,
    label1: 'Public',
    label2: '(VMS/3PS/Job)',
    count: 0,
    value: '00094XX55',
    sfdcId: '',
    isPublic: false,
  },
  {
    idx: 6,
    id: 0,
    label1: 'Private',
    label2: '(3PL/Job)',
    count: 0,
    value: '00094XX56',
    sfdcId: '',
    isPublic: false,
  },
  {
    idx: 7,
    id: 0,
    label1: 'Private',
    label2: '(3PS/SO/SL/SP)',
    count: 0,
    value: '00094XX57',
    sfdcId: '',
    isPublic: false,
  },
  {
    idx: 8,
    id: 0,
    label1: 'Private',
    label2: '(3PL/SO/SL/SP)',
    count: 0,
    value: '00094XX58',
    sfdcId: '',
    isPublic: false,
  },
  {
    idx: 9,
    id: 0,
    label1: 'Private',
    label2: '(3PS/PO/PI/PP)',
    count: 0,
    value: '00094XX59',
    sfdcId: '',
    isPublic: false,
  },
  {
    idx: 10,
    id: 0,
    label1: 'Private',
    label2: '(3PL/PO/PI/PP)',
    count: 0,
    value: '00094XX10',
    sfdcId: '',
    isPublic: false,
  },
];

export const sCategoryCaseType: StageList[] = [
  { id: 1, value: 'Field Service Engineer Only (FRU Parts Not Required)', label: 'Field Service Engineer Only (FRU Parts Not Required)' },
  { id: 2, value: 'FRU Part Only (Field Service Engineer Not Required)', label: 'FRU Part Only (Field Service Engineer Not Required)' },
  { id: 3, value: 'Field Service Engineer & FRU Parts - Both (Default)', label: 'Field Service Engineer & FRU Parts - Both' },
  { id: 4, value: 'FRU Parts WSL Transfer (Field Service Engineer Not required)', label: 'FRU Parts WSL Transfer (Field Service Engineer Not required)' }
];

export const serviceCatelogSelection: ValueList[] = [
  { value: 'Standard', label: 'Standard Incident Service Profile: Use Available Service Item Request'}, 
  { value: 'Custom', label: 'Custom Incident Service Profile: Create Custom Service Item Request' }
];

export const extendedCoveragePlans: ValueList[] = [
  { value: '24x7-9H5D', label: 'Extended Coverage Plan - Standard Business Hours Coverage Plan (24x7-9H5D): Monday-Friday, 9am to 5pm' },
  { value: '24x7-DAY', label: 'After Hours Coverage (Monday-Friday, 5pm to 9am, 24x7), 24x7-AFTH' },
  { value: '24x7-AFTH', label: 'Extended Coverage Plan - After Hours Coverage (24x7-AFTH): Monday-Friday, 5pm to 9am' },
  { value: '24x7-WKND', label: 'Extended Coverage Plan - Weekend Hours Coverage (24x7-WKND)' },
  { value: '24x7-HLDY', label: 'Extended Coverage Plan - Holiday Hours Coverage (24x7-HLDY)' }
];

export const serviceCatelogLogisticsSelection: ValueList[] = [
  { value: '1', label: 'Standard Incident Service Profile: Pre-Registered Assets, Use Standard FRU Catalog' },
  { value: '2', label: 'Standard Incident Service Profile: Custom Assets, Use Standard FRU Catalog' },
  { value: '3', label: 'Custom Incident Service Profile: No Assets, Enter FRU Information Manually' }
];

export const appointmentServiceVisitType: StageList[] = [
  { id: 1, value: 'Single Schedule, Field Service Engineer (FSE)', label: 'Single Schedule, Field Service Engineer (FSE)' },
  { id: 2, value: 'Single Schedule, FRU Item Delivery', label: 'Single Schedule, FRU Item Delivery' },
  { id: 3, value: 'Single Schedule, Field Service Engineer (FSE) & FRU Item Delivery', label: 'Single Schedule, Field Service Engineer (FSE) & FRU Item Delivery' },
  { id: 4, value: 'Custom Schedule, Field Service Engineer (FSE) & FRU Item Delivery', label: 'Custom Schedule, Field Service Engineer (FSE) & FRU Item Delivery' }
];

// export const  appointmentCalndarArrival: ValueList[] = [
//     { value: '1', label: 'Customer Appointment Setup Request (Iron Service Global to Call Customer)' },
//     { value: '2', label: 'Dispatch Now (SLA Priority: SBD)' },
//     { value: '3', label: 'Pre-Scheduled Appointment(Enter Date/Time (Local Time) below)' }
// ];
export const regionList = [
  { 'sfdcId': 'Africa', 'Name': 'Africa' },
  { 'sfdcId': 'APAC', 'Name': 'APAC' },
  { 'sfdcId': 'EU', 'Name': 'EU' },
  { 'sfdcId': 'LATAM', 'Name': 'LATAM' },
  { 'sfdcId': 'Middle-East', 'Name': 'Middle-East' },
  { 'sfdcId': 'Rest Of Europe', 'Name': 'Rest Of Europe' },
  { 'sfdcId': 'USCA', 'Name': 'USCA' },
  { 'sfdcId': 'EMEA', 'Name': 'EMEA' },
];

export const CaseProgressData = [
  { title: 'Work Orders', class: 'disabled' },
  { title: 'Cases', class: 'disabled' },
  { title: 'Job', class: 'disabled' },
  { title: 'Assigned', class: 'disabled' },
  { title: 'Started', class: 'disabled' },
  { title: 'Delivered', class: 'disabled' },
  { title: 'Completed', class: 'disabled' },
  { title: 'Invoiced', class: 'disabled' },
  { title: 'Paid', class: 'disabled' },
  { title: 'Closed', class: 'disabled' },
  { title: 'Cancelled', class: 'disabled' }
];

export const hiddenGlobalFiltersWo = ['vendorAccounts', 'talentTypes', 'jobs', 'jobStatus', 'jobTypes', 'employeeType', 'vendorSites', 'recordTypeIds',
  'caseCategory', 'geoMetro', 'geoMetroJobSite', 'workerNames', 'teamShifts', 'teamShiftUsers', 'regions', 'countries', 'orderTypes', 'escalationType',
  'serviceContractNumbers', 'serviceContractCategory'];

export const projectTypeValues: ValueList[] = [
  // { value: '', label: 'All' },
  // { value: 'Account', label: 'Account' },
  // { value: 'Sales', label: 'Sales' },
  // { value: 'FSD', label: 'Field Service Delivery' },
  // { value: 'Others', label: 'Others' },
  // { value: 'HelpDesk', label: 'HelpDesk' },
  // { value: 'ServiceManager', label: 'Service Manager' },
  // { value: 'BillingManager', label: 'Billing Manager' },
  // { value: 'ProblemManagement', label: 'Problem Management' },
  { value: 'TaskCase', label: 'Project Manager Console (Task2Case)' },
  { value: 'ServiceManager', label: 'Service Manager Console (Case2Order)' },
  { value: 'BillingManager', label: 'Billing Manager Console (Order2Order & Procure2Pay)' },
  { value: 'ChangeManagementConsoleCARPARCorrectionAction', label: 'Change Management Console (CAR/PAR & Correction Action)' },
  { value: 'HelpDesk', label: 'Help Desk Manager Console' },
  { value: 'ProblemManagement', label: 'Problem Management Console' },
  { value: 'RecruitmentManager', label: 'Recruitment Manager Console' },
  { value: 'OKR', label: 'Objectives and Key Results (OKR)' },
  { value: 'GlobalMyTaskManager', label: 'Global MyTask Manager Console' },
  { value: 'Sales', label: 'Sales Opportuntity Manager Console' },
  { value: 'AccountRevenueForecast', label: 'Account Revenue Forecast Console' },
  { value: 'VCSQD3PS', label: 'Vendor Manager - Procurement (RFx) (VMS/3PS)' },
  { value: 'ISGEntity', label: 'Entity Registration ISG Console' },
  { value: 'FSD', label: 'Field Service Delivery (Manual-Jinder)' },
  { value: 'Account', label: 'Account Management (TBD)' },
  { value: 'ProjectManager', label: 'Project Manager' },
  { value: 'Others', label: 'All Other Projects' },
];

export const limitedProjectTypeValues: ValueList[] = [
  { value: 'HelpDesk', label: 'Help Desk Manager Console' },
  { value: 'ProblemManagement', label: 'Problem Management Console' },
  { value: 'RecruitmentManager', label: 'Recruitment Manager Console' },
  { value: 'EntityRegistrationManager', label: 'Entity Registration Manager Console' },
  { value: 'MyObjective', label: 'My Objective Console' },
  { value: 'ProcurementRFxVendorManager', label: 'Procurement RFx Vendor Manager Console' },
  { value: 'ExpenseManager', label: 'Expense Manager Console' },
  { value: 'BillPayManager', label: 'Bill Pay Manager Console' },
  { value: 'AccountRevenueForecastManager', label: 'Account Revenue Forecast Manager Console' },
  { value: 'SalesOpportunityManager', label: 'Sales Opportunity Manager Console' },
  { value: 'WorkforceSupplyChainManager', label: 'Workforce Supply Chain Manager Console' },
  { value: 'TaskCase', label: 'Project Manager Console (Task2Order)' },
  { value: 'BillingManager', label: 'Billing Manager Console' },
  { value: 'ServiceManager', label: 'Service Manager Console' },
  { value: 'MWPOpportunity', label: 'MWP Opportunity' },
];

export const taskTypeValues: ValueList[] = [

  { value: 'Issue or Bug (& CAR)', label: 'Issue or Bug (& CAR)' },
  { value: 'Enhancement (& PAR)', label: 'Enhancement (& PAR)' },
  { value: 'Problem', label: 'Problem' },
  { value: 'Case (Incident)', label: 'Case (Incident)' },
  { value: 'Case (Task)', label: 'Case (Task)' },
  { value: 'Task (Action)', label: 'Task (Action)' },
  { value: 'Task (Enhancement)', label: 'Task (Enhancement)' },
  { value: 'Task (Issue)', label: 'Task (Issue)' },
  { value: 'Escalate (Problem)', label: 'Escalate (Problem)' },
  { value: 'Escalate (Change)', label: 'Escalate (Change)' },
  { value: 'RFI', label: 'RFI' },
  { value: 'RFP', label: 'RFP' },
  { value: 'RFQ', label: 'RFQ' },
  { value: 'Expense', label: 'Expense' },
  { value: 'Bill Pay', label: 'Bill Pay' },
  { value: 'Worker Availability (WAR) Request', label: 'Worker Availability (WAR) Request' },
  { value: 'Worker MyAvailbility (WMA) Request', label: 'Worker MyAvailbility (WMA) Request' },
  { value: 'Worker MySchedule (WMS) Request', label: 'Worker MySchedule (WMS) Request' },
  { value: 'Offer Request', label: 'Offer Request' }
];
export const taskPriorityValues: ValueList[] = [

  { value: 'Very Critical (P0)', label: 'Very Critical (P0)' },
  { value: 'Critical (P1)', label: 'Critical (P1)' },
  { value: 'High (P2)', label: 'High (P2)' },
  { value: 'Medium (P3)', label: 'Medium (P3)' },
  { value: 'Low (P4)', label: 'Low (P4)' },
  { value: 'Very Low (P5)', label: 'Very Low (P5)' }
];
export const taskStatusValues: ValueList[] = [

  { value: 'AllOpen', label: 'All Open Tasks' },
  { value: 'AllCompleted', label: 'All Completed Tasks' },
  { value: 'New (1)', label: 'New (1)' },
  { value: 'Need More Info (1)', label: 'Need More Info (1)' },
  { value: 'Assigned (Not Started) (2)', label: 'Assigned (Not Started) (2)' },
  { value: 'Assigned (On Hold) (3)', label: 'Assigned (On Hold) (3)' },
  { value: 'In Progress (4)', label: 'In Progress (4)' },
  { value: 'Delivered (Ready for Review) (5)', label: 'Delivered (Ready for Review) (5)' },
  { value: 'Delivered (Review in Progress) (5)', label: 'Delivered (Review in Progress) (5)' },
  { value: 'Delivered (Review Completed) (6)', label: 'Delivered (Review Completed) (6)' },
  { value: 'Delivered (Review Failed) (6)', label: 'Delivered (Review Failed) (6)' },
  { value: 'Completed (Awaiting Close) (7)', label: 'Completed (Awaiting Close) (7)' },
  { value: 'Completed (Closed) (7)', label: 'Completed (Closed) (7)' }
];
export const problemManagementStatusValues: ValueList[] = [
  { value: 'AllOpen', label: 'All Open Tasks' },
  { value: 'AllCompleted', label: 'All Completed Tasks' },
  { value: 'New (1)', label: 'New (1)' },
  { value: 'Need More Info (1)', label: 'Need More Info (1)' },
  { value: 'Assigned (Not Started) (2)', label: 'Assigned (Not Started) (2)' },
  { value: 'Assigned (On Hold) (2)', label: 'Assigned (On Hold) (2)' },
  { value: 'Assigned (In Progress) (2)', label: 'Assigned (In Progress) (2)' },
  { value: 'Assigned (Challenged) (2)', label: 'Assigned (Challenged) (2)' },
  { value: 'Contained (Resolved, No Change) (4)', label: 'Contained (Resolved, No Change) (4)' },
  { value: 'Contained (Resolved, Change Required) (4)', label: 'Contained (Resolved, Change Required) (4)' },
  { value: 'Contained (Resolved, Change Purposed) (4)', label: 'Contained (Resolved, Change Purposed) (4)' },
  { value: 'Change Approval (Ready for Review) (5)', label: 'Change Approval (Ready for Review) (5)' },
  { value: 'Change Approval (Review in Progress) (5)', label: 'Change Approval (Review in Progress) (5)' },
  { value: 'Change Approval (Review Failed) (5)', label: 'Change Approval (Review Failed) (5)' },
  { value: 'Change Approval (Completed) (5)', label: 'Change Approval (Completed) (5)' },
  { value: 'Implementation (Not Started) (6)', label: 'Implementation (Not Started) (6)' },
  { value: 'Implementation (In Progress) (6)', label: 'Implementation (In Progress) (6)' },
  { value: 'Completed (Awaiting Close) (7)', label: 'Completed (Awaiting Close) (7)' },
  { value: 'Completed (Closed) (7)', label: 'Completed (Closed) (7)' }
];

export const taskRequestorValues: ValueList[] = [

  { value: 'All Requested By Me', label: 'All Requested By Me' },
  { value: 'Requested by My Team', label: 'Requested by My Team' }
];

export const EmployeeTypeValues: ValueList[] = [
  { value: 'internal', label: 'Internal' },
  { value: 'partner', label: 'Partner' },
  { value: 'vendor', label: 'Vendor' },
];
export const yearValues: ValueList[] = [
  { value: '', label: 'All' },
  { value: '2017', label: '2017' },
  { value: '2018', label: '2018' },
  { value: '2019', label: '2019' },
  { value: '2020', label: '2020' },
  { value: '2021', label: '2021' },
  { value: '2022', label: '2022' },
  { value: '2023', label: '2023' },
  { value: '2024', label: '2024' },
  { value: '2025', label: '2025' },
  { value: '2026', label: '2026' },
  { value: '2027', label: '2027' },
  { value: '2028', label: '2028' },
  { value: '2029', label: '2029' },
  { value: '2030', label: '2030' },
];
export const monthValues: ValueList[] = [
  { value: '', label: 'All' },
  { value: 'Jan', label: 'Jan' },
  { value: 'Feb', label: 'Feb' },
  { value: 'Mar', label: 'Mar' },
  { value: 'Apr', label: 'Apr' },
  { value: 'Jun', label: 'Jun' },
  { value: 'Jul', label: 'Jul' },
  { value: 'Aug', label: 'Aug' },
  { value: 'Sep', label: 'Sep' },
  { value: 'Oct', label: 'Oct' },
  { value: 'Nov', label: 'Nov' },
  { value: 'Dec', label: 'Dec' },
];

export const expenseFormDepartments: IExpenseDepartment[] = [
  { label: '(IT) Information Technology', value: '(IT) Information Technology' },
  { label: '(SLS) Sales', value: '(SLS) Sales' },
]

export const expensePaymentType: IExpensePaymentType[] = [
  { label: 'Company Paid (Taxed)', value: 'Company Paid (Taxed)' },
  { label: 'Out-of-pocket (Taxed)', value: 'Out-of-pocket (Taxed)' },
  { label: 'Pending Card Transaction (Taxed)', value: 'Pending Card Transaction (Taxed)' },
  { label: 'Company Paid (Non-Taxed)', value: 'Company Paid (Non-Taxed)' },
  { label: 'Out-of-pocket (Non-Taxed)', value: 'Out-of-pocket (Non-Taxed)' },
  { label: 'Pending Card Transaction (Non-Taxed)', value: 'Pending Card Transaction (Non-Taxed)' },
]

export const ironApprovalStatus: IIronApprovalStatus[] = [
  { label: 'Approved', value: 'Approved' },
  { label: 'Cancelled', value: 'Cancelled' },
  { label: 'In-Progress', value: 'In-progress' },
  { label: 'Not Submitted', value: 'Not Submitted' },
  { label: 'Rejected', value: 'Rejected' },
  { label: 'Submitted', value: 'Submitted' },
  { label: 'Paid', value: 'Paid' }
]
export const ironApprovalStatusValues: ValueList[] = [
  { value: 'In-Progress', label: 'In-Progress' },
  { value: 'Submitted', label: 'Submitted' },
  { value: 'Approved', label: 'Approved' },
  { value: 'Rejected', label: 'Rejected' },
  { value: 'Paid', label: 'Paid' },
  { value: 'Cancelled', label: 'Cancelled' },
  { value: 'Not Submitted', label: 'Not Submitted' }
];

export const customerApprovalStatusValues: ValueList[] = [

  { value: 'Not Submitted', label: 'Not Submitted' },
  { value: 'Submitted', label: 'Submitted' },
  { value: 'Approved', label: 'Approved' },
  { value: 'Rejected', label: 'Rejected' },
];

export const actualCoverageHourValues: ValueList[] = [

  { value: '9H5D', label: '9H5D' },
  { value: 'AFTH', label: 'AFTH' },
  { value: 'HLDY', label: 'HLDY' },
  { value: 'WKND', label: 'WKND' },
];

export const workerBillingTypeValues: ValueList[] = [

  { value: 'W2', label: 'W2' },
  { value: '1099', label: '1099' },
  { value: 'Other', label: 'Other' }
];

export const divisionList = [
  { 'sfdcId': 'EMEA', 'Name': 'EMEA' },
  { 'sfdcId': 'North America', 'Name': 'North America' },
  { 'sfdcId': 'South America', 'Name': 'South America' },
  { 'sfdcId': 'Asia', 'Name': 'Asia' },
  { 'sfdcId': 'AAPAC', 'Name': 'AAPAC' },
  { 'sfdcId': 'ANZ', 'Name': 'ANZ' }
];

export const departmentList = [
  { 'sfdcId': '(ACCNT) Accounting', 'Name': '(ACCNT) Accounting' },
  { 'sfdcId': '(IT) Information Technology', 'Name': '(IT) Information Technology' },
  { 'sfdcId': '(MKTN) Marketing', 'Name': '(MKTN) Marketing' },
  { 'sfdcId': '(OPS) Operations', 'Name': '(OPS) Operations' },
  { 'sfdcId': '(RND) Research & Development', 'Name': '(RND) Research & Development' },
  { 'sfdcId': '(SLS) Sales', 'Name': '(SLS) Sales' },
  { 'sfdcId': '(SUPP) Support', 'Name': '(SUPP) Support' }
];

export const paymentTypeList = [
  { 'sfdcId': 'Company Paid (Taxed)', 'Name': 'Company Paid (Taxed)' },
  { 'sfdcId': 'Out-of-Pocket (Taxed)', 'Name': 'Out-of-Pocket (Taxed)' },
  { 'sfdcId': 'Pending Card Transaction (Taxed)', 'Name': 'Pending Card Transaction (Taxed)' },
  { 'sfdcId': 'Company Paid (Non-Taxed)', 'Name': 'Company Paid (Non-Taxed)' },
  { 'sfdcId': 'Out-of-Pocket (Non-Taxed)', 'Name': 'Out-of-Pocket (Non-Taxed)' },
  { 'sfdcId': 'Pending Card Transaction (Non-Taxed)', 'Name': 'Pending Card Transaction (Non-Taxed)' }
];

export const expenseTypeValues = [

  {
    value: 'Travel',
    label: 'Travel',
    expenseSubType: [
      {
        value: 'Car Rental', label: 'Car Rental'
      },
      {
        value: 'Hotel', label: 'Hotel'
      },
      {
        value: 'Airfare', label: 'Airfare'
      }]
  },
  {
    value: 'Transportation',
    label: 'Transportation',
    expenseSubType: [
      {
        value: 'Fuel', label: 'Fuel'
      },
      {
        value: 'Parking', label: 'Parking'
      },
      {
        value: 'Personal Car Mileage', label: 'Personal Car Mileage'
      },
      {
        value: 'Taxi', label: 'Taxi'
      },
      {
        value: 'Tolls/Road Charges', label: 'Tolls/Road Charges'
      },
      {
        value: 'Train', label: 'Train'
      }]
  },
  {
    value: 'Meals and Entertainment',
    label: 'Meals and Entertainment',
    expenseSubType: [
      {
        value: 'Breakfast', label: 'Breakfast'
      },
      {
        value: 'Business Meals (Attendees)', label: 'Business Meals (Attendees)'
      },
      {
        value: 'Dinner', label: 'Dinner'
      },
      {
        value: 'Lunch', label: 'Lunch'
      }]
  },
  {
    value: 'Office Expenses',
    label: 'Office Expenses',
    expenseSubType: [
      {
        value: 'Office Supplies/Software', label: 'Office Supplies/Software'
      }]
  },
  {
    value: 'Communications',
    label: 'Communications',
    expenseSubType: [
      {
        value: 'Internet/Online Fees', label: 'Internet/Online Fees'
      },
      {
        value: 'Mobile/Cellular Phone', label: 'Mobile/Cellular Phone'
      }]
  },
  {
    value: 'Miscellaneous',
    label: 'Miscellaneous',
    expenseSubType: [
      {
        value: 'Miscellaneous', label: 'Miscellaneous'
      }]
  }
]

export const expenseDetailList = [

  {
    value: 'Airfare', label: 'Airfare'
  },
  {
    value: 'Fuel/Car Mileage', label: 'Fuel/Car Mileage'
  },
  {
    value: 'Parking', label: 'Parking'
  },
  {
    value: 'Telephones Expense', label: 'Telephones Expense'
  },
  {
    value: 'Internet Expense', label: 'Internet Expense'
  },
  {
    value: 'Tolls/Road Charges', label: 'Tolls/Road Charges'
  },
  {
    value: 'Breakfast', label: 'Breakfast'
  },
  {
    value: 'Lunch', label: 'Lunch'
  },
  {
    value: 'Dinner', label: 'Dinner'
  },
  {
    value: 'Business Meals (Attendees)', label: 'Business Meals (Attendees)'
  },
  {
    value: 'Office Supplies/Software', label: 'Office Supplies/Software'
  },
  {
    value: 'Tools & Materials', label: 'Tools & Materials'
  },
  {
    value: 'Taxi', label: 'Taxi'
  },
  {
    value: 'Train', label: 'Train'
  },
  {
    value: 'Bus', label: 'Bus'
  },
  {
    value: 'Hotel/Accommodation', label: 'Hotel/Accommodation'
  },
  {
    value: 'Education & Training', label: 'Education & Training'
  },
  {
    value: 'Miscellaneous Expenses', label: 'Miscellaneous Expenses'
  },
  {
    value: 'BGV Charges', label: 'BGV Charges'
  }
]

export const invoiceStatus: ValueList[] = [
  { value: 'Invoice Created', label: 'Invoice Created' },
  { value: 'Submitted for Review', label: 'Submitted for Review' },
  { value: 'Invoice Reviewed', label: 'Invoice Reviewed' },
  { value: 'Submitted for Approval', label: 'Submitted for Approval' },
  { value: 'Invoice Approved', label: 'Invoice Approved' },
  { value: 'Payment Done', label: 'Payment Done' },
  { value: 'Payment Received', label: 'Payment Received' },
  { value: 'Invoice Rejected', label: 'Invoice Rejected' }
];
export const accountCommunityValues: ValueList[] = [
  { value: 'Partner', label: 'PMS' },
  { value: 'internal', label: 'MSP' },
  { value: 'Vendor', label: 'VMS' }
];

export const DepartmentFullList: ValueList[] = [
  { value: '(ACCNT) Accounting', label: '(ACCNT) Accounting' },
  { value: '(IT) Information Technology', label: '(IT) Information Technology' },
  { value: '(MKTN) Marketing', label: '(MKTN) Marketing' },
  { value: '(OPS) Operations', label: '(OPS) Operations' },
  { value: '(RND) Research Development', label: '(RND) Research Development' },
  { value: '(SLS) Sales', label: '(SLS) Sales' },
  { value: '(SUPP) Support', label: '(SUPP) Support' }
]

export const expenseDivisions: IExpenseDivision[] = [
  { label: 'EMEA', value: 'EMEA', department: expenseFormDepartments },
  { label: 'North America', value: 'North America', department: DepartmentFullList },
  { label: 'South America', value: 'South America', department: expenseFormDepartments },
  { label: 'Asia', value: 'Asia', department: DepartmentFullList },
  { label: 'AAPAC', value: 'AAPAC', department: expenseFormDepartments },
  { label: 'ANZ', value: 'ANZ', department: expenseFormDepartments },
]

export const ActivityType: ValueList[] = [
  { value: 'Case', label: 'Case' },
  { value: 'Task (Non-Case)', label: 'Task (Non-Case)' },
];
export const customStdCoveragePlans: ValueList[] = [
  { value: '9H5D1', label: 'Standard Business Hours Coverage Plan: Monday-Friday, 9am to 5pm (9H5D)' }
];

export const customExtCoveragePlans: ValueList[] = [
  { value: '9H5D', label: 'Extended Coverage Plan - Standard Business Hours Coverage Plan (24x7-9H5D): Monday-Friday, 9am to 5pm' },
  { value: '24x7-AFTH', label: 'Extended Coverage Plan - After Hours Coverage (24x7-AFTH): Monday-Friday, 5pm to 9am' },
  { value: '24x7-WKND', label: 'Extended Coverage Plan - Weekend Hours Coverage (24x7-WKND)' },
  { value: '24x7-HLDY', label: 'Extended Coverage Plan - Holiday Hours Coverage (24x7-HLDY)' }
];

export const SLAList = [
  {
    value: 1,
    title: "2BD"
  },
  {
    value: 2,
    title: "NBD"
  },
  {
    value: 3,
    title: "5BD"
  },
  {
    value: 4,
    title: "3BD"
  },
  {
    value: 5,
    title: "SBD6H"
  },
  {
    value: 6,
    title: "SBD4H"
  },
  {
    value: 7,
    title: "SBD2H"
  }
];

export const slaValueLabelList: Array<ValueList> = [
  { value: 'NBD', label: 'NBD' },
  { value: '2BD', label: '2BD' },
  { value: '3BD', label: '3BD' },
  { value: '5BD', label: '5BD' },
  { value: 'SBD2H', label: 'SBD2H' },
  { value: 'SBD4H', label: 'SBD4H' },
  { value: 'SBD6H', label: 'SBD6H' },
];

export const coverageHoursList = [
  { value: "", label: "None" },
  { value: "9H5D", label: "9H5D" },
  { value: "AFTH", label: "AFTH" },
  { value: "WKND", label: "WKND" },
  { value: "HLDY", label: "HLDY" },
  { value: "24x7-DAY", label: "24x7-DAY" }
];

export const talentLevelList = [
  { value: "L1", label: "L1" },
  { value: "L2", label: "L2" },
  { value: "", label: "Not Applicable" }
]
export const serviceContractCategoryValue: ValueList[] = [
  { value: 'All without HMS', label: 'All without HMS' },
  { value: '0121a0000006S20AAE', label: 'FSE Contract' },
  { value: '0121a000000VlnzAAC', label: 'FTE Contract - Fixed Hourly SO & Hourly PO - B1' },
  { value: '0121a0000006S1vAAE', label: 'FTE Contract - Monthly Fixed SO & Hourly PO - B3' },
  { value: '0121a000000VlhQAAS', label: 'FTE Contract - Monthly Fixed SO & Monthly Fixed PO - B2' },
  { value: '0121a000000F1LDAA0', label: 'FTE Contract - Monthly Hourly SO & Fixed PO - B4' },
  { value: '0121a0000006SHjAAM', label: 'Hardware Maintenance Support Service Contract (HMS)' },
  { value: '012000000000000AAA', label: 'Master' }
];

export const vendorType: ValueList[] = [
  { value: '3PS', label: '3PS' },
  { value: '3PL/FSL', label: '3PL/FSL' },
  { value: 'FSL Vendor', label: 'FSL Vendor' },
];

export const AccountCurrency: ValueList[] = [
  { value: 'AUD', label: 'Australian Dollar' },
  { value: 'BRL', label: 'Brazilian Real' },
  { value: 'CAD', label: 'Canadian Dollar' },
  { value: 'CHF', label: 'Swiss Franc' },  
  { value: 'DKK', label: 'Danish Krone' },
  { value: 'EUR', label: 'Euro' },  
  { value: 'GBP', label: 'British Pound' },
  { value: 'HKD', label: 'Hong Kong Dollar' },
  { value: 'IDR', label: 'Indonesian Rupiah' },
  { value: 'INR', label: 'Indian Rupee' },
  { value: 'JPY', label: 'Japanese Yen' },
  { value: 'MYR', label: 'Malaysian Ringgit' },
  { value: 'NOK', label: 'Norwegian Krone' },
  { value: 'PHP', label: 'Philippine Peso' },
  { value: 'PLN', label: 'Polish Zloty' },
  { value: 'SAR', label: 'Saudi Arabian Riyal' },
  { value: 'SEK', label: 'Swedish Krona' },
  { value: 'SGD', label: 'Singapore Dollar' },
  { value: 'THB', label: 'Thai Baht' },
  { value: 'USD', label: 'U.S. Dollar' },
  { value: 'ZAR', label: 'South African Rand' }
]

export const timesheetOffset: ValueList[] = [
  { value: '00', label: '00' },
  { value: '15', label: '15' },
  { value: '30', label: '30' },
]

export const accountCommunityType: ValueList[] = [
  { value: 'MSP', label: 'MSP' },
  { value: 'Partner', label: 'Partner' },
  { value: 'Partner-Customer', label: 'Partner-Customer' },
  { value: 'Vendor', label: 'Vendor' }
]

export const engagementType: ValueList[] = [
  { value: 'Freelance Consultant', label: 'Freelance Consultant' },
  { value: 'Full-Time FTE', label: 'Full-Time FTE' },
  { value: 'Part-Time FSE', label: 'Part-Time FSE' },
  { value: 'Other', label: 'Other' }
]

export const vendorSubCommunityType: ValueList[] = [
  { value: 'VMS', label: 'VMS' },
  { value: 'FMS', label: 'VFMS' }
]

export const affiliatedType: ValueList[] = [
  { value: 'Parent', label: 'Parent' },
  { value: 'Subsidiary', label: 'Subsidiary' },
  { value: 'Affiliate', label: 'Affiliate' },
]

export const bankAccountType: ValueList[] = [
  { value: 'Savings', label: 'Savings' },
  { value: 'Current', label: 'Current' },
  { value: 'Checking', label: 'Checking' },
]

export const vendorRegion: ValueList[] = [
  { value: 'EMEA', label: 'EMEA' },
  { value: 'LATAM', label: 'LATAM' },
  { value: 'USCA', label: 'USCA' },
  { value: 'APAC', label: 'APAC' },
  { value: 'JAPAN', label: 'JAPAN' }
]

export const businessType: ValueList[] = [
  { value: 'Sole Proprietorship', label: 'Sole Proprietorship' },
  { value: 'Parntership Firm', label: 'Parntership Firm' },
  { value: 'Company', label: 'Company' },
  { value: 'Person/Individual', label: 'Person/Individual' },
  { value: 'Trust (Body of Individuals)', label: 'Trust (Body of Individuals)' }
]

export const registrationStatus: ValueList[] = [
  { value: 'Prospect', label: 'Prospect' },
  { value: 'Listed', label: 'Listed' },
  { value: 'Registered', label: 'Registered' },
  { value: 'Qualified', label: 'Qualified' },
  { value: 'Trained', label: 'Trained' },
  { value: 'Certified Pool', label: 'Certified Pool' },
  { value: 'IRON Dispatch Pool', label: 'IRON Dispatch Pool' }
]

export const accountSource: ValueList[] = [
  { value: 'Advertisement', label: 'Advertisement' },
  { value: 'Employee Referral', label: 'Employee Referral' },
  { value: 'External Referral', label: 'External Referral' },
  { value: 'Partner', label: 'Partner' },
  { value: 'Public Relations', label: 'Public Relations' },
  { value: 'Seminar - Internal', label: 'Seminar - Internal' },
  { value: 'Seminar - Partner', label: 'Seminar - Partner' },
  { value: 'Trade Show', label: 'Trade Show' },
  { value: 'Web', label: 'Web' },
  { value: 'Word of mouth', label: 'Word of mouth' },
  { value: 'Other', label: 'Other' },
  { value: 'IRON Research Team', label: 'IRON Research Team' },
  { value: 'Online Survey', label: 'Online Survey' }
];

export const ContactUserTypeList: ValueList[] = [
  { value: "Vendor Contact (Billing Dept)", label: "Vendor Contact (Billing Dept)" },
  { value: "Vendor Contact (Case Dispatch Dept.)", label: "Vendor Contact (Case Dispatch Dept.)" },
  { value: "Vendor Billing Contact", label: "Vendor Billing Contact" },
  { value: "Vendor Technical Contact", label: "Vendor Technical Contact" },
  { value: "Vendor Contact (Professional Services)", label: "Vendor Contact (Professional Services)" },
  { value: "Vendor Primary Contact", label: "Vendor Primary Contact" },
  { value: "Vendor Company Contact", label: "Vendor Company Contact" },
  { value: "Billing Member (VMS)", label: "Billing Member (VMS)" },
  { value: "Vendor Contact (Logistics)", label: "Vendor Contact (Logistics)" },
  { value: "Service Delivery Member (VMS)", label: "Service Delivery Member (VMS)" },
  { value: "Program Management Member (VMS)", label: "Program Management Member (VMS)" },
  { value: "Chief Operating Officer", label: "Chief Operating Officer" },
  { value: "Program Manager, Admin (VMS)", label: "Program Manager, Admin (VMS)" },
  { value: "Vendor Contact (Case Dispatch Lead, Admin)", label: "Vendor Contact (Case Dispatch Lead, Admin)" },
  { value: "Account Relationship Management Member (VMS)", label: "Account Relationship Management Member (VMS)" },
  { value: "Worker Resource Pool (VMS)", label: "Worker Resource Pool (VMS)" },
  { value: "Procurement &amp; Pricing Member (VMS)", label: "Procurement & Pricing Member (VMS)" },
  { value: "Vendor Contact (Program Manager)", label: "Vendor Contact (Program Manager)" },
  { value: "Vendor Contact (Program Director)", label: "Vendor Contact (Program Director)" }
];

export const TimeZone: ValueList[] = [
  { value: "Greenwich Mean Time (GMT)", label: "Greenwich Mean Time (GMT)" },
  { value: "Universal Coordinated Time (GMT)", label: "Universal Coordinated Time (GMT)" },
  { value: "European Central Time (GMT+1:00)", label: "European Central Time (GMT+1:00)" },
  { value: "Eastern European Time (GMT+2:00)", label: "Eastern European Time (GMT+2:00)" },
  { value: "(Arabic) Egypt Standard Time (GMT+2:00)", label: "(Arabic) Egypt Standard Time (GMT+2:00)" },
  { value: "Eastern African Time (GMT+3:00)", label: "Eastern African Time (GMT+3:00)" },
  { value: "Middle East Time (GMT+3:30)", label: "Middle East Time (GMT+3:30)" },
  { value: "Near East Time (GMT+4:00)", label: "Near East Time (GMT+4:00)" },
  { value: "Pakistan Lahore Time (GMT+5:00)", label: "Pakistan Lahore Time (GMT+5:00)" },
  { value: "India Standard Time (GMT+5:30)", label: "India Standard Time (GMT+5:30)" },
  { value: "Bangladesh Standard Time (GMT+6:00)", label: "Bangladesh Standard Time (GMT+6:00)" },
  { value: "Vietnam Standard Time (GMT+7:00)", label: "Vietnam Standard Time (GMT+7:00)" },
  { value: "China Taiwan Time (GMT+8:00)", label: "China Taiwan Time (GMT+8:00)" },
  { value: "Japan Standard Time (GMT+9:00)", label: "Japan Standard Time (GMT+9:00)" },
  { value: "Australia Central Time (GMT+9:30)", label: "Australia Central Time (GMT+9:30)" },
  { value: "Australia Eastern Time (GMT+10:00)", label: "Australia Eastern Time (GMT+10:00)" },
  { value: "Solomon Standard Time (GMT+11:00)", label: "Solomon Standard Time (GMT+11:00)" },
  { value: "New Zealand Standard Time (GMT+12:00)", label: "New Zealand Standard Time (GMT+12:00)" },
  { value: "Midway Islands Time (GMT-11:00)", label: "Midway Islands Time (GMT-11:00)" },
  { value: "Hawaii Standard Time (GMT-10:00)", label: "Hawaii Standard Time (GMT-10:00)" },
  { value: "Alaska Standard Time (GMT-9:00)", label: "Alaska Standard Time (GMT-9:00)" },
  { value: "Pacific Standard Time (GMT-8:00)", label: "Pacific Standard Time (GMT-8:00)" },
  { value: "Phoenix Standard Time (GMT-7:00)", label: "Phoenix Standard Time (GMT-7:00)" },
  { value: "Mountain Standard Time (GMT-7:00)", label: "Mountain Standard Time (GMT-7:00)" },
  { value: "Central Standard Time (GMT-6:00)", label: "Central Standard Time (GMT-6:00)" },
  { value: "Eastern Standard Time (GMT-5:00)", label: "Eastern Standard Time (GMT-5:00)" },
  { value: "Indiana Eastern Standard Time (GMT-5:00)", label: "Indiana Eastern Standard Time (GMT-5:00)" },
  { value: "Puerto Rico and US Virgin Islands Time (GMT-4:00)", label: "Puerto Rico and US Virgin Islands Time (GMT-4:00)" },
  { value: "Canada Newfoundland Time (GMT-3:30)", label: "Canada Newfoundland Time (GMT-3:30)" },
  { value: "Argentina Standard Time (GMT-3:00)", label: "Argentina Standard Time (GMT-3:00)" },
  { value: "Brazil Eastern Time (GMT-3:00)", label: "Brazil Eastern Time (GMT-3:00)" },
  { value: "Central African Time (GMT-1:00)", label: "Central African Time (GMT-1:00)" },
];


export const supportedLanguagesList: ValueList[] = [
  { value: "English", label: "English" },
  { value: "Spanish", label: "Spanish" },
  { value: "Arabic", label: "Arabic" },
  { value: "Portuguese", label: "Portuguese" },
  { value: "Russian", label: "Russian" },
  { value: "Japanese", label: "Japanese" },
  { value: "German", label: "German" },
  { value: "French", label: "French" },
  { value: "Korean", label: "Korean" },
  { value: "Itailian", label: "Itailian" },
  { value: "Other", label: "Other" }
];

export const industryList: ValueList[] = [
  // { value: 'Promotional Marketing', label: 'Promotional Marketing' },
  // { value: 'Technology and Communications', label: 'Technology and Communications' },
  // { value: 'Software Development', label: 'Software Development' },
  // { value: 'Retail Merchandising and Installation', label: 'Retail Merchandising and Installation' },
  // { value: 'Transportation & Logistics', label: 'Transportation & Logistics' },
  // { value: 'Administrative Support', label: 'Administrative Support' },
  // { value: 'Audio/Visual', label: 'Audio/Visual' },
  // { value: 'Business and Financial Services', label: 'Business and Financial Services' },
  // { value: 'Consumer Services', label: 'Consumer Services' },
  // { value: 'Design and Multimedia Production', label: 'Design and Multimedia Production' },
  // { value: 'Digital Signage', label: 'Digital Signage' },
  // { value: 'Education', label: 'Education' },
  // { value: 'Enterprise Software', label: 'Enterprise Software' },
  // { value: 'Facilities and Maintenance', label: 'Facilities and Maintenance' },
  // { value: 'Government Services', label: 'Government Services' },
  // { value: 'Health and Wellness', label: 'Health and Wellness' },
  // { value: 'Home Services', label: 'Home Services' },
  // { value: 'Hospitality', label: 'Hospitality' },
  // { value: 'Human Resources', label: 'Human Resources' },
  // { value: 'Interpretation', label: 'Interpretation' },
  // { value: 'Legal Services', label: 'Legal Services' },
  // { value: 'Meeting & Event Services', label: 'Meeting & Event Services' },
  // { value: 'Personal Services', label: 'Personal Services' },
  // { value: 'Property Preservation', label: 'Property Preservation' },
  // { value: 'Retail Cosmetics', label: 'Retail Cosmetics' },
  // { value: 'Sales and Marketing', label: 'Sales and Marketing' },
  // { value: 'Security Services', label: 'Security Services' },
  // { value: 'Writing and Translation', label: 'Writing and Translation' },
  { value: 'Agriculture', label: 'Agriculture' },
  { value: 'Biotechnology', label: 'Biotechnology' },
  { value: 'Chemicals', label: 'Chemicals' },
  { value: 'Communications', label: 'Communications' },
  { value: 'Computer Hardware', label: 'Computer Hardware' },
  { value: 'Consulting', label: 'Consulting' },
  { value: 'Data Center Infrastructure', label: 'Data Center Infrastructure' },
  { value: 'Electronics', label: 'Electronics' },
  { value: 'Energy', label: 'Energy' },
  { value: 'Engineering', label: 'Engineering' },
  { value: 'Entertainment', label: 'Entertainment' },
  { value: 'Environmental', label: 'Environmental' },
  { value: 'Facilities and Maintenance', label: 'Facilities and Maintenance' },
  { value: 'Field Services', label: 'Field Services' },
  { value: 'Finance', label: 'Finance' },
  { value: 'Government', label: 'Government' },
  { value: 'Healthcare', label: 'Healthcare' },
  { value: 'Hospitality', label: 'Hospitality' },
  { value: 'Information Technology', label: 'Information Technology' },
  { value: 'Machinery', label: 'Machinery' },
  { value: 'Manufacturing', label: 'Manufacturing' },
  { value: 'Media', label: 'Media' },
  { value: 'Recreation', label: 'Recreation' },
  { value: 'Retail', label: 'Retail' },
  { value: 'Shipping', label: 'Shipping' },
  { value: 'Software Development', label: 'Software Development' },
  { value: 'Transportation & Logistics', label: 'Transportation & Logistics' },
  { value: 'Technology and Communications', label: 'Technology and Communications' },
  { value: 'Telecommunications', label: 'Telecommunications' },
  { value: 'Utilities', label: 'Utilities' },
  { value: 'Promotional Marketing', label: 'Promotional Marketing' },
  { value: 'Retail Merchandising Installation', label: 'Retail Merchandising Installation' },
  { value: 'Other', label: 'Other' }
]

export const flashWSLSetup: ValueList[] = [
  { value: 'Qualified', label: 'Qualified' },
  { value: 'Available', label: 'Available' }
]

export const _3PLCarrierNBDOnly: ValueList[] = [
  { value: 'DHL', label: 'DHL' },
  { value: 'Estafeta', label: 'Estafeta' },
  { value: 'FedEX', label: 'FedEX' },
  { value: 'Flash', label: 'Flash' },
  { value: 'Purolator', label: 'Purolator' },
  { value: 'Qatar Logistics', label: 'Qatar Logistics' }
]

export const WSLLocationStatus: ValueList[] = [
  { value: 'Active', label: 'Active' },
  { value: 'Passive', label: 'Passive' },
  { value: 'Phantom', label: 'Phantom' }
]

export const WSLLocationSLASupportedSetupNBD: ValueList[] = [
  { value: '4 Hours', label: '4 Hours' },
  { value: 'NBD', label: 'NBD' },
  { value: 'Best Effort', label: 'Best Effort' },
  { value: 'CSEPU', label: 'CSEPU' },
  { value: 'Pickup', label: 'Pickup' }
]

export const _3PLServiceLevelNBDOnly: ValueList[] = [
  { value: 'DHL Domestic Express 12:00PM NBD', label: 'DHL Domestic Express 12:00PM NBD' },
  { value: 'FedEx Standard Overnight', label: 'FedEx Standard Overnight' },
  { value: 'Next Business Day FG Controlled', label: 'Next Business Day FG Controlled' },
  { value: 'Purolator Express 1030AM NBD', label: 'Purolator Express 1030AM NBD' },
  { value: 'NBD', label: 'NBD' },
  { value: 'Pickup', label: 'Pickup' }
]

export const B2BProcessType: ValueList[] = [
  { value: 'FSL', label: 'FSL' },
  { value: 'Iron', label: 'Iron' },
]

export const integrationServiceName: ValueList[] = [
  { value: 'FlashTrac', label: 'FlashTrac' },
  { value: 'Dimerco', label: 'Dimerco' },
  { value: 'Ironcdc', label: 'Ironcdc' },
  { value: 'Base Logistics', label: 'Base Logistics' },
]

export const B2BFRU_SKU_Identifier: ValueList[] = [
  { value: 'Product Name', label: 'Product Name' },
  { value: 'Partner FRU SKU', label: 'Partner FRU SKU' },
]

export const marketingListSource: ValueList[] = [
  { value: 'IBM', label: 'IBM' },
  { value: 'Avaya', label: 'Avaya' },
  { value: 'Lenovo', label: 'Lenovo' },
  { value: 'Dell', label: 'Dell' },
  { value: 'HP', label: 'HP' },
  { value: 'EMC', label: 'EMC' },
  { value: 'Other', label: 'Other' }
];

export const vendorListSource: ValueList[] = [
  { value: 'None', label: 'None' },
  { value: 'IBM', label: 'IBM' },
  { value: 'Microsoft', label: 'Microsoft' },
  { value: 'Apple', label: 'Apple' },
  { value: 'Avaya', label: 'Avaya' },
  { value: 'Cisco', label: 'Cisco' },
  { value: 'Citrix', label: 'Citrix' },
  { value: 'Dell', label: 'Dell' },
  { value: 'EMC', label: 'EMC' },
  { value: 'Fujitsu', label: 'Fujitsu' },
  { value: 'Hitachi HDS', label: 'Hitachi HDS' },
  { value: 'HPE', label: 'HPE' },
  { value: 'Intel', label: 'Intel' },
  { value: 'Lenovo', label: 'Lenovo' },
  { value: 'NetApp', label: 'NetApp' },
  { value: 'NEC', label: 'NEC' },
  { value: 'Oracle/Sun', label: 'Oracle/Sun' },
  { value: 'Quanta', label: 'Quanta' },
  { value: 'Redhat or Other Linux', label: 'Redhat or Other Linux' },
  { value: 'SuperMario', label: 'SuperMario' },
  { value: 'Vmware', label: 'Vmware' },
  { value: 'ZTE', label: 'ZTE' },
];

export const techListSource: ValueList[] = [
  { value: 'None', label: 'None' },
  { value: 'Azure Cloud', label: 'Azure Cloud' },
  { value: 'Data Centre Facility Management', label: 'Data Centre Facility Management' },
  { value: 'Data Centre Rack-n-stack Cabiling', label: 'Data Centre Rack-n-stack Cabiling' },
  { value: 'Desktop Business', label: 'Desktop Business' },
  { value: 'Desktop Enterprise', label: 'Desktop Enterprise' },
  { value: 'Enterprise Security', label: 'Enterprise Security' },
  { value: 'Enterprise Storage', label: 'Enterprise Storage' },
  { value: 'Network Security', label: 'Network Security' },
  { value: 'Networking and Switches', label: 'Networking and Switches' },
  { value: 'Remote Infrastructure Management', label: 'Remote Infrastructure Management' },
  { value: 'Routers', label: 'Routers' },
  { value: 'SDN Networking', label: 'SDN Networking' },
  { value: 'Servers & Storage', label: 'Servers & Storage' },
];

export const ResourceListSource: ValueList[] = [
  { value: 'Desktop EUC Engineer', label: 'Desktop EUC Engineer' },
  { value: 'Desktop EUC Technician', label: 'Desktop EUC Technician' },
  { value: 'Desktop Support', label: 'Desktop Support' },
  { value: 'Desktop Technician', label: 'Desktop Technician' },
  { value: 'Desktop Engineer', label: 'Desktop Engineer' },
  { value: 'Electrical Engineer', label: 'Electrical Engineer' },
  { value: 'Engineering Manager', label: 'Engineering Manager' },
  { value: 'Exchange Consultant', label: 'Exchange Consultant' },
  { value: 'Field Engineer', label: 'Field Engineer' },
  { value: 'Field Technician', label: 'Field Technician' },
  { value: 'Hardware Engineer', label: 'Hardware Engineer' },
  { value: 'HelpDesk Manager', label: 'HelpDesk Manager' },
  { value: 'HR Administrator', label: 'HR Administrator' },
  { value: 'Maintainance Engineer', label: 'Maintainance Engineer' },
  { value: 'Mechnical Engineer', label: 'Mechnical Engineer' },
  { value: 'Network Administrator', label: 'Network Administrator' },
  { value: 'Network Engineer', label: 'Network Engineer' },
  { value: 'Network Technician', label: 'Network Technician' },
  { value: 'Planning Engineer', label: 'Planning Engineer' },
  { value: 'Process Engineer', label: 'Process Engineer' },
  { value: 'Production Engineer', label: 'Production Engineer' },
  { value: 'Project Administrator', label: 'Project Administrator' },
  { value: 'Project Engineer', label: 'Project Engineer' },
  { value: 'Quality Engineer', label: 'Quality Engineer' },
  { value: 'sales Administrator', label: 'sales Administrator' },
  { value: 'sales Engineer', label: 'sales Engineer' },
  { value: 'SCCM Consultant', label: 'SCCM Consultant' },
  { value: 'SCOM Consultant', label: 'SCOM Consultant' },
  { value: 'Server Consultant', label: 'Server Consultant' },
  { value: 'Server Manager', label: 'Server Manager' },
  { value: 'Server Administrator', label: 'Server Administrator' },
  { value: 'Server Engineer', label: 'Server Engineer' },
  { value: 'Service Engineer', label: 'Service Engineer' },
  { value: 'Storage Engineer', label: 'Storage Engineer' },
  { value: 'Storage Technician', label: 'Storage Technician' },
  { value: 'Structural Engineer', label: 'Structural Engineer' },
  { value: 'System Administrator', label: 'System Administrator' },
  { value: 'System Engineer', label: 'System Engineer' },
  { value: 'Technical Architect', label: 'Technical Architect' },
  { value: 'Technical Author', label: 'Technical Author' },
  { value: 'Technical Consultant', label: 'Technical Consultant' },
  { value: 'Technical Director', label: 'Technical Director' },
  { value: 'Technical Manager', label: 'Technical Manager' },
  { value: 'Test Engineer', label: 'Test Engineer' },
  { value: 'Voice Administrator', label: 'Voice Administrator' },
  { value: 'Voice Engineer', label: 'Voice Engineer' },
  { value: 'Voice Technician', label: 'Voice Technician' },
  { value: 'Administrative Assistant', label: 'Administrative Assistant' },
  { value: 'Application Support', label: 'Application Support' },
  { value: 'Data Analyst', label: 'Data Analyst' },
  { value: 'Data Architect', label: 'Data Architect' },
  { value: 'Data Network Engineer', label: 'Data Network Engineer' },
  { value: 'Data Network Technician', label: 'Data Network Technician' },
  { value: 'Database Administrator', label: 'Database Administrator' },
  { value: 'Database Developer', label: 'Database Developer' },
  { value: 'Delivery Manager', label: 'Delivery Manager' },
  { value: 'Design Engineer', label: 'Design Engineer' },
];

export const certifications: ValueList[] = [
  { value: 'Apple Technologies', label: 'Apple Technologies' },
  { value: 'Cisco Technologies', label: 'Cisco Technologies' },
  { value: 'Citrix Technologies', label: 'Citrix Technologies' },
  { value: 'Dell Technologies', label: 'Dell Technologies' },
  { value: 'HP Technologies', label: 'HP Technologies' },
  { value: 'IBM Technologies', label: 'IBM Technologies' },
  { value: 'Microsoft Technologies', label: 'Microsoft Technologies' },
  { value: 'Sun Microsystems Technologies', label: 'Sun Microsystems Technologies' },
  { value: 'VMware Technologies', label: 'VMware Technologies' },
  { value: 'None', label: 'None' }
];

export const vendorExpertiseBasic: ValueList[] = [
  {
    'value': 'IBM',
    'label': 'IBM'
  },
  {
    'value': 'HP',
    'label': 'HP'
  },
  {
    'value': 'Microsoft',
    'label': 'Microsoft'
  },
  {
    'value': 'None',
    'label': 'None'
  },
  {
    'value': 'Apple',
    'label': 'Apple'
  },
  {
    'value': 'Avaya',
    'label': 'Avaya'
  },
  {
    'value': 'Cisco',
    'label': 'Cisco'
  },
  {
    'value': 'Citrix',
    'label': 'Citrix'
  },
  {
    'value': 'Dell',
    'label': 'Dell'
  },
  {
    'value': 'EMC',
    'label': 'EMC'
  },
  {
    'value': 'Fujitsu',
    'label': 'Fujitsu'
  },
  {
    'value': 'Hitachi HDS',
    'label': 'Hitachi HDS'
  },
  {
    'value': 'HPE',
    'label': 'HPE'
  },
  {
    'value': 'Intel',
    'label': 'Intel'
  },
  {
    'value': 'Lenovo',
    'label': 'Lenovo'
  },
  {
    'value': 'NetApp',
    'label': 'NetApp'
  },
  {
    'value': 'NEC',
    'label': 'NEC'
  },
  {
    'value': 'Oracle/Sun',
    'label': 'Oracle/Sun'
  },
  {
    'value': 'Quanta',
    'label': 'Quanta'
  },
  {
    'value': 'Redhat or Other Linux',
    'label': 'Redhat or Other Linux'
  },
  {
    'value': 'Supermicro',
    'label': 'Supermicro'
  },
  {
    'value': 'VMware',
    'label': 'VMware'
  },
  {
    'value': 'ZTE',
    'label': 'ZTE'
  }
];

export const technologySupportedAdvanceExpertise = [
  {
    'value': 'None',
    'label': 'None'
  },
  {
    'value': 'Azure Cloud',
    'label': 'Azure Cloud'
  },
  {
    'value': 'Data Center Facility Management',
    'label': 'Data Center Facility Management'
  },
  {
    'value': 'Data Center Rack-n-Stack & Cabling',
    'label': 'Data Center Rack-n-Stack & Cabling'
  },
  {
    'value': 'Desktop Business',
    'label': 'Desktop Business'
  },
  {
    'value': 'Desktop Enterprise',
    'label': 'Desktop Enterprise'
  },
  {
    'value': 'Enterprise Security',
    'label': 'Enterprise Security'
  },
  {
    'value': 'Enterprise Storage',
    'label': 'Enterprise Storage'
  },
  {
    'value': 'Network Security',
    'label': 'Network Security'
  },
  {
    'value': 'Networking & Switches',
    'label': 'Networking & Switches'
  },
  {
    'value': 'Remote Infrastructure Management',
    'label': 'Remote Infrastructure Management'
  },
  {
    'value': 'Routers',
    'label': 'Routers'
  },
  {
    'value': 'SDN Networking',
    'label': 'SDN Networking'
  },
  {
    'value': 'Servers & Storage',
    'label': 'Servers & Storage'
  }
];

export const vendorSupportedAdvanceExpertise = [
  {
    'value': 'None',
    'label': 'None'
  },
  {
    'value': 'Apple',
    'label': 'Apple'
  },
  {
    'value': 'Avaya',
    'label': 'Avaya'
  },
  {
    'value': 'Cisco',
    'label': 'Cisco'
  },
  {
    'value': 'Citrix',
    'label': 'Citrix'
  },
  {
    'value': 'Dell',
    'label': 'Dell'
  },
  {
    'value': 'EMC',
    'label': 'EMC'
  },
  {
    'value': 'Fujitsu',
    'label': 'Fujitsu'
  },
  {
    'value': 'Hitachi HDS',
    'label': 'Hitachi HDS'
  },
  {
    'value': 'HPE',
    'label': 'HPE'
  },
  {
    'value': 'IBM',
    'label': 'IBM'
  },
  {
    'value': 'Intel',
    'label': 'Intel'
  },
  {
    'value': 'Lenovo',
    'label': 'Lenovo'
  },
  {
    'value': 'Microsoft',
    'label': 'Microsoft'
  },
  {
    'value': 'NetApp',
    'label': 'NetApp'
  },
  {
    'value': 'NEC',
    'label': 'NEC'
  },
  {
    'value': 'Oracle/Sun',
    'label': 'Oracle/Sun'
  },
  {
    'value': 'Quanta',
    'label': 'Quanta'
  },
  {
    'value': 'Redhat or Other Linux',
    'label': 'Redhat or Other Linux'
  },
  {
    'value': 'Supermicro',
    'label': 'Supermicro'
  },
  {
    'value': 'VMWare',
    'label': 'VMWare'
  },
  {
    'value': 'ZTE',
    'label': 'ZTE'
  }
];

export const typesOfTechnicalResourcesSpecialty = [
  {
    'value': 'Desktop EUC Engineer',
    'label': 'Desktop EUC Engineer'
  },
  {
    'value': 'Desktop EUC Technician',
    'label': 'Desktop EUC Technician'
  },
  {
    'value': 'Desktop Support',
    'label': 'Desktop Support'
  },
  {
    'value': 'Desktop Technician',
    'label': 'Desktop Technician'
  },
  {
    'value': 'Electrical Engineer',
    'label': 'Electrical Engineer'
  },
  {
    'value': 'Engineering Manager',
    'label': 'Engineering Manager'
  },
  {
    'value': 'Exchange Consultant',
    'label': 'Exchange Consultant'
  },
  {
    'value': 'Field Engineer',
    'label': 'Field Engineer'
  },
  {
    'value': 'Field Engineer',
    'label': 'Field Engineer'
  },
  {
    'value': 'Field Technician',
    'label': 'Field Technician'
  },
  {
    'value': 'Hardware Engineer',
    'label': 'Hardware Engineer'
  },
  {
    'value': 'Helpdesk Manager',
    'label': 'Helpdesk Manager'
  },
  {
    'value': 'HR Administrator',
    'label': 'HR Administrator'
  },
  {
    'value': 'Maintenance Engineer',
    'label': 'Maintenance Engineer'
  },
  {
    'value': 'Mechanical Engineer',
    'label': 'Mechanical Engineer'
  },
  {
    'value': 'Network Administrator',
    'label': 'Network Administrator'
  },
  {
    'value': 'Network Engineer',
    'label': 'Network Engineer'
  },
  {
    'value': 'Network Technician',
    'label': 'Network Technician'
  },
  {
    'value': 'Planning Engineer',
    'label': 'Planning Engineer'
  },
  {
    'value': 'Process Engineer',
    'label': 'Process Engineer'
  },
  {
    'value': 'Production Engineer',
    'label': 'Production Engineer'
  },
  {
    'value': 'Project Administrator',
    'label': 'Project Administrator'
  },
  {
    'value': 'Project Engineer',
    'label': 'Project Engineer'
  },
  {
    'value': 'Quality Engineer',
    'label': 'Quality Engineer'
  },
  {
    'value': 'Sales Administrator',
    'label': 'Sales Administrator'
  },
  {
    'value': 'Sales Engineer',
    'label': 'Sales Engineer'
  },
  {
    'value': 'SCCM Consultant',
    'label': 'SCCM Consultant'
  },
  {
    'value': 'SCOM Consultant',
    'label': 'SCOM Consultant'
  },
  {
    'value': 'Server Consultant',
    'label': 'Server Consultant'
  },
  {
    'value': 'Server Technician',
    'label': 'Server Technician'
  },
  {
    'value': 'Server Administrator',
    'label': 'Server Administrator'
  },
  {
    'value': 'Server Engineer',
    'label': 'Server Engineer'
  },
  {
    'value': 'Server Technician',
    'label': 'Server Technician'
  },
  {
    'value': 'Service Engineer',
    'label': 'Service Engineer'
  },
  {
    'value': 'Storage Engineer',
    'label': 'Storage Engineer'
  },
  {
    'value': 'Storage Technician',
    'label': 'Storage Technician'
  },
  {
    'value': 'Storage Engineer',
    'label': 'Storage Engineer'
  },
  {
    'value': 'Structural Engineer',
    'label': 'Structural Engineer'
  },
  {
    'value': 'Systems Administrator',
    'label': 'Systems Administrator'
  },
  {
    'value': 'Systems Engineer',
    'label': 'Systems Engineer'
  },
  {
    'value': 'Technical Architect',
    'label': 'Technical Architect'
  },
  {
    'value': 'Technical Author',
    'label': 'Technical Author'
  },
  {
    'value': 'Technical Consultant',
    'label': 'Technical Consultant'
  },
  {
    'value': 'Technical Director',
    'label': 'Technical Director'
  },
  {
    'value': 'Technical Manager',
    'label': 'Technical Manager'
  },
  {
    'value': 'Test Engineer',
    'label': 'Test Engineer'
  },
  {
    'value': 'Voice Consultant',
    'label': 'Voice Consultant'
  },
  {
    'value': 'Voice Engineer',
    'label': 'Voice Engineer'
  },
  {
    'value': 'Voice Technician',
    'label': 'Voice Technician'
  },
  {
    'value': 'Administrative Assistant',
    'label': 'Administrative Assistant'
  },
  {
    'value': 'Application Support',
    'label': 'Application Support'
  },
  {
    'value': 'Data Analyst',
    'label': 'Data Analyst'
  },
  {
    'value': 'Data Architect',
    'label': 'Data Architect'
  },
  {
    'value': 'Data Network Consultant',
    'label': 'Data Network Consultant'
  },
  {
    'value': 'Data Network Engineer',
    'label': 'Data Network Engineer'
  },
  {
    'value': 'Data Network Technician',
    'label': 'Data Network Technician'
  },
  {
    'value': 'Database Administrator',
    'label': 'Database Administrator'
  },
  {
    'value': 'Database Developer',
    'label': 'Database Developer'
  },
  {
    'value': 'Delivery Manager',
    'label': 'Delivery Manager'
  },
  {
    'value': 'Design Engineer',
    'label': 'Design Engineer'
  },
  {
    'value': 'Other',
    'label': 'Other'
  }

];

export const timechangeReasons: ValueList[] = [
  { value: 'Forgot to login/ Could not login on time', label: 'Forgot to login/ Could not login on time' },
  { value: 'Connectivity/Internet issue', label: 'Connectivity/Internet issue' },
  { value: 'Job not Assigned', label: 'Job not Assigned' },
  { value: 'Technical issue/Portal issue', label: 'Technical issue/Portal issue' }
]
export const teamTypes = [
  {
    label: 'MyWorkplace Project Team (Department-Organization Members)',
    value: 'MyWorkplaceProjectTeam',
  }, {
    label: 'Account Program Team (Program-Select Members)',
    value: 'AccountProgramTeam',
  }
];

export const groupRecordTypes = [
  { value: 'Group (General / Everyone)', label: 'Group (General / Everyone)' },
  { value: 'Group (Custom)', label: 'Group (Custom)' },
  { value: 'Team', label: 'Team' },
];

export const deptRoleAccesses = [
  {
    label: 'Active',
    value: 'Active',
  }, {
    label: 'Passive',
    value: 'Passive',
  }
];

export const typeOfGovtId: ValueList[] = [
  {
    label: 'Driving License',
    value: 'Driving License',
  }, {
    label: 'Passport',
    value: 'Passport',
  },
  {
    label: 'Tax ID/ PAN ID',
    value: 'Tax ID/ PAN ID',
  },
  {
    label: 'Voter ID',
    value: 'Voter ID',
  },
  {
    label: 'Aadhar Card/Voter Id/PAN Card',
    value: 'Aadhar Card/Voter Id/PAN Card',
  },
  {
    label: 'Other',
    value: 'Other',
  }

];

export const SubCategory: ValueList[] = [
  { value: 'Recruitment', label: 'Recruitment' },
  { value: 'Human Resources', label: 'Human Resources' },
  { value: 'Software Development', label: 'Software Development' }
];
export const memberRoles: ValueList[] = [
  { value: 'Primary', label: 'Primary' },
  { value: 'Secondary', label: 'Secondary' },
  { value: 'Tertiary', label: 'Tertiary' },
  { value: 'Quaternary', label: 'Quaternary' },
  { value: 'Senary', label: 'Senary' },
  { value: 'Septenary', label: 'Septenary' },
  { value: 'Octonary', label: 'Octonary' },
  { value: 'Nonary', label: 'Nonary' },
  { value: 'Denary', label: 'Denary' },
];

export const polarQuestionOptions: ValueList[] = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' }
];

export const numOfHours: ValueList[] = [
  { value: '', label: '--None--' },
  { value: '2', label: '2' },
  { value: '1', label: '1 (Preferred)' },
  { value: '4', label: '4' },
  { value: '6', label: '6' },
  { value: '8', label: '8' },
];

export const languageUsedByTechnician: ValueList[] = [
  { value: '', label: '--None--' },
  { value: 'Local Only', label: 'Local Only' },
  { value: 'English + Local', label: 'English + Local' },
  { value: 'English Only', label: 'English Only' },
  { value: 'Local + Translator', label: 'Local + Translator' }
];

export const servicesProvided: ValueList[] = [
  { value: '', label: '--None--' },
  { value: 'Not Covered', label: 'Not Covered' },
  { value: 'SBD4H-24x7', label: 'SBD4H-24x7' },
  { value: 'SBD6H-24x7', label: 'SBD6H-24x7' },
  { value: 'SBD4H-9H5D', label: 'SBD4H-9H5D' },
  { value: 'SBD6H-9H5D', label: 'SBD6H-9H5D' },
  { value: 'NBD', label: 'NBD' },
  { value: '2BD', label: '2BD' },
  { value: '3BD', label: '3BD' },
  { value: '5BD', label: '5BD' },
  { value: '10BD', label: '10BD' }
];

export const servicesProvidedSubset: ValueList[] = [
  { value: '', label: '--None--' },
  { value: 'Not Covered', label: 'Not Covered' },
  { value: 'NBD', label: 'NBD' },
  { value: '2BD', label: '2BD' },
  { value: '3BD', label: '3BD' },
  { value: '5BD', label: '5BD' }
];
export const accountOnboardingDocumentsValues = {
  Signed_copy_of_NDA__c: { categoryId: 4, subCategoryId: 23 },
  Signed_copy_of_MSA__c: { categoryId: 4, subCategoryId: 24 },
  Umbrella_Liability_ins__c: { categoryId: 4, subCategoryId: 31 },
  Cyber_Liability_ins__c: { categoryId: 4, subCategoryId: 32 },
  Workers_Compensation_ins__c: { categoryId: 4, subCategoryId: 33 },
  Employers_liability_ins__c: { categoryId: 4, subCategoryId: 34 },
  automobile_liability_ins__c: { categoryId: 4, subCategoryId: 35 },
  General_Liability_ins__c: { categoryId: 4, subCategoryId: 30 },
  GDPR_Compliance__c: { categoryId: 4, subCategoryId: 36 },
  cancelled_Cheque_of_Bank_Det__c: { categoryId: 4, subCategoryId: 29 },
  Incorporation_Business_Registration_certificate_det__c: { categoryId: 4, subCategoryId: 26 },
  Tax_Identification_Number_det__c:{ categoryId: 4, subCategoryId: 27 },
  GST_VAT_Registration_certificate_det__c:{ categoryId: 4, subCategoryId: 28 },
  service_Tax_Registration_det__c:{ categoryId: 4, subCategoryId: 36 }
}

export const earlyCheckInOptions: ValueList[] = [
  { value: 'Customer Requested', label: 'Customer Requested' },
  { value: 'Just happened to be', label: 'Just happened to be' },
  { value: 'Plan to leave early', label: 'Plan to leave early' },
  { value: 'Other', label: 'Other' }
];

export const lateCheckInOptions: ValueList[] = [
  { value: 'Customer Requested', label: 'Customer Requested' },
  { value: 'Just happened to be', label: 'Just happened to be' },
  { value: 'Sick', label: 'Sick' },
  { value: 'Traffic', label: 'Traffic' },
  { value: 'Personal', label: 'Personal' },
  { value: 'Doctor Appointment', label: 'Doctor Appointment' },
  { value: 'Other', label: 'Other' }
];

export const earlyCheckOutOptions: ValueList[] = [
  { value: 'Came in Early', label: 'Came in Early' },
  { value: 'Personal', label: 'Personal' },
  { value: 'Doctor Appointment', label: 'Doctor Appointment' },
  { value: 'Sick', label: 'Sick' },
  { value: 'Other', label: 'Other' }
];

export const lateCheckOutOptions: ValueList[] = [
  { value: 'Came in Late', label: 'Came in Late' },
  { value: 'Longer Break', label: 'Longer Break' },
  { value: 'Over-Time', label: 'Over-Time' },
  { value: 'Other', label: 'Other' }
];

export const earlyBreakOption: ValueList[] = [
  { value: 'Customer Requested', label: 'Customer Requested' },
  { value: 'Just happened to be', label: 'Just happened to be' },
  { value: 'Leaving Early', label: 'Leaving Early' },
  { value: 'Other', label: 'Other' }
];

export const lateBreakOption: ValueList[] = [
  { value: 'Just Happened to be', label: 'Just Happened to be' },
  { value: 'Personal', label: 'Personal' },
  { value: 'Sick', label: 'Sick' },
  { value: 'Doctor Appointment', label: 'Doctor Appointment' },
  { value: 'Other', label: 'Other' }
];

export const OToptions: ValueList[] = [
  { value: 'Customer Requested', label: 'Customer Requested' },
  { value: 'PM Requested', label: 'PM Requested' },
  { value: 'Lost Track of Time', label: 'Lost Track of Time' },
  { value: 'No OT', label: 'No OT'},
  { value: 'Other', label: 'Other' }
];
export const yesNoOptions: ValueList[] = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' }
];

export const availableToCommunities = [
  {
    value: 'PMS',
    title: 'PMS'
  },
  {
    value: 'MSP',
    title: 'MSP'
  },
  {
    value: 'VMS',
    title: 'VMS'
  }
];
// : any = ['Customer Requested', 'Just happened to be', 'Plan to leave early', 'Other']
//   lateCheckInOptions: any = ['Customer Requested', 'Just Happened To Be', 'Sick', 'Traffic', 'Personal', 'Doctor Appointment', 'Other'];
//   checkOutOptions: any = ['Came in Early', 'Personal', 'Doctor Appointment', 'Sick', 'Other']
//   earlyLateBreakOption: any = ['Just happened to be', 'Customer Requested', 'Leaving Early', 'Other']
//   OToptions: any = ['Customer Requested', 'PM Requested', 'Lost Track of Time', 'Other']
//   yesNoOptions: any = ['No', 'Yes']

export const JobsiteStatus: ValueList[] = [
  { value: 'Active', label: 'Active' },
  { value: 'Passive', label: 'Passive' },
  { value: 'Archived', label: 'Archived' }
];

export const statusChangeValues: ValueList[] = [
  { value: "Submitted for Review", label: "Submitted for Review" },
  { value: "Invoice Reviewed", label: "Invoice Reviewed" },
  { value: "Submitted for Approval", label: "Submitted for Approval" },
  { value: "Invoice Approved", label: "Invoice Approved" },
  { value: "Payment Done", label: "Payment Done" },
  { value: "Payment Received", label: "Payment Received" },
  { value: "Invoice Rejected", label: "Invoice Rejected" },
];

export const minHrsofDispatch: ValueList[] = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '4', label: '4' },
  { value: '6', label: '6' },
  { value: '8', label: '8' },
];

export const slasForInProcessCatalogTabs: any[] = [
  { value: 'SBD4H-24x7', label: 'FSE - SBD4H - 24x7-Day', coverageHrs: '24x7-Day' },
  { value: 'SBD6H-24x7', label: 'FSE - SBD6H - 24x7-Day', coverageHrs: '24x7-Day' },
  { value: 'SBD4H-9H5D', label: 'FSE - SBD4H - 9H5D', coverageHrs: '9H5D' },
  { value: 'SBD6H-9H5D', label: 'FSE - SBD6H - 9H5D', coverageHrs: '9H5D' },
  { value: 'NBD', label: 'FSE - NBD - 9H5D', coverageHrs: '9H5D' },
  { value: '2BD', label: 'FSE - 2BD - 9H5D', coverageHrs: '9H5D' },
  { value: '3BD', label: 'FSE - 3BD - 9H5D', coverageHrs: '9H5D' },
  { value: '5BD', label: 'FSE - 5BD - 9H5D', coverageHrs: '9H5D' },
  { value: '10BD', label: 'FSE - 10BD - 9H5D', coverageHrs: '9H5D' }
];

export const JobsiteType: ValueList[] = [
  { value: 'User - Manual Create', label: 'User - Manual Create' },
  { value: 'Asset - Auto Create', label: 'Asset - Auto Create' },
];

export const JobsiteApprovalStatus: ValueList[] = [
  { value: 'Active (Activation Pending)', label: 'Active (Activation Pending)' },
  { value: 'Active (Activation Approved)', label: 'Active (Activation Approved)' },
  { value: 'Inactive', label: 'Inactive' },
];

export const JobsiteTimeSchedule: ValueList[] = [
  { value: '0.00AM', label: '0.00AM' },
  { value: '1.00AM', label: '1.00AM' },
  { value: '2.00AM', label: '2.00AM' },
  { value: '3.00AM', label: '3.00AM' },
  { value: '4.00AM', label: '4.00AM' },
  { value: '5.00AM', label: '5.00AM' },
  { value: '7.00AM', label: '7.00AM' },
  { value: '7.30AM', label: '7.30AM' },
  { value: '8.00AM', label: '8.00AM' },
  { value: '8.30AM', label: '8.30AM' },
  { value: '9.00AM', label: '9.00AM' },
  { value: '9.30AM', label: '9.30AM' },
  { value: '10.00AM', label: '10.00AM' },
  { value: '10.30AM', label: '10.30AM' },
  { value: '11.00AM', label: '11.00AM' },
  { value: '11.30AM', label: '11.30AM' },
  { value: '12.00PM', label: '12.00PM' },
  { value: '12.30PM', label: '12.30PM' },
  { value: '1.00PM', label: '1.00PM' },
  { value: '1.30PM', label: '1.30PM' },
  { value: '2.00PM', label: '2.00PM' },
  { value: '2.30PM', label: '2.30PM' },
  { value: '3.00PM', label: '3.00PM' },
  { value: '3.30PM', label: '3.30PM' },
  { value: '4.00PM', label: '4.00PM' },
  { value: '4.30PM', label: '4.30PM' },
  { value: '5.00PM', label: '5.00PM' },
  { value: '5.30PM', label: '5.30PM' },
  { value: '6.00PM', label: '6.00PM' },
  { value: '6.30PM', label: '6.30PM' },
  { value: '7.00PM', label: '7.00PM' },
  { value: '8.00PM', label: '8.00PM' },
  { value: '9.00PM', label: '9.00PM' },
  { value: '10.00PM', label: '10.00PM' },
  { value: '11.00PM', label: '11.00PM' },
];
export const contactMethodList = [
  { value: 'Phone', label: 'Phone' },
  { value: 'Mobile', label: 'Mobile' },
  { value: 'Email', label: 'Email' }
];

export const workerDispatchTypeList = [
  { value: 'Worker - Dispatch FE (Primary)', label: 'Worker - Dispatch FE (Primary)' },
  { value: 'Worker - Dispatch FE (Secondary 1)', label: 'Worker - Dispatch FE (Secondary 1)' },
  { value: 'Worker - Dispatch FE (Secondary 2)', label: 'Worker - Dispatch FE (Secondary 2)' },
  { value: 'Worker-  Dispatch FE (Secondary 3)', label: 'Worker-  Dispatch FE (Secondary 3)' },
  { value: 'Worker - Dispatch FE New', label: 'Worker - Dispatch FE New' },
  { value: 'VMS User', label: 'VMS User' }
];

export const workerDedicatedTypeList = [
  { value: 'FTE (With Backfill)', label: 'FTE (With Backfill)' },
  { value: 'FTE (Without Backfill)', label: 'FTE (Without Backfill)' },
  { value: 'FTE (Custom Backfill)', label: 'FTE (Custom Backfill)' },
  // { value: 'PMS User', label: 'PMS User' }, to fix #22722
  { value: 'VMS User', label: 'VMS User' }
];

export const workerHiringTypeList = [
  { value: 'Rehire', label: 'Rehire' },
  { value: 'Rebadged', label: 'Rebadged' },
  { value: 'New Hire', label: 'New Hire' }
];

export const templateCategory: any[] = [
  {
    value: '', label: 'None', subCategory: [
      { value: '', label: 'None' }
    ]
  },
  {
    value: 'Account', label: 'Account', subCategory: [
      { value: '', label: 'None' },
      { value: 'Account Master Project (PMS)', label: 'Account Master Project (PMS)' },
      { value: 'Account Master Project (VMS)', label: 'Account Master Project (VMS)' },
      { value: 'Sales Projects', label: 'Sales Project' }
    ]
  },
  {
    value: 'Field Service Delivery', label: 'Field Service Delivery', subCategory: [
      { value: '', label: 'None' },
      { value: 'Programs', label: 'Programs' },
      { value: 'Delivery Master', label: 'Delivery Master' },
    ]
  },
  {
    value: 'Others', label: 'Others', subCategory: [
      { value: '', label: 'None' },
      { value: 'Recruitment', label: 'Recruitment' },
      { value: 'Human Resources', label: 'Human Resources' },
      { value: 'Software Development', label: 'Software Development' },
    ]
  },
  {
    value: 'Help Desk', label: 'Help Desk', subCategory: [
      { value: '', label: 'None' },
      { value: 'Portal Issues Help Desk (MSP)', label: 'Portal Issues Help Desk (MSP)' },
      { value: 'Portal Issue Help Desk (PMS/VMS)', label: 'Portal Issue Help Desk (PMS/VMS)' },
    ]
  },
  {
    value: 'ISG Entity', label: 'ISG Entity', subCategory: [
      { value: '', label: 'None' }
    ]
  }
];

export const invoiceAdjustmentStatusValues: ValueList[] = [
  { value: "Request Created", label: "Request Created" },
  { value: "Request Verified", label: "Request Verified" },
  { value: "Request Approved", label: "Request Approved" },
  { value: "Submitted for Review", label: "Submitted for Review" },
  { value: "Submitted for Approval", label: "Submitted for Approval" }
]

export const invoiceAdjustmentTypeValues: ValueList[] = [
  { value: "Sales Invoice", label: "Sales Invoice" }
]

export const milestoneTmpltLibraryTypes: ValueList[] = [
  { value: 'Task-Case', label: 'Task-Case' },
  { value: 'Standard', label: 'Standard' },
];

export const wslAccountStatusList: ValueList[] = [
  { value: 'Active', label: 'Active' },
  { value: 'Passive', label: 'Passive' },
  { value: 'WSL Network (N/A)', label: 'WSL Network (N/A)' }
];

export const workerStatusList: ValueList[] = [
  { value: 'Active', label: 'Active' },
  { value: 'Passive', label: 'Passive' },
  { value: 'Expired', label: 'Expired' }
];

export const wslAccountList: ValueList[] = [
  { value: 'All', label: 'All WSL' },
  { value: '0011a00000sgCARAA2', label: 'Facebook Vendor (POP WSL)' },
  { value: '0011a00000X9fsxAAB', label: 'Iron Systems (Vendor & Vendor Sites)-Test' },
  { value: '0011a00000rw4kDAAQ', label: 'Iron Systems - WSL Vendor (Test)' },
  { value: '0011a00000v2YnBAAU', label: 'Magiclink Vendor' }
];
export const IronApprovalStatus: ValueList[] = [
  { value: 'Approved', label: 'Approved' },
  { value: 'Cancelled', label: 'Cancelled' },
  { value: 'In-Progress', label: 'In-Progress' },
  { value: 'Not Submitted', label: 'Not Submitted' },
  { value: 'Rejected', label: 'Rejected' },
  { value: 'Submitted', label: 'Submitted' },
  { value: 'Paid', label: 'Paid' }
];

export const PaymentTypeC: ValueList[] = [
  { value: 'Company Paid (Taxed)', label: 'Company Paid (Taxed)' },
  { value: 'Out-of-pocket (Taxed)', label: 'Out-of-pocket (Taxed)' },
  { value: 'Pending Card Transaction (Taxed)', label: 'Pending Card Transaction (Taxed)' },
  { value: 'Company Paid (Non-Taxed)', label: 'Company Paid (Non-Taxed)' },
  { value: 'Out-of-pocket (Non-Taxed)', label: 'Out-of-pocket (Non-Taxed)' },
  { value: 'Pending Card Transaction (Non-Taxed)', label: 'Pending Card Transaction (Non-Taxed)' }
];

export const CustomerApprovalC: ValueList[] = [
  { value: 'Not Submitted', label: 'Not Submitted' },
  { value: 'Submitted', label: 'Submitted' },
  { value: 'Approved', label: 'Approved' },
  { value: 'Rejected', label: 'Rejected' }
];

export const apvpGroupNumberValues: ValueList[] = [
  { value: "A1", label: "A1" },
  { value: "A2", label: "A2" },
  { value: "B1", label: "B1" },
  { value: "B2", label: "B2" },
  { value: "C1", label: "C1" },
  { value: "C2", label: "C2" },
  { value: "D1", label: "D1" },
  { value: "D2", label: "D2" }
]

export const communityTypeValues: ValueList[] = [
  { value: "VMS", label: "VMS" },
  { value: "VFMS", label: "VFMS" }
]

export const slaGroupNameValues: ValueList[] = [
  { value: "SBD2H Only", label: "SBD2H Only" },
  { value: "SBD4H Only", label: "SBD4H Only" },
  { value: "NxBD All", label: "NxBD All" }
]

export const radiusList: ValueList[] = [
  { value: '50', label: '50' },
  { value: '100', label: '100' },
  { value: '200', label: '200' },
  { value: '500', label: '500' },
  { value: '1000', label: '1000' }
];

export const CheckinCheckoutMapLableCount = [
  { label: 'FTE Currently Active Country', count: 0 },
  { label: 'Total 3PS/FTE', count: 0 },
  { label: 'FTE Currently Checked-In', count: 0, color: '#069106' },
  { label: 'FTE Not Checked-In', count: 0, color: '#cb2121' },
  { label: 'FTE On Break', count: 0, color: '#f7921e' },
  { label: 'FTE Checked Out', count: 0, color: '#747e96' },
];

export const backfillWorkerServiceList: ValueList[] = [
  { value: 'Passive', label: 'Passive Contracts' },
  { value: 'Expired', label: 'Expired Contracts' },
  { value: 'Active', label: 'Active Contracts' }
];

export const displayCoverageTypeList: ValueList[] = [
  { value: 'Vendor', label: 'Vendor' },
  { value: 'Coverage', label: 'Coverage' },
];

export const vendorSubTypeCommunityList: ValueList[] = [
  { value: 'VMS', label: 'VMS' },
  { value: 'VFMS', label: 'VFMS' },
];
export const gtpResourceTypeList: ValueList[] = [
  { value: 'FTE', label: 'FTE' },
  { value: 'FSE', label: 'FSE' },
];

export const gtpPoolAccessTypeList: ValueList[] = [
  { value: 'Private', label: 'Private' },
  { value: 'Public', label: 'Public' },
];

export const gtpMemberTypeList: ValueList[] = [
  { value: 'Partner', label: 'Partner' },
  { value: 'Partner-Customer', label: 'Partner-Customer' },
  { value: 'MSP', label: 'MSP' },
  { value: 'Vendor', label: 'Vendor' },
];

export const gtpCommunityTypeList: ValueList[] = [
  { value: 'FMS', label: 'FMS' },
  { value: 'VMS', label: 'VMS' },
];

export const slaAccountTypeList: ValueList[] = [
  { value: 'NBD', label: 'NBD' },
  { value: '2NBD', label: '2NBD' },
  { value: '3NBD', label: '3NBD' },
  { value: '5NBD', label: '5NBD' },
  { value: 'SBD2H', label: 'SBD2H' },
  { value: 'SBD6H', label: 'SBD6H' },
  { value: 'SBD4H', label: 'SBD4H' },
];

export const covrageHoursAccountTypeList: ValueList[] = [
  { value: '9H5D', label: '9H5D' },
  { value: 'AFTH', label: 'AFTH' },
  { value: 'WKND', label: 'WKND' },
  { value: 'HLDY', label: 'HLDY' },
  { value: '24x7-DAY', label: '24x7-DAY' }

];

export const displayVendorsiteTypeList: ValueList[] = [
  { value: 'Vendor', label: 'Vendor' },
  { value: 'Vendorsite', label: 'Vendorsite' }

];

export const vendorStatusTypeList: ValueList[] = [
  { value: 'Active', label: 'Active' },
  { value: 'Passive', label: 'Passive' },
];

export const mvvpDisplayTypeList: ValueList[] = [
  { value: 'Vendor', label: 'Vendor' },
  { value: 'MVVP', label: 'MVVP' },
];

export const CandidateTypeList: ValueList[] = [
  { value: 'Worker', label: 'Worker' },
  { value: 'Applicant', label: 'Applicant' },
  { value: 'Candidate Only', label: 'Candidate Only' },
  { value: 'Vendor', label: 'Vendor' }
]

export const technicalLevelList = [
  { value: 'L1', label: 'L1' },
  { value: 'L2', label: 'L2' },
  { value: 'L3', label: 'L3' }
];

export const isBillable: ValueList[] = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" }
];
export const category: ValueList[] = [
  { value: "Fuel/Car Mileage", label: "Fuel/Car Mileage" },
  { value: "Parking", label: "Parking" },
  { value: "Telephone Expense", label: "Telephone Expense" },
  { value: "Internet Expense", label: "Internet Expense" },
  { value: "Tolls/Road Charges", label: "Tolls/Road Charges" },
  { value: "Breakfast", label: "Breakfast" },
  { value: "Lunch", label: "Lunch" },
  { value: "Dinner", label: "Dinner" },
  { value: "Business Meal (Attendees)", label: "Business Meal (Attendees)" },
  { value: "Office Supplies/Software", label: "Office Supplies/Software" },
  { value: "Tools & Materials", label: "Tools & Materials" },
  { value: "Taxi", label: "Taxi" },
  { value: "Train", label: "Train" },
  { value: "Bus", label: "Bus" },
  { value: "Airfare", label: "Airfare" },
  { value: "Hotel/Accommodation", label: "Hotel/Accommodation" },
  { value: "Education & Training", label: "Education & Training" },
  { value: "Miscellaneous Expenses", label: "Miscellaneous Expenses" },
];



export const contactCertification: ValueList[] = [
  { value: 'RN', label: 'RN' },
  { value: 'RN-C', label: 'RN-C' },
  { value: 'RN-BC', label: 'RN-BC' },
  { value: 'CCRN', label: 'CCRN' },
  { value: 'CPN', label: 'CPN' },
  { value: 'Cert 1', label: 'Cert 1' },
];

export const mastersDegree: ValueList[] = [
  { value: 'Master of Accountancy', label: 'Master of Accountancy' },
  { value: 'Master of Architecture', label: 'Master of Architecture' },
  { value: 'Master of Arts', label: 'Master of Arts' },
  { value: 'Master of Biological Science', label: 'Master of Biological Science' },
  { value: 'Master of Business', label: 'Master of Business' },
  { value: 'Master of Business Administration', label: 'Master of Business Administration' },
  { value: 'Master of Business Studies', label: 'Master of Business Studies' },
  { value: 'Master of Chemistry', label: 'Master of Chemistry' },
  { value: 'Master of Clinical Dentistry', label: 'Master of Clinical Dentistry' },
  { value: 'Master of Commerce', label: 'Master of Commerce' },
  { value: 'Master of Computing', label: 'Master of Computing' },
  { value: 'Master of Divinity', label: 'Master of Divinity' },
  { value: 'Master of Drama', label: 'Master of Drama' },
  { value: 'Master of Economic', label: 'Master of Economic' },
  { value: 'Master of Education', label: 'Master of Education' },
  { value: 'Master of Electronic Business', label: 'Master of Electronic Business' },
  { value: 'Master of Engineering', label: 'Master of Engineering' },
  { value: 'Master of Finance', label: 'Master of Finance' },
  { value: 'Master of Fine Arts', label: 'Master of Fine Arts' },
  { value: 'Master of Horticulture (RHS)', label: 'Master of Horticulture (RHS)' },
  { value: 'Master of Jurisprudence', label: 'Master of Jurisprudence' },
  { value: 'Master of Laws', label: 'Master of Laws' },
  { value: 'Master of Letters', label: 'Master of Letters' },
  { value: 'Master of Librarianship', label: 'Master of Librarianship' },
  { value: 'Master of Mathematics', label: 'Master of Mathematics' },
  { value: 'Master of Medical Science', label: 'Master of Medical Science' },
  { value: 'Master of Music', label: 'Master of Music' },
  { value: 'Master of Pharmacy', label: 'Master of Pharmacy' },
  { value: 'Master of Philosophy', label: 'Master of Philosophy' },
  { value: 'Master of Physical Therapy', label: 'Master of Physical Therapy' },
  { value: 'Master of Physics', label: 'Master of Physics' },
  { value: 'Master of Public Health', label: 'Master of Public Health' },
  { value: 'Master of Research', label: 'Master of Research' },
  { value: 'Master of Science', label: 'Master of Science' },
  { value: 'Master of Science in Nursing', label: 'Master of Science in Nursing' },
  { value: 'Master of Science in Physical Therapy', label: 'Master of Science in Physical Therapy' },
  { value: 'Master of Social Science', label: 'Master of Social Science' },
  { value: 'Master of Studies', label: 'Master of Studies' },
  { value: 'Master of Surgery', label: 'Master of Surgery' },
  { value: 'Master of Technology', label: 'Master of Technology' },
  { value: 'Master of Theology', label: 'Master of Theology' },
  { value: 'Master of Voluntary Administration', label: 'Master of Voluntary Administration' },
  { value: 'Master of the University', label: 'Master of the University' },
  { value: 'Mastership in Chemical Analysis', label: 'Mastership in Chemical Analysis' },
];

export const bachelorsDegree: ValueList[] = [
  { value: 'BTEC', label: 'BTEC' },
  { value: 'Bachelor of Architecture', label: 'Bachelor of Architecture' },
  { value: 'Bachelor of Arts', label: 'Bachelor of Arts' },
  { value: 'Bachelor of Arts (Languages)', label: 'Bachelor of Arts (Languages)' },
  { value: 'Bachelor of Business', label: 'Bachelor of Business' },
  { value: 'Bachelor of Business Administration', label: 'Bachelor of Business Administration' },
  { value: 'Bachelor of Chemical Engineering', label: 'Bachelor of Chemical Engineering' },
  { value: 'Bachelor of Chemistry', label: 'Bachelor of Chemistry' },
  { value: 'Bachelor of Civil Engineering', label: 'Bachelor of Civil Engineering' },
  { value: 'Bachelor of Civil Law', label: 'Bachelor of Civil Law' },
  { value: 'Bachelor of Commerce', label: 'Bachelor of Commerce' },
  { value: 'Bachelor of Commerce and Administration', label: 'Bachelor of Commerce and Administration' },
  { value: 'Bachelor of Commerce in Law', label: 'Bachelor of Commerce in Law' },
  { value: 'Bachelor of Computer Applications', label: 'Bachelor of Computer Applications' },
  { value: 'Bachelor of Computer Sciences', label: 'Bachelor of Computer Sciences' },
  { value: 'Bachelor of Computing', label: 'Bachelor of Computing' },
  { value: 'Bachelor of Economics', label: 'Bachelor of Economics' },
  { value: 'Bachelor of Education', label: 'Bachelor of Education' },
  { value: 'Bachelor of Engineering', label: 'Bachelor of Engineering' },
  { value: 'Bachelor of English', label: 'Bachelor of English' },
  { value: 'Bachelor of Fine Arts', label: 'Bachelor of Fine Arts' },
  { value: 'Bachelor of Informatics', label: 'Bachelor of Informatics' },
  { value: 'Bachelor of Laws', label: 'Bachelor of Laws' },
  { value: 'Bachelor of Mathematics', label: 'Bachelor of Mathematics' },
  { value: 'Bachelor of Medical Sciences', label: 'Bachelor of Medical Sciences' },
  { value: 'Bachelor of Medicine and Surgery', label: 'Bachelor of Medicine and Surgery' },
  { value: 'Bachelor of Music', label: 'Bachelor of Music' },
  { value: 'Bachelor of Pharmacy', label: 'Bachelor of Pharmacy' },
  { value: 'Bachelor of Philosophy', label: 'Bachelor of Philosophy' },
  { value: 'Bachelor of Physics', label: 'Bachelor of Physics' },
  { value: 'Bachelor of Physiotherapy', label: 'Bachelor of Physiotherapy' },
  { value: 'Bachelor of Science', label: 'Bachelor of Science' },
  { value: 'Bachelor of Science in Business Administration', label: 'Bachelor of Science in Business Administration' },
  { value: 'Bachelor of Science in Nursing', label: 'Bachelor of Science in Nursing' },
  { value: 'Bachelor of Social Science', label: 'Bachelor of Social Science' },
  { value: 'Bachelor of Surgery', label: 'Bachelor of Surgery' },
  { value: 'Bachelor of Technology', label: 'Bachelor of Technology' },
];

export const contactCountryCodes: ValueList[] = [
  { value: 'Afghanistan (AFG)', label: 'Afghanistan (AFG)' },
  { value: 'Aland Islands(ALA)', label: 'Aland Islands(ALA)' },
  { value: 'Albania (ALB)', label: 'Albania (ALB)' },
  { value: 'Algeria(DZA)', label: 'Algeria(DZA)' },
  { value: 'American Samoa (ASM)', label: 'American Samoa (ASM)' },
  { value: 'Angola(AGO)', label: 'Angola(AGO)' },
  { value: 'Anguilla (AIA)', label: 'Anguilla (AIA)' },
  { value: 'Antigua and Barbuda(ATG)', label: 'Antigua and Barbuda(ATG)' },
  { value: 'Argentina (ARG)', label: 'Argentina (ARG)' },
  { value: 'Armenia(ARM)', label: 'Armenia(ARM)' },
  { value: 'Aruba (ABW)', label: 'Aruba (ABW)' },
  { value: 'Australia(AUS)', label: 'Australia(AUS)' },
  { value: 'Austria (AUT)', label: 'Austria (AUT)' },
  { value: 'Azerbaijan(AZE)', label: 'Azerbaijan(AZE)' },
  { value: 'Bahamas (BHS)', label: 'Bahamas (BHS)' },
  { value: 'Bahrain(BHR)', label: 'Bahrain(BHR)' },
  { value: 'Bangladesh (BGD)', label: 'Bangladesh (BGD)' },
  { value: 'Barbados(BRB)', label: 'Barbados(BRB)' },
  { value: 'Belarus (BLR)', label: 'Belarus (BLR)' },
  { value: 'Belgium(BEL)', label: 'Belgium(BEL)' },
  { value: 'Belize (BLZ)', label: 'Belize (BLZ)' },
  { value: 'Benin(BEN)', label: 'Benin(BEN)' },
  { value: 'Bermuda (BMU)', label: 'Bermuda (BMU)' },
  { value: 'Bhutan(BTN)', label: 'Bhutan(BTN)' },
  { value: 'Bolivia (BOL)', label: 'Bolivia (BOL)' },
  { value: 'Bosnia and Herzegovina(BIH)', label: 'Bosnia and Herzegovina(BIH)' },
  { value: 'Botswana (BWA)', label: 'Botswana (BWA)' },
  { value: 'Brazil(BRA)', label: 'Brazil(BRA)' },
  { value: 'Brunei (BRN)', label: 'Brunei (BRN)' },
  { value: 'Bulgaria(BGR)', label: 'Bulgaria(BGR)' },
  { value: 'Burkina Faso (BFA)', label: 'RN' },
  { value: 'Burundi(BDI)', label: 'Burundi(BDI)' },
  { value: 'Cabo Verde (CPV)', label: 'Cabo Verde (CPV)' },
  { value: 'Cambodia(KHM)', label: 'Cambodia(KHM)' },
  { value: 'Cameroon (CMR)', label: 'Cameroon (CMR)' },
  { value: 'Canada(CAN)', label: 'Canada(CAN)' },
  { value: 'Cayman Islands (CYM)', label: 'Cayman Islands (CYM)' },
  { value: 'Central African Republic(CAF)', label: 'Central African Republic(CAF)' },
  { value: 'Chad (TCD)', label: 'Chad (TCD)' },
  { value: 'Chile(CHL)', label: 'Chile(CHL)' },
  { value: 'China (CHN)', label: 'China (CHN)' },
  { value: 'Christmas Island(CXR)', label: 'Christmas Island(CXR)' },
  { value: 'Colombia (COL)', label: 'Colombia (COL)' },
  { value: 'Comoros(COM)', label: 'Comoros(COM)' },
  { value: 'Congo (COG)', label: 'Congo (COG)' },
  { value: 'Cook Islands(COK)', label: 'Cook Islands(COK)' },
  { value: 'Costa Rica (CRI)', label: 'Costa Rica (CRI)' },
  { value: 'Croatia(HRV)', label: 'Croatia(HRV)' },
  { value: 'Cuba (CUB)', label: 'Cuba (CUB)' },
  { value: 'Cyprus(CYP)', label: 'Cyprus(CYP)' },
  { value: 'Czech Republic (CZE)', label: 'Czech Republic (CZE)' },
  { value: 'Denmark(DNK)', label: 'Denmark(DNK)' },
  { value: 'Djibouti (DJI)', label: 'Djibouti (DJI)' },
  { value: 'Dominica(DMA)', label: 'Dominica(DMA)' },
  { value: 'Dominican Republic (DOM)', label: 'Dominican Republic (DOM)' },
  { value: 'Ecuador(ECU)', label: 'Ecuador(ECU)' },
  { value: 'Egypt (EGY)', label: 'Egypt (EGY)' },
  { value: 'El Salvador(SLV)', label: 'El Salvador(SLV)' },
  { value: 'Equatorial Guinea (GNQ)', label: 'Equatorial Guinea (GNQ)' },
  { value: 'Eritrea(ERI)', label: 'Eritrea(ERI)' },
  { value: 'Estonia (EST)', label: 'Estonia (EST)' },
  { value: 'Ethiopia(ETH)', label: 'Ethiopia(ETH)' },
  { value: 'Falkland Islands (Malvinas) (FLK)', label: 'Falkland Islands (Malvinas) (FLK)' },
  { value: 'Faroe Islands(FRO)', label: 'Faroe Islands(FRO)' },
  { value: 'Fiji (FJI)', label: 'Fiji (FJI)' },
  { value: 'Finland(FIN)', label: 'Finland(FIN)' },
  { value: 'France (FRA)', label: 'France (FRA)' },
  { value: 'French Guiana(GUF)', label: 'French Guiana(GUF)' },
  { value: 'French Polynesia (PYF)', label: 'French Polynesia (PYF)' },
  { value: 'Gabon(GAB)', label: 'Gabon(GAB)' },
  { value: 'Gambia (GMB)', label: 'Gambia (GMB)' },
  { value: 'Georgia(GEO)', label: 'Georgia(GEO)' },
  { value: 'Germany (DEU)', label: 'Germany (DEU)' },
  { value: 'Ghana(GHA)', label: 'Ghana(GHA)' },
  { value: 'Gibraltar (GIB)', label: 'Gibraltar (GIB)' },
  { value: 'Greece(GRC)', label: 'Greece(GRC)' },
  { value: 'Greenland (GRL)', label: 'Greenland (GRL)' },
  { value: 'Grenada(GRD)', label: 'Grenada(GRD)' },
  { value: 'Guadeloupe (GLP)', label: 'Guadeloupe (GLP)' },
  { value: 'Guam(GUM)', label: 'Guam(GUM)' },
  { value: 'Guatemala (GTM)', label: 'Guatemala (GTM)' },
  { value: 'Guernsey(GGY)', label: 'Guernsey(GGY)' },
  { value: 'Guinea-Bissau (GNB)', label: 'Guinea-Bissau (GNB)' },
  { value: 'Guyana(GUY)', label: 'Guyana(GUY)' },
  { value: 'Haiti (HTI)', label: 'Haiti (HTI)' },
  { value: 'Holy See(Vatican)(VAT)', label: 'Holy See(Vatican)(VAT)' },
  { value: 'Honduras (HND)', label: 'Honduras (HND)' },
  { value: 'Hong Kong(HKG)', label: 'Hong Kong(HKG)' },
  { value: 'Hungary (HUN)', label: 'Hungary (HUN)' },
  { value: 'Iceland(ISL)', label: 'Iceland(ISL)' },
  { value: 'India (IND)', label: 'India (IND)' },
  { value: 'Indonesia(IDN)', label: 'Indonesia(IDN)' },
  { value: 'Iran (IRN)', label: 'Iran (IRN)' },
  { value: 'Iraq(IRQ)', label: 'Iraq(IRQ)' },
  { value: 'Ireland (IRL)', label: 'Ireland (IRL)' },
  { value: 'Israel(ISR)', label: 'Israel(ISR)' },
  { value: 'Italy (ITA)', label: 'Italy (ITA)' },
  { value: 'Jamaica(JAM)', label: 'Jamaica(JAM)' },
  { value: 'Japan (JPN)', label: 'Japan (JPN)' },
  { value: 'Jordan(JOR)', label: 'Jordan(JOR)' },
  { value: 'Kazakhstan (KAZ)', label: 'Kazakhstan (KAZ)' },
  { value: 'Kenya(KEN)', label: 'Kenya(KEN)' },
  { value: 'Kiribati (KIR)', label: 'Kiribati (KIR)' },
  { value: 'Kuwait(KWT)', label: 'Kuwait(KWT)' },
  { value: 'Kyrgyzstan (KGZ)', label: 'Kyrgyzstan (KGZ)' },
  { value: 'Lao Peoples Democratic Republic (LAO)', label: 'Lao Peoples Democratic Republic (LAO)' },
  { value: 'Latvia (LVA)', label: 'Latvia (LVA)' },
  { value: 'Lebanon (LBN)', label: 'Lebanon (LBN)' },
  { value: 'Lesotho (LSO)', label: 'Lesotho (LSO)' },
  { value: 'Liberia (LBR)', label: 'Liberia (LBR)' },
  { value: 'Libya (LBY)', label: 'Libya (LBY)' },
  { value: 'Lithuania (LTU)', label: 'Lithuania (LTU)' },
  { value: 'Luxembourg (LUX)', label: 'Luxembourg (LUX)' },
  { value: 'Macau (MAC)', label: 'Macau (MAC)' },
  { value: 'Macedonia (MKD)', label: 'Macedonia (MKD)' },
  { value: 'Madagascar (MDG)', label: 'Madagascar (MDG)' },
  { value: 'Malawi (MWI)', label: 'Malawi (MWI)' },
  { value: 'Malaysia (MYS)', label: 'Malaysia (MYS)' },
  { value: 'Maldives (MDV)', label: 'Maldives (MDV)' },
  { value: 'Mali (MLI)', label: 'Mali (MLI)' },
  { value: 'Malta (MLT)', label: 'Malta (MLT)' },
  { value: 'Marshall Islands (MHL)', label: 'Marshall Islands (MHL)' },
  { value: 'Martinique (MTQ)', label: 'Martinique (MTQ)' },
  { value: 'Mauritania (MRT)', label: 'Mauritania (MRT)' },
  { value: 'Mauritius (MUS)', label: 'Mauritius (MUS)' },
  { value: 'Mayotte (MYT)', label: 'Mayotte (MYT)' },
  { value: 'Mexico (MEX)', label: 'Mexico (MEX)' },
  { value: 'Micronesia (FSM)', label: 'Micronesia (FSM)' },
  { value: 'Moldova (MDA)', label: 'Moldova (MDA)' },
  { value: 'Mongolia (MNG)', label: 'Mongolia (MNG)' },
  { value: 'Montenegro (MNE)', label: 'Montenegro (MNE)' },
  { value: 'Montserrat (MSR)', label: 'Montserrat (MSR)' },
  { value: 'Morocco (MAR)', label: 'Morocco (MAR)' },
  { value: 'Mozambique (MOZ)', label: 'Mozambique (MOZ)' },
  { value: 'Myanmar (MMR)', label: 'Myanmar (MMR)' },
  { value: 'Namibia (NAM)', label: 'Namibia (NAM)' },
  { value: 'Nauru (NRU)', label: 'Nauru (NRU)' },
  { value: 'Nepal (NPL)', label: 'Nepal (NPL)' },
  { value: 'Netherlands (NLD)', label: 'Netherlands (NLD)' },
  { value: 'New Zealand (NZL)', label: 'New Zealand (NZL)' },
  { value: 'Nicaragua (NIC)', label: 'Nicaragua (NIC)' },
  { value: 'Niger (NER)', label: 'Niger (NER)' },
  { value: 'Nigeria (NGA)', label: 'Nigeria (NGA)' },
  { value: 'Northern Mariana Islands (MNP)', label: 'Northern Mariana Islands (MNP)' },
  { value: 'Norway (NOR)', label: 'Norway (NOR)' },
  { value: 'Oman (OMN)', label: 'Oman (OMN)' },
  { value: 'Pakistan (PAK)', label: 'Pakistan (PAK)' },
  { value: 'Palau (PLW)', label: 'Palau (PLW)' },
  { value: 'Palestine (PSE)', label: 'Palestine (PSE)' },
  { value: 'Panama (PAN)', label: 'Panama (PAN)' },
  { value: 'Papua New Guinea (PNG)', label: 'Papua New Guinea (PNG)' },
  { value: 'Paraguay (PRY)', label: 'Paraguay (PRY)' },
  { value: 'Peru (PER)', label: 'Peru (PER)' },
  { value: 'Philippines (PHL)', label: 'Philippines (PHL)' },
  { value: 'Poland (POL)', label: 'Poland (POL)' },
  { value: 'Portugal (PRT)', label: 'Portugal (PRT)' },
  { value: 'Puerto Rico (PRI)', label: 'Puerto Rico (PRI)' },
  { value: 'Qatar (QAT)', label: 'Qatar (QAT)' },
  { value: 'Romania (ROU)', label: 'Romania (ROU)' },
  { value: 'Russian Federation (RUS)', label: 'Russian Federation (RUS)' },
  { value: 'Rwanda (RWA)', label: 'Rwanda (RWA)' },
  { value: 'Saint Helena, Ascension and Tristan da Cunha (SHN)', label: 'Saint Helena, Ascension and Tristan da Cunha (SHN)' },
  { value: 'Saint Kitts and Nevis (KNA)', label: 'Saint Kitts and Nevis (KNA)' },
  { value: 'Saint Lucia (LCA)', label: 'Saint Lucia (LCA)' },
  { value: 'Saint Martin (French part) (MAF)', label: 'Saint Martin (French part) (MAF)' },
  { value: 'Saint Pierre and Miquelon (SPM)', label: 'Saint Pierre and Miquelon (SPM)' },
  { value: 'Saint Vincent and the Grenadines (VCT)', label: 'Saint Vincent and the Grenadines (VCT)' },
  { value: 'San Marino (SMR)', label: 'San Marino (SMR)' },
  { value: 'Sao Tome and Principe (STP)', label: 'Sao Tome and Principe (STP)' },
  { value: 'Saudi Arabia (SAU)', label: 'Saudi Arabia (SAU)' },
  { value: 'Senegal (SEN)', label: 'Senegal (SEN)' },
  { value: 'Serbia (SRB)', label: 'Serbia (SRB)' },
  { value: 'Seychelles (SYC)', label: 'Seychelles (SYC)' },
  { value: 'Sierra Leone (SLE)', label: 'Sierra Leone (SLE)' },
  { value: 'Singapore (SGP)', label: 'Singapore (SGP)' },
  { value: 'Slovakia (SVK)', label: 'Slovakia (SVK)' },
  { value: 'Slovenia (SVN)', label: 'Slovenia (SVN)' },
  { value: 'Solomon Islands (SLB)', label: 'Solomon Islands (SLB)' },
  { value: 'Somalia (SOM)', label: 'Somalia (SOM)' },
  { value: 'South Africa (ZAF)', label: 'South Africa (ZAF)' },
  { value: 'South Korea (KOR)', label: 'South Korea (KOR)' },
  { value: 'Spain (ESP)', label: 'Spain (ESP)' },
  { value: 'Sri Lanka (LKA)', label: 'Sri Lanka (LKA)' },
  { value: 'Sudan (SDN)', label: 'Sudan (SDN)' },
  { value: 'Suriname (SUR)', label: 'Suriname (SUR)' },
  { value: 'Swaziland (SWZ)', label: 'Swaziland (SWZ)' },
  { value: 'Sweden (SWE)', label: 'Sweden (SWE)' },
  { value: 'Switzerland (CHE)', label: 'Switzerland (CHE)' },
  { value: 'Syrian Arab Republic (SYR)', label: 'Syrian Arab Republic (SYR)' },
  { value: 'Taiwan (TWN)', label: 'Taiwan (TWN)' },
  { value: 'Tajikistan (TJK)', label: 'Tajikistan (TJK)' },
  { value: 'Tanzania (TZA)', label: 'Tanzania (TZA)' },
  { value: 'Togo (TGO)', label: 'Togo (TGO)' },
  { value: 'Tonga (TON)', label: 'Tonga (TON)' },
  { value: 'Trinidad and Tobago (TTO)', label: 'Trinidad and Tobago (TTO)' },
  { value: 'Tunisia (TUN)', label: 'Tunisia (TUN)' },
  { value: 'Turkey (TUR)', label: 'Turkey (TUR)' },
  { value: 'Turkmenistan (TKM)', label: 'Turkmenistan (TKM)' },
  { value: 'Turks and Caicos Islands (TCA)', label: 'Turks and Caicos Islands (TCA)' },
  { value: 'Tuvalu (TUV)', label: 'Tuvalu (TUV)' },
  { value: 'Uganda (UGA)', label: 'Uganda (UGA)' },
  { value: 'Ukraine (UKR)', label: 'Ukraine (UKR)' },
  { value: 'United Arab Emirates (ARE)', label: 'United Arab Emirates (ARE)' },
  { value: 'United Kingdom (GBR)', label: 'United Kingdom (GBR)' },
  { value: 'United States of America (USA)', label: 'United States of America (USA)' },
  { value: 'Uruguay (URY)', label: 'Uruguay (URY)' },
  { value: 'Uzbekistan (UZB)', label: 'Uzbekistan (UZB)' },
  { value: 'Vanuatu (VUT)', label: 'Vanuatu (VUT)' },
  { value: 'Venezuela (VEN)', label: 'Venezuela (VEN)' },
  { value: 'Vietnam (VNM)', label: 'Vietnam (VNM)' },
  { value: 'Virgin Islands, British (VGB)', label: 'Virgin Islands, British (VGB)' },
  { value: 'Virgin Islands, U.S. (VIR)', label: 'Virgin Islands, U.S. (VIR)' },
  { value: 'Wallis and Futuna (WLF)', label: 'Wallis and Futuna (WLF)' },
  { value: 'Western Sahara (ESH)', label: 'Western Sahara (ESH)' },
  { value: 'Yemen (YEM)', label: 'Yemen (YEM)' },
  { value: 'Zambia (ZMB)', label: 'Zambia (ZMB)' },
  { value: 'Zimbabwe (ZWE)', label: 'Zimbabwe (ZWE)' },

]
export const vendorPpeHoursList = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' }
];


export const userTypeContact = [
  { value: 'Master Admin', label: 'Master Admin' },
  { value: 'Admin', label: 'Admin' },
  { value: 'Standard', label: 'Standard' },
  { value: 'None', label: 'None' },
  { value: 'Admin User (PMS)', label: 'Admin User (PMS)' },
  { value: 'Admin User (VMS)', label: 'Admin User (VMS)' },
  { value: 'Admin User (MSP)', label: 'Admin User (MSP)' },
  { value: 'Billing', label: 'Billing' }
];

export const UserTypeContactList = [
  { value: 'Customer Primary Contact' , label: 'Customer Primary Contact'},
  { value: 'Customer Renewal Contact' , label: 'Customer Renewal Contact'},
  { value: 'Customer Technical Contact' , label: 'Customer Technical Contact'},
  { value: 'Partner User (Community)' , label: 'Partner User (Community)'},
  { value: 'Registered FE' , label: 'Registered FE'},
  { value: 'Unregistered FE' , label: 'Unregistered FE'},
  { value: 'Vendor Primary Contact' , label: 'Vendor Primary Contact'},
  { value: 'Vendor Technical Contact' , label: 'Vendor Technical Contact'},
  { value: 'Vendor Billing Contact' , label: 'Vendor Billing Contact'},
  { value: 'Customer Billing Contact' , label: 'Customer Billing Contact'},
  { value: 'Dispatch - Named FE Worker Pool' , label: 'Dispatch - Named FE Worker Pool'},
  { value: 'Dedicated – Named FTE Worker Pool' , label: 'Dedicated – Named FTE Worker Pool'},
  { value: 'Vendor Contact (Logistics)' , label: 'Vendor Contact (Logistics)'},
  { value: 'Vendor Contact (Case Dispatch Lead)' , label: 'Vendor Contact (Case Dispatch Lead)'},
  { value: 'Vendor  Contact (Professional Services)' , label: 'Vendor  Contact (Professional Services)'},
  { value: 'Vendor Contact (Case Dispatch Dept.)' , label: 'Vendor Contact (Case Dispatch Dept.)'},
  { value: 'Vendor Contact (Billing Dept)' , label: 'Vendor Contact (Billing Dept)'},
  { value: 'Case Dispatch Dept' , label: 'Case Dispatch Dept'},
  { value: 'Case Dispatch Lead' , label: 'Case Dispatch Lead'},
  { value: 'Partner Contact (Program Manager, Admin)' , label: 'Partner Contact (Program Manager, Admin)'},
  { value: 'Partner Contact (Service Dispatch Manager)' , label: 'Partner Contact (Service Dispatch Manager)'},
  { value: 'Partner Contact (Sales Pricing Manager)' , label: 'Partner Contact (Sales Pricing Manager)'},
  { value: 'Partner Contact (Billing Manager)' , label: 'Partner Contact (Billing Manager)'},
  { value: 'Sales and Solution Member (PMS)' , label: 'Sales and Solution Member (PMS)'},
  { value: 'Procurement &amp; Pricing Member (PMS)' , label: 'Procurement & Pricing Member (PMS)'},
  { value: 'Account Relationship Management Member (PMS)' , label: 'Account Relationship Management Member (PMS)'},
  { value: 'Program Management Member (PMS)' , label: 'Program Management Member (PMS)'},
  { value: 'Customer Renewal Contact' , label: 'Customer Renewal Contact'},
  { value: 'Customer Technical Contact' , label: 'Customer Technical Contact'},
  { value: 'Partner User (Community)' , label: 'Partner User (Community)'},
  { value: 'Dispatch - Standard FE Worker Pool' , label: 'Dispatch - Standard FE Worker Pool'},
  { value: 'Service Delivery Member (PMS)' , label: 'Service Delivery Member (PMS)'},
  { value: 'Billing Member (PMS)' , label: 'Billing Member (PMS)'},
  { value: 'Program Manager, Admin (PMS)' , label: 'Program Manager, Admin (PMS)'},
  { value: 'Sales and Solution Member (MSP)' , label: 'Sales and Solution Member (MSP)'},
  { value: 'Procurement &amp; Pricing Member (MSP)' , label: 'Procurement & Pricing Member (MSP)'},
  { value: 'Account Relationship Management Member (MSP)' , label: 'Account Relationship Management Member (MSP)'},
  { value: 'Program Management Member (MSP)' , label: 'Program Management Member (MSP)'},
  { value: 'Service Delivery Member (MSP)' , label: 'Service Delivery Member (MSP)'},
  { value: 'Billing Member (MSP)' , label: 'Billing Member (MSP)'},
  { value: 'Program Manager, Admin (MSP)' , label: 'Program Manager, Admin (MSP)'},
  { value: 'Partner Contact (New)' , label: 'Partner Contact (New)'},
  { value: 'Sales and Solution Member (VMS)' , label: 'Sales and Solution Member (VMS)'},
  { value: 'Procurement &amp; Pricing Member (VMS)' , label: 'Procurement & Pricing Member (VMS)'},
  { value: 'Account Relationship Management Member (VMS)' , label: 'Account Relationship Management Member (VMS)'},
  { value: 'Program Management Member (VMS)' , label: 'Program Management Member (VMS)'},
  { value: 'Service Delivery Member (VMS)' , label: 'Service Delivery Member (VMS)'},
  { value: 'Billing Member (VMS)' , label: 'Billing Member (VMS)'},
  { value: 'Program Manager, Admin (VMS)' , label: 'Program Manager, Admin (VMS)'},
  { value: 'Worker Resource Pool (VMS)' , label: 'Worker Resource Pool (VMS)'},
  { value: 'Chief Operating Officer' , label: 'Chief Operating Officer'}

]
export const technicianLanguage: ValueList[] = [
  { value: "Local Only", label: "Local Only" },
  { value: "English + Local", label: "English + Local" },
  { value: "English Only", label: "English Only" },
  { value: "Local + Translator", label: "Local + Translator"}
];

export const CoverageHourValues: ValueList[] = [

  { value: '9H5D', label: '9H5D' },
  { value: 'AFTH', label: '24x7-AFTH' },
  { value: 'WKND', label: '24x7-WKND' },
  { value: 'HLDY', label: '24x7-HLDY' },
  { value: '24x7-DAY', label: '24x7-DAY' }
];

export const technicalLevelValues = [
  { value: 'L1', label: 'L1' },
  { value: 'L2', label: 'L2' },
  { value: 'L3', label: 'L3' },
];
export const slaLabourCloudList: ValueList[] = [
  { value: 'NBD', label: 'NBD' },
  { value: '2BD', label: '2BD' },
  { value: '3BD', label: '3BD' },
  { value: '5BD', label: '5BD' },
  { value: '10BD', label: '10BD' },
  { value: 'SBD2H', label: 'SBD2H' },
  { value: 'SBD6H', label: 'SBD6H' },
  { value: 'SBD4H', label: 'SBD4H' },
];

export const probleSourceCategory: ValueList[] = [
  { value: 'People', label: 'People' },
  { value: 'Portal', label: 'Portal' },
  { value: 'Process', label: 'Process' },
  { value: 'Equipment', label: 'Equipment' },
  { value: 'Others', label: 'Others' },
  { value: 'Policy', label: 'Policy' },
];

export const problemResolutionStatus: ValueList[] = [
  { value: '', label: '--None--' },
  { value: 'New (1)', label: 'New (1)' },
  { value: 'Need More Info (1)', label: 'Need More Info (1)' },
  { value: 'Assigned (Not Started) (2)', label: 'Assigned (Not Started) (2)' },
  { value: 'Assigned (On Hold) (2)', label: 'Assigned (On Hold) (2)' },
  { value: 'Assigned (In Progress) (2)', label: 'Assigned (In Progress) (2)' },
  { value: 'Assigned (Challenged) (2)', label: 'Assigned (Challenged) (2)' },
  { value: 'Contained (Resolved, No Change) (4)', label: 'Contained (Resolved, No Change) (4)' },
  { value: 'Contained (Resolved, Change Required) (4)', label: 'Contained (Resolved, Change Required) (4)' },
  { value: 'Contained (Resolved, Change Purposed) (4)', label: 'Contained (Resolved, Change Purposed) (4)' },
  { value: 'Change Approval (Ready for Review) (5)', label: 'Change Approval (Ready for Review) (5)' },
  { value: 'Change Approval (Review in Progress) (5)', label: 'Change Approval (Review in Progress) (5)' },
  { value: 'Change Approval (Review Failed) (5)', label: 'Change Approval (Review Failed) (5)' },
  { value: 'Change Approval (Completed) (5)', label: 'Change Approval (Completed) (5)' },
  { value: 'Implementation (Not Started) (6)', label: 'Implementation (Not Started) (6)' },
  { value: 'Implementation (In Progress) (6)', label: 'Implementation (In Progress) (6)' },
  { value: 'Completed (Awaiting Close) (7)', label: 'Completed (Awaiting Close) (7)' },
  { value: 'Completed (Closed) (7)', label: 'Completed (Closed) (7)' }
];

export const EscalationChangeRequestStatus: ValueList[] = [
  { value: '', label: '--None--' },
  { value: 'New (Default)', label: 'New (Default)' },
  { value: 'Known Issue (No Change Request)', label: 'Known Issue (No Change Request)' },
  { value: 'Known Issue (Escalate>Change Request)', label: 'Known Issue (Escalate>Change Request)' },
  { value: 'New Issue (No Change Request)', label: 'New Issue (No Change Request)' },
  { value: 'New Issue (Escalate>Change Request)', label: 'New Issue (Escalate>Change Request)' },
];

export const problemTypes: ValueList[] = [
  { value: '', label: '--None--' },
  { value: 'Transactional', label: 'Transactional' },
  { value: 'Non Transactional', label: 'Non Transactional' },
];

export const changeRequired: ValueList[] = [
  { value: '', label: '--None--' },
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

export const workerRegStatus: ValueList[] = [
  { value: '', label: '--None--' },
  { value: 'Unregistered', label: 'Unregistered' },
  { value: 'Registered', label: 'Registered' },
];

export const apvpStatus: ValueList[] = [
  { value: '', label: '--None--' },
  { value: 'Approved', label: 'Approved' },
  { value: 'Available', label: 'Available' },
];
export const poPricingType:ValueList[] = [
  { value: 'Monthly', label: 'Monthly' },
  { value: 'Hourly', label: 'Hourly' },
  { value: 'Annual', label: 'Annual' },
  { value: 'Daily', label: 'Daily' },
  { value: 'Half Day', label: 'Half Day' }
]

export const appointmentPlanOption: ValueList[] = [
  { value: 'Option 1: ( Worker Arrival Date time - Customer Requested)- From Appointment', label: 'Option 1: ( Worker Arrival Date time - Customer Requested)- From Appointment' },
  { value: 'Option 2:Custom Window Dates', label: 'Option 2:Custom Window Dates' }
]

export const jobOrderStatus: ValueList[] = [
  { value: 'Open', label: 'Open' },
  // { value: 'In-Progress', label: 'In-Progress' },
  { value: 'On-Hold', label: 'On-Hold' },
  { value: 'Closed', label: 'Closed' },
  // { value: 'Ongoing', label: 'Ongoing' }
]

export const jobOrderStage: ValueList[] = [
  { value: 'New', label: 'New' },
  { value: 'Placements', label: 'Placements' },
  { value: 'Offers', label: 'Offers' },
  { value: 'Interviews', label: 'Interviews' },
  { value: 'Submittals', label: 'Submittals' },
  { value: 'Applications', label: 'Applications' }
]

export const jobOrderPriority: ValueList[] = [
  { value: 'Very Critical (P0)', label: 'Very Critical (P0)' },
  { value: 'Critical (P1)', label: 'Critical (P1)' },
  { value: 'High (P2)', label: 'High (P2)' },
  { value: 'Medium (P3)', label: 'Medium (P3)' },
  { value: 'Low (P4)', label: 'Low (P4)' },
  { value: 'Very Low (P5)', label: 'Very Low (P5)' },
]

export const interviewOverviewTemplate: any[] = [
  {
    tabName: 'Interview Detail',
    tabId : 'interviewDetail',
    fields: [
      {
        type: 'text',
        label: 'Interview ID',
        key: 'Name'
      },
      {
        type: 'date',
        label: 'Date',
        key: 'CreatedDate'
      },
      {
        type: 'text',
        label: 'Email ID',
        key: 'jobApplication.contact.Email'
      },
      {
        type: 'text',
        label: 'Driving Distance From Jobsite',
        key: 'jobApplication.Driving_Distance_From_Jobsite__c'
      },
      {
        type: 'text',
        label: 'Address',
        key: 'jobApplication.Address__c'
      },
      {
        type: 'text',
        label: 'Phone',
        key: 'jobApplication.contact.Phone'
      },
      {
        type: 'text',
        label: 'Driving Time',
        key: 'jobApplication.Driving_Time__c'
      },
      {
        type: 'text',
        label: 'Application Source',
        key: 'jobApplication.source.Name'
      },
      {
        type: 'text',
        label: 'Mobile',
        key: 'jobApplication.contact.MobilePhone'
      },
      {
        type: 'text',
        label: 'Application Stage',
        key: 'jobApplication.Stage__c'
      },
      {
        type: 'text',
        label: 'Application Sub-Stage',
        key: 'jobApplication.Substatus__c'
      },
      {
        type: 'text',
        label: 'Status',
        key: 'Status__c'
      },
      {
        type: 'text',
        label: 'Reject Reason',
        key: 'Reject_Reason__c'
      },
      {
        type: 'text',
        label: 'Reason',
        key: 'Reason__c'
      }
    ]
  },
  {
    tabName: 'Hiring Details',
    tabId : 'hiringDetail',
    fields: [
      {
        type: 'text',
        label: 'Recruiter',
        key: 'jobApplication.primaryRecruiter.Contact_Name__c'
      },
      {
        type: 'text',
        label: 'Primary Recruiter',
      key: 'jobOrder.recruiter.Contact_Name__c'
      },
      {
        type: 'text',
        label: 'Hiring Manager',
        key: 'hrManager.Name'
      },
      {
        type: 'text',
        label: 'Account (Partner Name)',
        key: 'partner.Name'
      },
      {
        type: 'text',
        label: 'Notice Period (days)',
        key: 'jobApplication.contact.Notice__c'
      },
      {
        type: 'text',
        label: 'Employment Status',
        key: 'jobApplication.Application_Status__c'
      },
      {
        type: 'checkbox',
        disabled: true,
        label: 'Internally Applied',
        key: 'jobApplication.Internally_Applied__c'
      },
      {
        type: 'text',
        label: 'Candidate/Vendor, Pay Rate requested',
        key: 'jobApplication.contact.Candidate_Pay_Rate_requested_Vendor_Pay__c'
      },
      {
        type: 'text',
        label: 'Candidate Currency',
        key: 'jobApplication.contact.CurrencyIsoCode'
      },
      {
        type: 'text',
        label: 'Candidate Salary Term',
        key: 'jobApplication.contact.Candidate_Salary_Term__c'
      },
      {
        type: 'text',
        label: 'Vendor Name',
        key: 'jobApplication.agency.Name'
      },
      {
        type: 'text',
        label: 'Reason for Change',
        key: 'jobApplication.contact.Reason_for_Change__c'
      },
      {
        type: 'text',
        label: 'Benefits',
        key: 'jobApplication.contact.Benefits__c'
      },
      {
        type: 'text',
        label: 'FTE Employment Category',
        key: 'jobApplication.FTE_Employment_Category__c'
      }
    ]
  },
  {
    tabName: 'Interview Round 1',
    type:'round',
    tabId : 'round1',
    dropdownValue: { value: 'Client Interview 1' , label: 'Round 1', isDisable: false},
    fields: [{
      type:'round',
      db:'Client Interview 1',
      round:'Round 1'
    }]
  },
  {
    tabName: 'Interview Round 2',
    type:'round',
    tabId : 'round2',
    dropdownValue: { value: 'Client Interview 2' , label: 'Round 2', isDisable: false},
    fields: [{
      type:'round',
      db:'Client Interview 2',
      round:'Round 2'
    }]
  },
  {
    tabName: 'Interview Round 3',
    type:'round',
    tabId : 'round3',
    dropdownValue: { value: 'Client Interview 3' , label: 'Round 3', isDisable: false},
    fields: [{
      type:'round',
      db:'Client Interview 3',
      round:'Round 3'
    }]
  },
  {
    tabName: 'Interview Round 4',
    type:'round',
    tabId : 'round4',
    dropdownValue: { value: 'Client Interview 4' , label: 'Round 4', isDisable: false},
    fields: [{
      type:'round',
      db:'Client Interview 4',
      round:'Round 4'
    }]
  },
  {
    tabName: 'System Information',
    tabId : 'systemInfo',
    fields: [
      {
        type: 'text_date',
        label: 'Created By',
        key: 'CreatedBy__c'
      },
      {
        type: 'text_date',
        label: 'Last Modified By',
        key: 'LastModifiedBy__c'
      }
    ]
  }
]
export const mvvpLanguage:ValueList[] = [
  { value: 'Local Only', label: 'Local Only' },
  { value: 'English + Local', label: 'English + Local' },
  { value: 'English Only', label: 'English Only' },
  { value: 'Local + Translator', label: 'Local + Translator' },
  
]

export const workHistoryFormTemplate: any[] = [
  {
    label: 'Employer Name',
    type: 'input',
    key: 'Name__c',
    required: false
  },
  {
    label: 'Contact Name',
    type: 'text',
    key: 'Contact__c',
    options: [{ label: 'one', value: 1 }, { label: 'two', value: 2 }],
    required: false
  },
  {
    label: 'Job Title',
    type: 'input',
    key: 'Job_Title__c',
    required: false
  },
  {
    label: 'Start Date',
    type: 'calendar',
    key: 'Employment_Start_Date__c',
    required: false
  },
  {
    label: 'Compensation',
    type: 'number',
    key: 'Compensation__c',
    required: false
  },
  {
    label: 'End Date',
    type: 'calendar',
    key: 'Employment_End_Date__c',
    required: false
  },
  {
    label: 'Employer',
    type: 'dropdown',
    key: 'Employer__c',
    options: [{ label: 'one', value: 1 }, { label: 'two', value: 2 }],
    required: false
  },
  {
    label: 'Salaried',
    type: 'checkbox',
    key: 'Salaried__c',
    required: false
  },
  // {
  //   label: 'Parsed',
  //   type: 'checkbox',
  //   key: 'Parsed__c',
  //   required: false
  // },
  {
    label: 'Location',
    type: 'input',
    key: 'Location__c',
    required: false
  },
  {
    label: 'Responsibilities',
    type: 'textBox',
    key: 'Responsibilities__c',
    required: false
  }

]


export const priceListCoverageHoursList: ValueList[] = [
  { value: 'Next Business Day, Business Hours (9H5D)', label: 'Next Business Day, Business Hours (9H5D)' },
  { value: 'Same Day, After Hours (AFTH)', label: 'Same Day, After Hours (AFTH)' },
  { value: 'Same Day, Business Hours (DAY)', label: 'Same Day, Business Hours (DAY)' },
  { value: 'Holiday Coverage (HLDY)', label: 'Holiday Coverage (HLDY)' },
  { value: 'Weekend Coverage (WKND)', label: 'Weekend Coverage (WKND)' },
]

export const priceListTechnicalLevelList: ValueList[] = [
  { value: 'L1', label: 'L1' },
  { value: 'L2', label: 'L2' },
  // { value: 'Not Applicable', label: 'Not Applicable' },
]

export const priceListSLAList: ValueList[] = [
  { value: '2BD', label: '2BD' },
  { value: 'NBD', label: 'NBD' },
  { value: 'NOBKFL', label: 'NOBKFL' },
  { value: 'BKFL', label: 'BKFL' },
  { value: 'SBD6H', label: 'SBD6H' },
  { value: 'SBD4H', label: 'SBD4H' },
  { value: '5BD', label: '5BD' },
  { value: 'Not Applicable', label: 'Not Applicable' },
  { value: '10BD', label: '10BD' },
  { value: '3BD', label: '3BD' },
  { value: 'SBD2H', label: 'SBD2H' },
]
export const TraningPlanStatus: ValueList[] = [
  { value: 'Draft', label: 'Draft' },
  { value: 'Archived', label: 'Archived' },
  { value: 'Active', label: 'Active' }
];

export const LearningRecordTypes: ValueList[] = [
  { value: environment.resouceRecordType, label: 'Resource' },
  { value: environment.quizRecordType, label: 'Quiz' }
];

export const isTestUser: ValueList[] = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" }
];
export const uniqueUsers: ValueList[] = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" }
];

export const uniqueUsersOption: ValueList[] = [
  { value: "false", label: "No" },
  { value: "day", label: "Daily Unique User" },
  { value: "week", label: "Weekly Unique User" },
  { value: "month", label: "Monthly Unique User" },
  { value: "all", label: "All Time Unique User" },
];

export const isActive: ValueList[] = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" }
];

export const addQuizTemplate: any[] = [
  {
    tabName: 'Information',
    tabId: 'information',
    fields: [
      {
        type: 'text',
        label: 'Quiz Title',
        required : true
      },
      {
        type: 'line',
        label: 'Learnings',
        key : 'Name'
      },
      {
        type: 'number',
        label: 'Time Alloted',
        required : false
      },
      {
        type: 'line',
        label: 'Record Type',
        key : 'aptitudeRecordName'
      }
    ]
  },
  {
    tabName: 'Score Information',
    tabId: 'scoreInfo',
    fields: [
      {
        type: 'number',
        label: 'Passing Score',
        required: false
      }
    ]
  }];


  export const questionType: ValueList[] = [
    { value: "Text", label: "Text" },
    { value: "Number", label: "Number" },
    { value: "SelectOneQuestion", label: "SelectOneQuestion" },
    { value: "SelectMultipleQuestion", label: "SelectMultipleQuestion" },
    { value: "Boolean", label: "Boolean" }
  ];

  export const editQuizTemplate: any[] = [
    {
      tabName: 'Information',
      tabId: 'information',
      fields: [
        {
          type: 'text',
          label: 'Quiz Title',
          required : true,
          key : 'Name'
        },
        {
          type: 'line',
          label: 'Learnings',
          key : 'learningsTitle'
        },
        {
          type: 'number',
          label: 'Time Alloted',
          required : false,
          key : 'Time_Allotted__c'
        },
        {
          type: 'line',
          label: 'Record Type',
          key : 'recordType'
        }
      ]
    },
    {
      tabName: 'Score Information',
      tabId: 'scoreInfo',
      fields: [
        {
          type: 'number',
          label: 'Passing Score',
          required: false,
          key: 'Passing_Score__c'
        }
      ]
    }];
export const userType: ValueList[] = [
  { value: '1', label: 'Standard'},
  { value: '2', label: 'Admin'},
  { value: '3', label: 'Super Admin'},
  { value: '4', label: 'MSP (Limited Access)'},
];

export const noticePeriod: ValueList[] = [
  { value: 'None', label: 'None' },
  { value: '1 Week', label: '1 Week' },
  { value: '2 Weeks', label: '2 Weeks' },
  { value: '3 Weeks', label: '3 Weeks' },
  { value: '4 Weeks', label: '4 Weeks' },
  { value: '45 Days', label: '45 Days' },
  { value: '60 Days', label: '60 Days' },
  { value: '90 Days', label: '90 Days' },
  { value: '90+ Days', label: '90+ Days' }
];

export const workAuthorization: ValueList[] = [
  { value: 'I am authorized to work in this country for any employer', label: 'I am authorized to work in this country for any employer' },
  { value: 'I am authorized to work in this country for my present employer only', label: 'I am authorized to work in this country for my present employer only' },
  { value: 'I require sponsorship to work in this country', label: 'I require sponsorship to work in this country' },
  { value: 'My status to work in this country is unknown', label: 'My status to work in this country is unknown' }
];

export const candidateCurrency: ValueList[] = [
  { value: '', label: 'None' },
  { value: 'USD', label: 'U.S. Dollar' },
  { value: 'AUD', label: 'Australian Dollar' },
  { value: 'BRL', label: 'Brazilian Real' },
  { value: 'GBP', label: 'British Pound' },
  { value: 'CAD', label: 'Canadian Dollar' },
  { value: 'DKK', label: 'Danish Krone' },
  { value: 'EUR', label: 'Euro' },
  { value: 'HKD', label: 'Hong Kong Dollar' },
  { value: 'INR', label: 'Indian Rupee' },
  { value: 'IDR', label: 'Indonesian Rupiah' },
  { value: 'JPY', label: 'Japanese Yen' },
  { value: 'MYR', label: 'Malaysian Ringgit' },
  { value: 'NOK', label: 'Norwegian Krone' },
  { value: 'PHP', label: 'Philippine Peso' },
  { value: 'PLN', label: 'Polish Zloty' },
  { value: 'SAR', label: 'Saudi Arabian Riyal' },
  { value: 'SGD', label: 'Singapore Dollar' },
  { value: 'ZAR', label: 'South African Rand' },
  { value: 'SEK', label: 'Swedish Krona' },
  { value: 'CHF', label: 'Swiss Franc' },
  { value: 'THB', label: 'Thai Baht' }
];

export const currentlyWorking: ValueList[] = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' }
 ];

export const slaExceptionList: ValueList[] = [
  { value: 'Service Request Create Issue', label: 'Service Request Create Issue'},
  { value: 'Change Request Issue', label: 'Change Request Issue'},
  { value: 'Requestor Unreachable', label: 'Requestor Unreachable'},
  { value: 'Service Request Invalid', label: 'Service Request Invalid'},
  { value: 'Emergency', label: 'Emergency'},
  { value: 'Others', label: 'Others'}
];

export const salaryTerm: ValueList[] = [
  { value: 'None', label: '--None--' },
  { value: 'Yearly', label: 'Yearly' },
  { value: 'Monthly', label: 'Monthly' },
  { value: 'Weekly', label: 'Weekly' },
  { value: 'Daily', label: 'Daily' },
  { value: 'Half Day', label: 'Half Day' },
  { value: 'Hourly', label: 'Hourly' }
];

export const rejectReason: ValueList[] = [
  { value: '', label: 'None' },
  { value: 'Failed to Meet Basic Qualifications', label: 'Failed to Meet Basic Qualifications' },
  { value: 'Invited to Apply', label: 'Invited to Apply' },
  { value: 'Candidate Withdrew Interest', label: 'Candidate Withdrew Interest' },
  { value: 'No Response', label: 'No Response' },
  { value: 'Not Considered', label: 'Not Considered' },
  { value: 'Not Eligible for Rehire', label: 'Not Eligible for Rehire' },
  { value: 'Failed to meet preferred qualifications', label: 'Failed to meet preferred qualifications' },
  { value: 'More Qualified Candidate selected/no interview', label: 'More Qualified Candidate selected/no interview' },
  { value: 'More Qualified Candidate selected/phone interview', label: 'More Qualified Candidate selected/phone interview' },
  { value: 'More Qualified Candidate selected/interview', label: 'More Qualified Candidate selected/interview' },
  { value: 'More Qualified Candidate selected/background check', label: 'More Qualified Candidate selected/background check' },
  { value: 'Not Hired, Considered for another position', label: 'Not Hired, Considered for another position' },
  { value: 'Offer Extended/Candidate Rejected', label: 'Offer Extended/Candidate Rejected' },
  { value: 'Rolled Over', label: 'Rolled Over' },
  { value: 'Hired', label: 'Hired' },
  { value: 'Blacklisted', label: 'Blacklisted' }
]
export const jobOrderRegionList = [
  { 'sfdcId': 'APAC', 'Name': 'APAC' },
  { 'sfdcId': 'EMEA', 'Name': 'EMEA' },
  { 'sfdcId': 'USCA', 'Name': 'USCA' },
  { 'sfdcId': 'LATAM', 'Name': 'LATAM' },
];

export const vendorContactType: ValueList[] = [
  { value: 'Vendor Contact (Service Dispatch Manager)', label: 'Vendor Contact (Service Dispatch Manager)' },
  { value: 'Vendor Contact (Case Dispatch Dept.)', label: 'Vendor Contact (Case Dispatch Dept.)' },
  { value: 'Vendor Contact (Billing Dept)', label: 'Vendor Contact (Billing Dept)' },
  { value: 'Vendor Contact (Program Manager)', label: 'Vendor Contact (Program Manager)' },
  { value: 'Vendor Contact (Program Director)', label: 'Vendor Contact (Program Director)' }
];

export const jobConsoleMessageTypeList = [
  { value: true, label: 'System Generated' },
  { value: false, label: 'User Created' },
];

export const appStatus = [
  {value: 'App Sent', label: 'App Sent' },
  {value: 'In Review', label: 'In Review' },
  {value: 'Submitted', label: 'Submitted' },
  {value: 'Interviewing', label: 'Interviewing' },
  {value: 'Offer Made', label: 'Offer Made' },
  {value: 'Hired', label: 'Hired' },
  {value: 'App Filed, Not Hired', label: 'App Filed, Not Hired' },
  {value: 'Incomplete', label: 'Incomplete' }
];

export const IncompleteReasonCodeList: ValueList[] = [
  { value: 'Customer rejected', label: 'Customer rejected' },
  { value: 'Emergency', label: 'Emergency' },
  { value: 'Missing Part', label: 'Missing Part' },
  { value: 'Missing Tool', label: 'Missing Tool' },
  { value: 'No access', label: 'No access' },
  { value: 'Weather hazard', label: 'Weather hazard' },
  { value: 'Other', label: 'Other' }
];

export const fteConfigurationType: ValueList[] = [
  {value: 'FTE (with Back Fill)', label: 'FTE (with Back Fill)'},
  {value: 'FTE (No Back Fill)', label: 'FTE (No Back Fill)'},
  {value: 'FSE Backfill Only (No FTE)', label: 'FSE Backfill Only (No FTE)'}
];

export const billingType: ValueList[] = [
  {value: '', label: 'None'},
  {value: 'Auto', label: 'Auto'},
  {value: 'Manual', label: 'Manual'}
];

export const billingCycle: ValueList[] = [
  {value: '', label: 'None'},
  {value: 'Annually (AB)', label: 'Annually (AB)'},
  {value: 'Monthly (MB)', label: 'Monthly (MB)'},
  {value: 'Quarterly (QB)', label: 'Quarterly (QB)'}
];

export const serviceDispatchFrequency: ValueList[] = [
  {value: '', label: 'None'},
  {value: 'Recuring - Weekly', label: 'Recuring - Weekly'},
  {value: 'Recuring - Bi-Weekly', label: 'Recuring - Bi-Weekly'},
  {value: 'Recuring - 4-Weekly', label: 'Recuring - 4-Weekly'},
  {value: 'Recuring - Monthly', label: 'Recuring - Monthly'},
  {value: 'Fixed - Single Event', label: 'Fixed - Single Event'},
  {value: 'Fixed - Multiple Events', label: 'Fixed - Multiple Events'}
];

export const schedule: ValueList[] = [
  {value: '', label: 'None'},
  {value: 'Standard (Fixed)', label: 'Standard (Fixed)'},
  {value: 'Custom', label: 'Custom'}
];

export const monthlyMarginCategory: ValueList[] = [
  {value: '', label: 'None'},
  {value: 'Greater Than 50%', label: 'Greater Than 50%'},
  {value: 'Between 50 % & 25 %', label: 'Between 50 % & 25 %'},
  {value: 'Between 25% & 16.67%', label: 'Between 25% & 16.67%'},
  {value: 'Between 16.67% & 10%', label: 'Between 16.67% & 10%'},
  {value: 'Between 10% & 5 %', label: 'Between 10% & 5 %'},
  {value: 'Between 5% & 0%', label: 'Between 5% & 0%'},
  {value: 'Between 0% & (-5)%', label: 'Between 0% & (-5)%'},
  {value: 'Between (-5)% & (-10) %', label: 'Between (-5)% & (-10) %'},
  {value: 'Between (-10)% & (-25)%', label: 'Between (-10)% & (-25)%'},
  {value: 'Between (-25)% & (-50%)', label: 'Between (-25)% & (-50%)'},
  {value: 'Less Than (-50%)', label: 'Less Than (-50%)'}
];

export const employeeTypeList: ValueList[] = [
  { value: '', label: 'All' },
  { value: 'internal', label: 'Internal' },
  { value: 'external', label: 'External' }
];


export const jobStatusInternalDropDown: ValueList[] = [
  { value : 'New', label : 'New' },
  { value : 'Invited', label : 'Invited' },
  { value : 'Decline', label : 'Decline' },
  { value : 'Accepted', label : 'Accepted' },
  { value : 'Assigned', label : 'Assigned' },
  { value : 'Started', label : 'Started' },
  { value : 'Delivered', label : 'Delivered' },
  { value : 'Completed', label : 'Completed' },
  { value : 'Invoiced', label : 'Invoiced' },
  { value : 'Paid', label : 'Paid' },
  { value : 'Closed', label : 'Closed' },
  { value : 'Cancelled', label : 'Cancelled' }
]

export const JobStatusDropDown: ValueList[] = [
  {value : "New", label : "New"},
  {value : "Assigned", label : "Assigned"},
  {value : "Dispatched", label : "Dispatched"},
  {value : "Travel", label : "Travel"},
  {value : "On Site", label : "On Site"},
  {value : "Completed", label : "Completed"},
  {value : "Incomplete", label : "Incomplete"},
  {value : "Canceled", label : "Cancelled"},
  {value : "Published to Work Market", label : "Published to Work Market"},
  {value : "Receive Updates from Work Market", label : "Receive Updates from Work Market"},
  {value : "Update to Work Market", label : "Update to Work Market"},
  {value : "Invited", label : "Invited"}
]

export const jobResDropDown: ValueList[] = [
  {value : "New", label : "New"},
  {value : "In-Progress", label : "In-Progress"},
  {value : "Resolved", label : "Resolved"},
  {value : "Closed", label : "Closed"},
]
export const requestorDepartmentCategory: any[] = [
  {
    value: '', label: '--None--', subCategory: [
      {
        value: '', label: '--None--', subCategory: [
          { value: '', label: '--None--' }
        ]
      }]
  },
  {
    value: 'ENS', label: 'ENS', subCategory: [
      {
        value: '', label: '--None--', subCategory: [
          { value: '', label: '--None--' }]
      },
      {
        value: 'Deployment', label: 'Deployment', subCategory: [
          { value: '', label: '--None--' },
          { value: 'LASSI Audit <DE-01>', label: 'LASSI Audit <DE-01>' },
          { value: 'No Form Needed', label: 'No Form Needed' },
          { value: 'PATL Remediation <DR-01>', label: 'PATL Remediation <DR-01>' },
          { value: 'Deploy (Gizmo) - No From Needed', label: 'Deploy (Gizmo) - No From Needed' },
          { value: 'Deploy (IP) - No Form Needed', label: 'Deploy (IP) - No Form Needed' },
          { value: 'Deploy (Optical) - No From Needed', label: 'Deploy (Optical) - No From Needed' },
          { value: 'Remediation (DE)', label: 'Remediation (DE)' },
          { value: 'Remediation (PAT)', label: 'Remediation (PAT)' },
          { value: 'Remediation (PC)', label: 'Remediation (PC)' }
        ]
      },
      {
        value: 'FSS (ISG Group)', label: 'FSS (ISG Group)', subCategory: [
          { value: '', label: '--None--' },
          { value: 'Wellness Assessment <FS-01>', label: 'Wellness Assessment <FS-01>' },
          { value: 'Priority Assessment <FS-02>', label: 'Priority Assessment <FS-02>' },
          { value: 'Standards Assessment <FS-03>', label: 'Standards Assessment <FS-03>' },
          { value: 'Wellness Remediation <FS-04>', label: 'Wellness Remediation <FS-04>' },
          { value: 'Priority Remediation <FS-05>', label: 'Priority Remediation <FS-05>' },
          { value: 'Standards Remediation <FS-06>', label: 'Standards Remediation <FS-06>' },
          { value: 'Asset Audit (DCIM) <FS-07>', label: 'Asset Audit (DCIM) <FS-07>' },
          { value: 'No Form Needed', label: 'No Form Needed' },
          { value: 'Houskeeping (Weekly) <FS-08>', label: 'Houskeeping (Weekly) <FS-08>' },
          { value: 'Wellness (Monthly) <FS-09>', label: 'Wellness (Monthly) <FS-09>' },
          { value: 'Standardization (Monthly) <FS-10>', label: 'Standardization (Monthly) <FS-10>' },
          { value: '5MHCK <HC-01>', label: '5MHCK <HC-01>' },
          { value: '10MHCK', label: '10MHCK' }
        ]
      },
      {
        value: 'IBOS', label: 'IBOS', subCategory: [
          { value: '', label: '--None--' },
          { value: 'No Form Needed', label: 'No Form Needed' },
          { value: 'Physical Inventory (Storage Cabinet)', label: 'Physical Inventory (Storage Cabinet)' }
        ]
      },
      {
        value: 'Operations', label: 'Operations', subCategory: [
          { value: '', label: '--None--' },
          { value: 'Break-Fix (ERAD)', label: 'Break-Fix (ERAD)' },
          { value: 'Break-Fix (Reboot)', label: 'Break-Fix (Reboot)' },
          { value: 'Break-Fix (RMA)', label: 'Break-Fix (RMA)' },
          { value: 'No Form Needed', label: 'No Form Needed' }
        ]
      },
      {
        value: 'Provisioning', label: 'Provisioning', subCategory: [
          { value: '', label: '--None--' },
          { value: 'Provisioning BOPP <PR-02>', label: 'Provisioning BOPP <PR-02>' },
          { value: 'Circuit Audit - XConnect <PR-01>', label: 'Circuit Audit - XConnect <PR-01>' },
          { value: 'Pre-Cabling <PR-03>', label: 'Pre-Cabling <PR-03>' },
          { value: 'No Form Needed', label: 'No Form Needed' },
          { value: 'Provisioning (Fiber Project) - No Form Needed', label: 'Provisioning (Fiber Project) - No Form Needed' },
          { value: 'Remediation (Prov) - No From Needed', label: 'Remediation (Prov) - No From Needed' }
        ]
      },
      {
        value: 'RISC', label: 'RISC', subCategory: [
          { value: '', label: '--None--' },
          { value: 'Asset Audit (Ad-Hoc) <RI-05>', label: 'Asset Audit (Ad-Hoc) <RI-05>' },
          { value: 'Facility Datasheet <RI-02>', label: 'Facility Datasheet <RI-02>' },
          { value: 'Infrastructure Inspection <RI-01>', label: 'Infrastructure Inspection <RI-01>' },
          { value: 'Storage Cabinet Inventory <RI-04>', label: 'Storage Cabinet Inventory <RI-04>' },
          { value: 'Household Item Inventory <RI-03>', label: 'Household Item Inventory <RI-03>' },
          { value: 'No Form Needed', label: 'No Form Needed' }
        ]
      },
      {
        value: 'RISC II', label: 'RISC II', subCategory: [
          { value: '', label: '--None--' },
          { value: 'Household Item Inventory <RI-06>', label: 'Household Item Inventory <RI-06>' },
          { value: 'Storage Cabinet Inventory <RI-07>', label: 'Storage Cabinet Inventory <RI-07>' },
          { value: 'Asset Audit <RI-08>', label: 'Asset Audit <RI-08>' },
          { value: 'A4.Infrastructure Inspection <RI-09>', label: 'A4.Infrastructure Inspection <RI-09>' },
          { value: 'A1.General Site Photos <RI-10>', label: 'A1.General Site Photos <RI-10>' }
        ]
      }
    ]
  },
  {
    value: 'ENE', label: 'ENE', subCategory: [
      {
        value: '', label: '--None--', subCategory: [
          { value: '', label: '--None--' }]
      },
      {
        value: 'ENE Deploy', label: 'ENE Deploy', subCategory: [
          { value: '', label: '--None--' },
          { value: 'WAN Circuit Retrofit - No Form Needed', label: 'WAN Circuit Retrofit - No Form Needed' }
        ]
      }
    ]
  },
  {
    value: 'Others', label: 'Others', subCategory: [
      {
        value: '', label: '--None--', subCategory: [
          { value: '', label: '--None--' }]
      },
      {
        value: 'Others', label: 'Others', subCategory: [
          { value: '', label: '--None--' },
          { value: 'No Form Needed', label: 'No Form Needed' }
        ]
      }
    ]
  }
]

export const timeChangeRequestStatusList: ValueList[] = [
  { value: 'All', label: 'All' },
  { value: 'Created', label: 'Created' },
  { value: 'Approved', label: 'Approved' },
  { value: 'Rejected', label: 'Rejected' }
];

export const deliverableStatDropDown: ValueList[] = [
  { value: 'Pending', label: 'Pending' },
  { value: 'Completed', label: 'Completed' },
]

export const jobOrderStatusRmcList: ValueList[] = [
  { value: 'Active', label: 'Active'},
  { value: 'InActive', label: 'InActive'},
];

export const chatBoardbacklogTodo: ValueList[] = [
  { value: 'backlogToday', label: 'MyTask Backlog (New) - Today'},
  { value: 'backlogThisWeek', label: 'MyTask Backlog (New) - This Week'},
  { value: 'backlogDueToday', label: 'MyTask Backlog (Due) - Today'},
  { value: 'backlogPastToday', label: 'MyTask Backlog (Past Due) - Today'},
  { value: 'todoToday', label: 'MyTask ToDo (New) - Today' },
  { value: 'todoThisWeek', label: 'MyTask ToDo (New) - This Week'},
  { value: 'todoDueToday', label: 'MyTask ToDo (Due) - Today'},
  { value: 'todoPastToday', label: 'MyTask ToDo (Past Due) - Today' },
];

export const vcsqdIndustriesList: ValueList[] = [
  { value: 'Promotional Marketing', label: 'Promotional Marketing' },
  { value: 'Technology and Communications', label: 'Technology and Communications' },
  { value: 'Software Development', label: 'Software Development' },
  { value: 'Retail Merchandising and Installation', label: 'Retail Merchandising and Installation' },
  { value: 'Transportation & Logistics', label: 'Transportation & Logistics' },
  { value: 'Administrative Support', label: 'Administrative Support' },
  { value: 'Audio/Visual', label: 'Audio/Visual' },
  { value: 'Business and Financial Services', label: 'Business and Financial Services' },
  { value: 'Consumer Services', label: 'Consumer Services' },
  { value: 'Design and Multimedia Production', label: 'Design and Multimedia Production' },
  { value: 'Digital Signage', label: 'Digital Signage' },
  { value: 'Education', label: 'Education' },
  { value: 'Enterprise Software', label: 'Enterprise Software' },
  { value: 'Facilities and Maintenance', label: 'Facilities and Maintenance' },
  { value: 'Government Services', label: 'Government Services' },
  { value: 'Health and Wellness', label: 'Health and Wellness' },
  { value: 'Home Services', label: 'Home Services' },
  { value: 'Hospitality', label: 'Hospitality' },
  { value: 'Human Resources', label: 'Human Resources' },
  { value: 'Interpretation', label: 'Interpretation' },
  { value: 'Legal Services', label: 'Legal Services' },
  { value: 'Meeting & Event Services', label: 'Meeting & Event Services' },
  { value: 'Personal Services', label: 'Personal Services' },
  { value: 'Property Preservation', label: 'Property Preservation' },
  { value: 'Retail Cosmetics', label: 'Retail Cosmetics' },
  { value: 'Sales and Marketing', label: 'Sales and Marketing' },
  { value: 'Security Services', label: 'Security Services' },
  { value: 'Writing and Translation', label: 'Writing and Translation' },
  { value: 'Other', label: 'Other' }
]
export const workerTypeListPB: ValueList[] = [
  { value: '', label: 'All' },
  { value: 'primary', label: 'Primary' },
  { value: 'backfill', label: 'Backfill' }
];

export const addSVRForm = [
  {
    tabName: 'Worker Info',
    key: 'workerInfo',
    expanded: true,
    value: [
      {
        label: 'Worker Name',
        key: 'worker_name',
        required: false,
        type: 'text'
      },
      {
        label: 'Worker Email ID',
        key: 'worker_email',
        required: false,
        type: 'text'
      },
      {
        label: 'Worker Phone #',
        key: 'worker_phone',
        required: false,
        type: 'text'
      },
      {
        label: 'Worker Mobile OS (Android/iOS)',
        key: 'Worker_Mobile_OS_Android_IOS__c',
        required: true,
        type: 'input'
      },
      {
        label: 'Appointment Start Date (Actual)',
        key: 'Appointment_Start_Date_Actual__c',
        required: true,
        type: 'date'
      },
      {
        label: 'Appointment End Date (Actual)',
        key: 'Appointment_End_Date_Actual__c',
        required: true,
        type: 'date'
      },
      {
        label: 'Activities Details (Actual)',
        key: 'Activities_Details_Actual__c',
        required: true,
        type: 'input'
      },
      {
        label: 'Equipment Make & Model (Activity Performed On)',
        key: 'Equipment_Make_Model_Performed_On__c',
        required: true,
        type: 'input'
      },
      {
        label: 'Equipment Serial # (Activity Performed On)',
        key: 'Equipment_Serial_Performed_On__c',
        required: true,
        type: 'input'
      }
    ]
  },
  {
    tabName: 'Ticket Info',
    key: 'ticketInfo',
    expanded: true,
    value: [
      {
        label: 'End Client Name',
        key: 'end_client_name',
        required: false,
        type: 'text'
      },
      {
        label: 'Jobsite Name',
        key: 'jobsite_name',
        required: false,
        type: 'text'
      },
      {
        label: 'Jobsite Address',
        key: 'jobsite_address',
        required: false,
        type: 'text'
      },
      {
        label: 'Dispatch Ticket Number',
        key: 'Iron_Job_num__c',
        required: false,
        type: 'text'
      },
      {
        label: 'Appointment Start Date (Requested)',
        key: 'Worker_Arrival_DateTime_Cust_Requested__c',
        required: false,
        type: 'text'
      },
      {
        label: 'Appointment End Date (Requested)',
        key: 'Worker_Arrival_Date_Customer_Req_End__c',
        required: false,
        type: 'text'
      },
      {
        label: 'Worker Arrival Time (Requested)',
        key: 'Worker_Arrival_DateTime_Cust_Requested__c',
        required: false,
        type: 'text'
      },
      {
        label: 'Worker Departure Time (Requested)',
        key: 'Worker_Arrival_Date_Customer_Req_End__c',
        required: false,
        type: 'text'
      },
      {
        label: 'Activity Description',
        key: 'activity_desc',
        required: false,
        type: 'text'
      }
    ]
  },
  {
    tabName: 'Customer Site POC Details',
    key: 'pocDetails',
    expanded: true,
    value: [
      {
        label: 'Customer POC Name',
        key: 'Location_Name__c',
        required: false,
        type: 'text'
      },
      {
        label: 'Customer POC Email',
        key: 'Customer_Contact_Email__c',
        required: false,
        type: 'text'
      },
      {
        label: 'Customer POC Phone #',
        key: 'Customer_Contact_Phone__c',
        required: false,
        type: 'text'
      },
      {
        label: 'Verify POC Details',
        key: 'Verify_POC_Details__c',
        required: true,
        type: 'checkbox'
      },
      {
        label: 'Add New POC Name',
        key: '',
        required: false,
        type: 'button'
      }
    ]
  },
  {
    tabName: 'Attachments',
    key: 'attachments',
    expanded: true,
    value: [
      {
        label: 'Add Attachment',
        key: 'attachment',
        required: false,
        type: 'file'
      }
    ]
  }
]
export const contactCaseMethodList = [
  { value: 'Phone', label: 'Phone' },
  { value: 'Email', label: 'Email' },
  { value: 'Other', label: 'Other' }
];


export const allowedVideoTypes: ValueList[] = [
  { value: '.avi', label: 'video/avi' },
  { value: '.mp4', label: 'video/mp4' },
  { value: '.mov', label: 'video/quicktime' },
  { value: '.mpeg', label: 'video/mpeg' },
  { value: '.ogv', label: 'video/ogg' },
  { value: '.webm', label: 'video/webm' },
  { value: '.3gp', label: 'video/3gpp' },
  { value: '.wmv', label: 'video/x-ms-wmv' },
  { value: '.m3u8', label: 'application/x-mpegURL'}
];

export const certification: ValueList[] = [
  { label: 'Apple Technologies', value: 'Apple Technologies' },
  { label: 'Cisco Technologies', value: 'Cisco Technologies' },
  { label: 'Citrix Technologies', value: 'Citrix Technologies' },
  { label: 'Dell Technologies', value: 'Dell Technologies' },
  { label: 'HP Technologies', value: 'HP Technologies' },
  { label: 'IBM Technologies', value: 'IBM Technologies' },
  { label: 'Microsoft Technologies', value: 'Microsoft Technologies' },
  { label: 'Sun Microsystems Technologies', value: 'Sun Microsystems Technologies' },
  { label: 'VMware Technologies', value: 'VMware Technologies' },
  { label: 'Other', value: 'Other' }
];

export const certificationSkillLevel: ValueList[] = [
  { label: 'Beginner', value: 'Beginner' },
  { label: 'Advanced', value: 'Advanced' },
  { label: 'Expert', value: 'Expert' }
];
 
export const completedStatus: ValueList[] = [
  { value: '', label: 'All'},
  { value: '1', label: 'Yes' },
  { value: '0', label: 'No' },
];

export const stageStatus: ValueList[] = [
    { value: '', label: 'All' },
    { value: 'New', label: 'New' },
    { value: 'Accepted', label: 'Accepted' },
    { value: 'Rejected', label: 'Rejected' },
    { value: 'Incomplete', label: 'Incomplete' },
    { value: 'Cancelled', label: 'Cancelled' }
];

export const requirementTypeList: ValueList[] = [
  // { value: '', label: 'All' },
  { value: 'All Primary', label: 'All Primary' },
  { value: 'All Backfill', label: 'All Backfill' },
  { value: 'All Dispatch', label: 'All Dispatch' },
  { value: 'Primary New', label: 'Primary New' },
  { value: 'Primary Replacement', label: 'Primary Replacement' },
  { value: 'Backfill New', label: 'Backfill New' },
  { value: 'Backfill Replacement', label: 'Backfill Replacement' },
  { value: 'Dispatch New', label: 'Dispatch New' },
  { value: 'Dispatch Replacement', label: 'Dispatch Replacement' }
];

export const employmentFilterList: ValueList[] = [
  // { value: '', label: 'All' },
  { value: 'notinternal', label: 'All excluding internal' }
];

export const jobCreationReasonList: ValueList[] = [
  { value: 'New SOW', label: 'New SOW' },
  { value: 'New Jobsite Addition', label: 'New Jobsite Addition' },
  { value: 'Worker Count Increase', label: 'Worker Count Increase' },
  { value: 'Coverage Changed (FSE to FTE)', label: 'Coverage Changed (FSE to FTE)' },
  { value: 'Resigned: Medical/Health (Worker)', label: 'Resigned: Medical/Health (Worker)' },
  { value: 'Resigned: Better Job Offer (Worker)', label: 'Resigned: Better Job Offer (Worker)' },
  { value: 'Resigned: Lack of Amble Growth (Worker)', label: 'Resigned: Lack of Amble Growth (Worker)' },
  { value: 'Resigned: Poor Pay (Worker)', label: 'Resigned: Poor Pay (Worker)' },
  { value: 'Resigned: Hostile Work Environment (Worker)', label: 'Resigned: Hostile Work Environment (Worker)' },
  { value: 'Resigned: Jobsite Culture (Worker)', label: 'Resigned: Jobsite Culture (Worker)' },
  { value: 'Terminated: Poor Performance (PMS)', label: 'Terminated: Poor Performance (PMS)' },
  { value: 'Terminated: Behavioral Issues (PMS)', label: 'Terminated: Behavioral Issues (PMS)' },
  { value: 'Terminated: Serious Malicious (PMS)', label: 'Terminated: Serious Malicious (PMS)' },
  { value: 'Terminated: Poor Performance (MSP)', label: 'Terminated: Poor Performance (MSP)' },
  { value: 'Terminated: Behavioral Issues (MSP)', label: 'Terminated: Behavioral Issues (MSP)' },
  { value: 'Terminated: Serious Malicious (MSP)', label: 'Terminated: Serious Malicious (MSP)' },
  { value: 'Other', label: 'Other' }
];

export const approvalStatusList: ValueList[] = [
  { value: 'Job Requisition Created', label: 'Job Requisition Created' },
  { value: 'Job Requisition Submitted for Review', label: 'Job Requisition Submitted for Review' },
  { value: 'Job Requisition Reviewed', label: 'Job Requisition Reviewed' },
  { value: 'Job Requisition Submitted for Approval', label: 'Job Requisition Submitted for Approval' },
  { value: 'Job Requisition Approved', label: 'Job Requisition Approved' },
  { value: 'Job Requisition Rejected', label: 'Job Requisition Rejected' },
];

export const isServiceoLogin: ValueList[] = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" }
];

export const objectiveType = [
  // { value: '', label: 'All' },
  { value: 'Company', label: 'Company' },
  { value: 'Group', label: 'Group' },
  { value: 'Personal', label: 'Personal' }
];

export const objectiveStatus = [
  // { value: '', label: 'All' },
  { value: 'New', label: 'New' },
  { value: 'In-Progress', label: 'In-Progress' },
  { value: 'Completed', label: 'Completed' },
  { value: 'Canceled', label: 'Canceled' }
];

export const checkInStatus = [
  // { value: '', label: 'All' },
  { value: 'Not Started', label: 'Not Started' },
  { value: 'In Progress', label: 'In Progress' },
  { value: 'Completed', label: 'Completed' }
];

export const meetingStatus = [
  { value: '', label: '--None--' },
  { value: 'New', label: 'New' },
  { value: 'Cancelled', label: 'Cancelled' },
  { value: 'Completed', label: 'Completed' }
];

export const keyResultStatus = [
  // { value: '', label: '--None--' },
  { value: 'Not Started', label: 'Not Started' },
  { value: 'On Track (No Risk)', label: 'On Track (No Risk)' },
  { value: 'Off Track (Risk)', label: 'Off Track (Risk)' }
];

export const checkInFrequency = [
  // { value: '', label: 'All' },
  { value: 'Weekly', label: 'Weekly' },
  { value: 'Monthly', label: 'Monthly' },
  { value: 'Biweekly', label: 'Biweekly' }
];

export const showOnlyJobTypeList = [
  { value: 'applied', label: 'Applied Jobs' },
  { value: 'non-applied', label: 'Non Applied Jobs' }
];

export const jobAssignedList: ValueList[] = [
  // { value: '', label: 'All' },
  { value: 'Assigned To Me', label: 'Assigned To Me' }
];

export const jobPostingTypeList: ValueList[] = [
  { value: 'Dice', label: 'Posted to Dice' },
  { value: 'Internal', label: 'Internal' }
];

export const moveControlsStages = [
  {
    value: 'Application', label: 'Application',
    subStages: [
      {
        value: 'Review', label: 'Review'
      },
      {
        value: 'Prescreening', label: 'Prescreening'
      },
      {
        value: 'Financial Approval', label: 'Financial Approval'
      },
      {
        value: 'On-Hold', label: 'On-Hold'
      },
      {
        value: 'Reject', label: 'Reject'
      }
    ]
  },
  {
    value: 'Submittal', label: 'Submittal',
    subStages: [
      {
        value: 'Internal Review', label: 'Internal Review'
      },
      {
        value: 'Client Review', label: 'Client Review'
      },
      {
        value: 'CV Feedback awaited', label: 'CV Feedback awaited'
      },
      {
        value: 'On-Hold', label: 'On-Hold'
      },
      {
        value: 'Reject', label: 'Reject'
      }
    ]
  },
  {
    value: 'Interview', label: 'Interview',
    subStages: [
      {
        value: 'Client Interview 1', label: 'Client Interview 1'
      },
      {
        value: 'Client Interview 2', label: 'Client Interview 2'
      },
      {
        value: 'Interview Feedback awaited', label: 'Interview Feedback awaited'
      },
      {
        value: 'Interview slot awaited', label: 'Interview slot awaited'
      },
      {
        value: 'On-Hold', label: 'On-Hold'
      },
      {
        value: 'Reject', label: 'Reject'
      },
      {
        value: 'Client Interview 3', label: 'Client Interview 3'
      },
      {
        value: 'Client Interview 4', label: 'Client Interview 4'
      }
    ]
  },
  {
    value: 'Offer', label: 'Offer', subStages: [
      {
        value: 'Gathering Details', label: 'Gathering Details'
      },
      {
        value: 'Sent to Candidate', label: 'Sent to Candidate'
      },
      {
        value: 'Offer Declined', label: 'Offer Declined'
      },
      {
        value: 'On-Hold', label: 'On-Hold'
      }
    ]
  },
  {
    value: 'Placement', label: 'Placement', subStages: [
      {
        value: 'Waiting for Orientation', label: 'Waiting for Orientation'
      },
      {
        value: 'Started', label: 'Started'
      },
      {
        value: 'Awaited Start', label: 'Awaited Start'
      },
      {
        value: 'No Show', label: 'No Show'
      },
      {
        value: 'On-Hold', label: 'On-Hold'
      },
      {
        value: 'Background Verification and Drug Test', label: 'Background Verification and Drug Test'
      }
    ]
  }];
  
export const overallStatus: ValueList[] = [
  // { value: '', label: 'All' },
  { value: 'Active', label: 'Active' },
  { value: 'Hired', label: 'Hired' },
  { value: 'Rejected', label: 'Rejected' },
  { value: 'Incomplete', label: 'Incomplete' },
  { value: 'Cancelled', label: 'Cancelled' }
];

export const assessmentStages: ValueList[] = [
  { value: 'AptitudeTest', label: 'Aptitude Test' },
  { value: 'EnglishTest', label: 'English Test' },
  { value: 'TechnicalTest', label: 'Technical Test' },
  { value: 'SystemInformation', label: 'System Information' }
];

export const submittalStatus: ValueList[] = [
  { value: '', label: 'None' },
  { value: 'New', label: 'New' },
  { value: 'Accepted', label: 'Accepted' },
  { value: 'Rejected', label: 'Rejected' },
  { value: 'Cancelled', label: 'Cancelled' }
];

export const rankList: ValueList[] = [
  { value: '', label: 'None' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' }
]

export const staffingCandidateList: ValueList[] = [
  { value: 'caq', label: 'Candidate Assessment Questions (CAQ)' },
  { value: 'file', label: 'File' },
  { value: 'assessment', label: 'Assessment' },
  { value: 'skill', label: 'Skills' },
  { value: 'workHistory', label: 'History' },
  { value: 'education', label: 'Education' },
  { value: 'references', label: 'References' },
  { value: 'visaProofs', label: 'Visa Proof Attachments' },
  { value: 'notes', label: 'Notes' }
];


export const jobOrderItemFTEUtilzation: ValueList[] = [
  { value: '20', label: '20% (1 Day/Week Equivalent)' },
  { value: '40', label: '40% (2 Days/Week Equivalent)' },
  { value: '50', label: '50% (2.5 Days/Week Equivalent)' },
  { value: '60', label: '60% (3 Days/Week Equivalent)' },
  { value: '90', label: '90% (4 Days/Week Equivalent)' },
  { value: '100', label: '100% (5 Days/Week Equivalent)' },
];

export const jobOrderItemRecordType: ValueList[] = [
  { value: '0122L000000RRFj', label: 'Field Service - Product - 3rd Party - FRU' },
  { value: '0121a000000F1eq', label: 'Field Service - Product - FRU' },
  { value: '0121a0000006Rdh', label: 'Field Service - Product - FSE' },
  { value: '0121a000000eSiQ', label: 'Field Service - Product - FTE' },
  { value: '0121a0000006ROM', label: 'Field Service - Product - FTE Hourly' },
  { value: '0121a000000F27g', label: 'Field Service - Product - Service Logistics(FRU)' },
  { value: '0121a0000006ROR', label: 'Logistics Service - Kit Item' },
  { value: '0121a0000006ROb', label: 'Logistics Service - Product' },
];

export const englishProficiancy: ValueList[] = [
  { value: '0 (No Proficiency)', label: '0 (No Proficiency)' },
  { value: 'A1 (Beginner)', label: 'A1 (Beginner)' },
  { value: 'A2 (Elementary Proficiency)', label: 'A2 (Elementary Proficiency)' },
  { value: 'B1 (Intermediate/Limited Working Proficiency)', label: 'B1 (Intermediate/Limited Working Proficiency)' },
  { value: 'B2 (Upper-Intermediate/Professional Working Proficiency)', label: 'B2 (Upper-Intermediate/Professional Working Proficiency)' },
  { value: 'C1 (Advanced/Full Professional Proficiency)', label: 'C1 (Advanced/Full Professional Proficiency)' },
  { value: 'C2 (Proficiency/Master/Native)', label: 'C2 (Proficiency/Master/Native)' }
]

export const lteCategory: ValueList[] = [
  { value: 'Rehire', label: 'Rehire' },
  { value: 'Rebadged', label: 'Rebadged' },
  { value: 'New Hire', label: 'New Hire' }
 
]



export const interviewStatusRoundForm = [
  {
    value: 'Skills', label: 'Skills',
    formControl: [
      {
        value: [1, 2, 3, 4, 5],
        label: 'Skills',
        type: 'radio',
        key:'Skills_Rating__c'
      },
      {
        label: '',
        type: 'input',
        key:'score_0'
      },
      {
        label: 'Comments',
        type: 'textarea',
        key:'Skill_Comment__c'
      }
    ]
  },
  {
    value: 'Accountability/Results', label: 'Accountability/Results',
    formControl: [
      {
        value: [1, 2, 3, 4, 5],
        label: 'Accountability/Results',
        type: 'radio',
        key:'Accountability_Rating__c'
      },
      {
        label: '',
        type: 'input',
        key:'score_1'
      },
      {

        label: 'Comments',
        type: 'textarea',
        key:'Accountability_Comment__c'
      }
    ]
  },
  {
    value: 'Leadership/Ownership', label: 'Leadership/Ownership',
    formControl: [
      {
        value: [1, 2, 3, 4, 5],
        label: 'Leadership/Ownership',
        type: 'radio',
        key:'Leadership_Rating__c'
      },
      {
        label: '',
        type: 'input',
        key:'score_2'
      },
      {
        label: 'Comments',
        type: 'textarea',
        key:'Leadership_Comment__c'
      }
    ]
  },
  {
    value: 'Innovation', label: 'Innovation',
    formControl: [
      {
        value: [1, 2, 3, 4, 5],
        label: 'Innovation',
        type: 'radio',
        key:'Innovation_Rating__c'
      },
      {
        label: '',
        type: 'input',
        key:'score_3'
      },
      {
        label: 'Comments',
        type: 'textarea',
        key:'Innovation_Comment__c'
      }
    ]
  },
  {
    value: 'Passion', label: 'Passion',
    formControl: [
      {
        value: [1, 2, 3, 4, 5],
        label: 'Passion',
        type: 'radio',
        key:'Passion_Rating__c'
      },
      {
        label: '',
        type: 'input',
        key:'score_4'
      },
      {
        label: 'Comments',
        type: 'textarea',
        key:'Passion_Comment__c'
      }
    ]
  },
  {
    value: 'Drive', label: 'Drive',
    formControl: [
      {
        value: [1, 2, 3, 4, 5],
        label: 'Drive',
        type: 'radio',
        key:'Drive_Rating__c'
      },
      {
        label: '',
        type: 'input',
        key:'score_5'
      },
      {

        label: 'Comments',
        type: 'textarea',
        key:'Drive_Comment__c'
      }
    ]
  },
  {
    value: 'Attitude/Consideration', label: 'Attitude/Consideration',
    formControl: [
      {
        value: [1, 2, 3, 4, 5],
        label: 'Attitude/Consideration',
        type: 'radio',
        key:'Attitude_Rating__c'
      },
      {
        type: 'input',
        label: '',
        key:'score_6'
      },
      {
        type: 'textarea',
        label: 'Comments',
        key:'Attitude_Comment__c'
      }
    ]
  },
  {
    value: 'Communication', label: 'Communication',
    formControl: [
      {
        value: [1, 2, 3, 4, 5],
        label: 'Communication',
        type: 'radio',
        key:'Communication_Rating__c'
      },
      {
        type: 'input',
        label: '',
        key:'score_7'
      },
      {
        type: 'textarea',
        label: 'Comments',
        key:'Communication_Comment__c'
      }
    ]
  },
  {
    value: 'Integrity', label: 'Integrity',
    formControl: [
      {
        value: [1, 2, 3, 4, 5],
        label: 'Integrity',
        type: 'radio',
        key:'Integrity_Rating__c'
      },
      {
        type: 'input',
        label: '',
        key:'score_8'
      },
      {
        type: 'textarea',
        label: 'Comments',
        key:'Integrity_Comment__c'
      }
    ]
  },
  {
    value: 'Adaptability', label: 'Adaptability',
    formControl: [
      {
        value: [1, 2, 3, 4, 5],
        label: 'Adaptability',
        type: 'radio',
        key:'Adaptability_Rating__c'
      },
      {
        type: 'input',
        label: '',
        key:'score_9'
      },
      {
        type: 'textarea',
        label: 'Comments',
        key:'Adaptability_Comment__c'
      }
    ]
  },
  {
    value: 'Customer Service Focus', label: 'Customer Service Focus',
    formControl: [
      {
        value: [1, 2, 3, 4, 5],
        label: 'Customer Service Focus',
        type: 'radio',
        key:'Customer_Service_Focus_Rating__c'
      },
      {
        type: 'input',
        label: '',
        key:'score_10'
      },
      {
        type: 'textarea',
        label: 'Comments',
        key:'Customer_Service_Focus_Comment__c'
      }
    ]
  },
  {
    value: 'id', label: 'id',
    formControl: [
      {
        type: 'hidden',
        label: '',
        key:'id'
      }
    ]
  },
  {
    value: 'Overall Rating', label: 'Overall Rating',
    formControl: [
      {
        value: [1, 2, 3, 4, 5],
        label: 'Overall Rating',
        type: 'radio',
        key:'Overall_Rating__c',
        isDisable:true
      },
      {
        type: 'input',
        label: '',
        key:'Overall_Score__c',
        isDisable:true
      },
      {
        type: 'textarea',
        label: 'Comments',
        key:'Overall_Comments__c',
        required:'yes'
      }
    ]
  }
];

export const yesNoScheduledOptions = [
  { value: 'No', label: 'No' },
  { value: 'Yes', label: 'Yes' },
  { value: 'Scheduled', label: 'Scheduled' }
]

export const interviewTypeList: ValueList[] = [
  { value: 'Phone', label: 'Phone' },
  { value: 'Skype', label: 'Skype' },
  { value: 'In-person', label: 'In-person' },
  { value: 'Web Conference', label: 'Web Conference' }
];
export const interviewFeedbackList: ValueList[] = [
  // { value: 'Pending', label: 'Pending' },
  // { value: 'Interview Scheduled', label: 'Interview Scheduled' },
  { value: 'Shortlisted', label: 'Shortlisted' },
  { value: 'Rejected', label: 'Rejected' },
  { value: 'Waived', label: 'Waived' },
  { value: 'Not Interested', label: 'Not Interested' },
  { value: 'Not Reachable', label: 'Not Reachable' },
  { value: 'Cancelled', label: 'Cancelled' }
];
export const feedbackRecomndation: ValueList[] = [
  { value: "Hire(Yes)", label: "Hire(Yes)" },
  { value: "Don't Hire(No)", label: "Don't Hire(No)" },
  { value: "Further Evaluation(TBD)", label: "Further Evaluation(TBD)" }
];

export const interviewTimeZoneList = [
  { value: 'Pacific/Kiritimati', label: 'Pacific/Kiritimati', Abbr: 'LINT' },
  { value: 'Pacific/Enderbury', label: 'Pacific/Enderbury', Abbr: 'PHOT' },
  { value: 'Pacific/Tongatapu', label: 'Pacific/Tongatapu', Abbr: 'TOT' },
  { value: 'Pacific/Chatham', label: 'Pacific/Chatham', Abbr: 'CHAST' },
  { value: 'Pacific/Auckland', label: 'Pacific/Auckland', Abbr: 'NZST' },
  { value: 'Pacific/Fiji', label: 'Pacific/Fiji', Abbr: 'FJT' },
  { value: 'Asia/Kamchatka', label: 'Asia/Kamchatka', Abbr: 'PETT' },
  { value: 'Pacific/Norfolk', label: 'Pacific/Norfolk', Abbr: 'NFT' },
  { value: 'Australia/Lord_Howe', label: 'Australia/Lord_Howe', Abbr: 'LHST' },
  { value: 'Pacific/Guadalcanal', label: 'Pacific/Guadalcanal', Abbr: 'SBT' },
  { value: 'Australia/Adelaide', label: 'Australia/Adelaide', Abbr: 'ACST' },
  { value: 'Australia/Sydney', label: 'Australia/Sydney', Abbr:'AEST'},
  { value: 'Australia/Brisbane', label: 'Australia/Brisbane', Abbr: 'AEST' },
  { value: 'Australia/Darwin', label: 'Australia/Darwin', Abbr: 'ACST' },
  { value: 'Asia/Seoul', label: 'Asia/Seoul', Abbr: 'KST' },
  { value: 'Asia/Tokyo', label: 'Asia/Tokyo', Abbr: 'JST' },
  { value: 'Asia/Hong_Kong', label: 'Asia/Hong_Kong', Abbr: 'HKT' },
  { value: 'Asia/Kuala_Lumpur', label: 'Asia/Kuala_Lumpur', Abbr: 'MYT' },
  { value: 'Asia/Manila', label: 'Asia/Manila', Abbr: 'PHT' },
  { value: 'Asia/Shanghai', label: 'Asia/Shanghai', Abbr: 'CST' },
  { value: 'Asia/Singapore', label: 'Asia/Singapore', Abbr: 'SGT' },
  { value: 'Asia/Taipei', label: 'Asia/Taipei', Abbr: 'CST' },
  { value: 'Australia/Perth', label: 'Australia/Perth', Abbr: 'AWST' },
  { value: 'Asia/Bangkok', label: 'Asia/Bangkok', Abbr: 'ICT' },
  { value: 'Asia/Ho_Chi_Minh', label: 'Asia/Ho_Chi_Minh', Abbr: 'ICT' },
  { value: 'Asia/Jakarta', label: 'Asia/Jakarta', Abbr: 'WIB' },
  { value: 'Asia/Rangoon', label: 'Asia/Rangoon', Abbr: 'MMT' },
  { value: 'Asia/Dhaka', label: 'Asia/Dhaka', Abbr: 'BST' },
  { value: 'Asia/Kathmandu', label: 'Asia/Kathmandu', Abbr: 'NPT' },
  { value: 'Asia/Colombo', label: 'Asia/Colombo', Abbr: 'IST' },
  { value: 'Asia/Kolkata', label: 'Asia/Kolkata', Abbr: 'IST' },
  { value: 'Asia/Karachi', label: 'Asia/Karachi', Abbr: 'PKT' },
  { value: 'Asia/Tashkent', label: 'Asia/Tashkent', Abbr: 'UZT' },
  { value: 'Asia/Yekaterinburg', label: 'Asia/Yekaterinburg', Abbr: 'YEKT' },
  { value: 'Asia/Kabul', label: 'Asia/Kabul', Abbr: 'AFT' },
  { value: 'Asia/Baku', label: 'Asia/Baku', Abbr: 'AZST' },
  { value: 'Asia/Dubai', label: 'Asia/Dubai', Abbr: 'GST' },
  { value: 'Asia/Tbilisi', label: 'Asia/Tbilisi', Abbr: 'GST' },
  { value: 'Asia/Yerevan', label: 'Asia/Yerevan', Abbr: 'AMT' },
  { value: 'Asia/Tehran', label: 'Asia/Tehran', Abbr: 'IRDT' },
  { value: 'Africa/Nairobi', label: 'Africa/Nairobi', Abbr: 'EAT' },
  { value: 'Asia/Baghdad', label: 'Asia/Baghdad', Abbr: 'AST' },
  { value: 'Asia/Kuwait', label: 'Asia/Kuwait', Abbr: 'AST' },
  { value: 'Asia/Riyadh', label: 'Asia/Riyadh', Abbr: 'AST' },
  { value: 'Europe/Minsk', label: 'Europe/Minsk', Abbr: 'MSK' },
  { value: 'Europe/Moscow', label: 'Europe/Moscow', Abbr: 'MSK' },
  { value: 'Africa/Cairo', label: 'Africa/Cairo', Abbr: 'EEST' },
  { value: 'Asia/Beirut', label: 'Asia/Beirut', Abbr: 'EEST' },
  { value: 'Asia/Jerusalem', label: 'Asia/Jerusalem', Abbr: 'IDT' },
  { value: 'Europe/Athens', label: 'Europe/Athens', Abbr: 'EEST' },
  { value: 'Europe/Bucharest', label: 'Europe/Bucharest', Abbr: 'EEST' },
  { value: 'Europe/Helsinki', label: 'Europe/Helsinki', Abbr: 'EEST' },
  { value: 'Europe/Istanbul', label: 'Europe/Istanbul', Abbr: 'EEST' },
  { value: 'Africa/Johannesburg', label: 'Africa/Johannesburg', Abbr: 'SAST' },
  { value: 'Europe/Amsterdam', label: 'Europe/Amsterdam', Abbr: 'CEST' },
  { value: 'Europe/Berlin', label: 'Europe/Berlin', Abbr: 'CEST' },
  { value: 'Europe/Brussels', label: 'Europe/Brussels', Abbr: 'CEST' },
  { value: 'Europe/Paris', label: 'Europe/Paris', Abbr: 'CEST' },
  { value: 'Europe/Prague', label: 'Europe/Prague', Abbr: 'CEST' },
  { value: 'Europe/Rome', label: 'Europe/Rome', Abbr: 'CEST' },
  { value: 'Europe/Lisbon', label: 'Europe/Lisbon', Abbr: 'CEST' },
  { value: 'Africa/Algiers', label: 'Africa/Algiers', Abbr: 'CET' },
  { value: 'Europe/London', label: 'Europe/London', Abbr: 'BST' },
  { value: 'Atlantic/Cape_Verde', label: 'Atlantic/Cape_Verde', Abbr: 'CVT' },
  { value: 'Africa/Casablanca', label: 'Africa/Casablanca', Abbr: 'WET' },
  { value: 'Europe/Dublin', label: 'Europe/Dublin', Abbr: 'IST' },
  { value: 'GMT', label: 'GMT', Abbr: 'GMT' },
  { value: 'America/Scoresbysund', label: 'America/Scoresbysund', Abbr: 'EGST' },
  { value: 'Atlantic/Azores', label: 'Atlantic/Azores', Abbr: 'AZOST' },
  { value: 'Atlantic/South_Georgia', label: 'Atlantic/South_Georgia', Abbr: 'GST' },
  { value: 'America/St_Johns', label: 'America/St_Johns', Abbr: 'NDT' },
  { value: 'America/Sao_Paulo', label: 'America/Sao_Paulo', Abbr: 'BRST' },
  { value: 'America/Argentina/Buenos_Aires', label: 'America/Argentina/Buenos_Aires', Abbr: 'ART' },
  { value: 'America/Santiago', label: 'America/Santiago', Abbr: 'CLST' },
  { value: 'America/Halifax', label: 'America/Halifax', Abbr: 'ADT' },
  { value: 'America/Puerto_Rico', label: 'America/Puerto_Rico', Abbr: 'AST' },
  { value: 'Atlantic/Bermuda', label: 'Atlantic/Bermuda', Abbr: 'ADT' },
  { value: 'America/Caracas', label: 'America/Caracas', Abbr: 'VET' },
  { value: 'America/Indiana/Indianapolis', label: 'America/Indiana/Indianapolis', Abbr: 'EDT' },
  { value: 'America/New_York', label: 'America/New_York', Abbr: 'EDT' },
  { value: 'America/Bogota', label: 'America/Bogota', Abbr: 'COT' },
  { value: 'America/Lima', label: 'America/Lima', Abbr: 'PET' },
  { value: 'America/Panama', label: 'America/Panama', Abbr: 'EST' },
  { value: 'America/Mexico_City', label: 'America/Mexico_City', Abbr: 'CDT' },
  { value: 'America/Chicago', label: 'America/Chicago', Abbr: 'CDT' },
  { value: 'America/El_Salvador', label: 'America/El_Salvador', Abbr: 'CST' },
  { value: 'America/Denver', label: 'America/Denver', Abbr: 'MDT' },
  { value: 'America/Mazatlan', label: 'America/Mazatlan', Abbr: 'MST' },
  { value: 'America/Phoenix', label: 'America/Phoenix', Abbr: 'MST' },
  { value: 'America/Los_Angeles', label: 'America/Los_Angeles', Abbr: 'PST' },
  { value: 'America/Tijuana', label: 'America/Tijuana', Abbr: 'PST' },
  { value: 'Pacific/Pitcairn', label: 'Pacific/Pitcairn', Abbr: 'PST' },
  { value: 'America/Anchorage', label: 'America/Anchorage', Abbr: 'AKDT' },
  { value: 'Pacific/Gambier', label: 'Pacific/Gambier', Abbr: 'GAMT' },
  { value: 'America/Adak', label: 'America/Adak', Abbr: 'HST' },
  { value: 'Pacific/Marquesas', label: 'Pacific/Marquesas', Abbr: 'MART' },
  { value: 'Pacific/Honolulu', label: 'Pacific/Honolulu', Abbr: 'HST' },
  { value: 'Pacific/Niue', label: 'Pacific/Niue', Abbr: 'NUT' },
  { value: 'Pacific/Pago_Pago', label: 'Pacific/Pago_Pago', Abbr: 'SST' }
];

export const sprintBoard: ValueList[] = [
  { value: 'KeyResultCheckIn', label: 'Key Result Check-Ins' },
  { value: 'HelpDeskProjects', label: 'HelpDesk Projects' },
  { value: 'Both', label: 'Both' }
]

export const accountCommunity: ValueList[] = [
  { value: 'PMS', label: 'PMS' },
  { value: 'MSP', label: 'MSP' },
  { value: 'VMS', label: 'VMS' }
];

export const workerCType: ValueList[] = [
  { value: 'W2', label: 'W2' },
  { value: '1099', label: '1099' },
  { value: 'Vendor', label: 'Vendor' },
  { value: 'Internal Brazil', label: 'Internal Brazil' },
  { value: 'Internal Canada', label: 'Internal Canada' },
  { value: 'Internal India', label: 'Internal India' },
  { value: 'Internal UK', label: 'Internal UK' },
  { value: 'Internal Mexico', label: 'Internal Mexico' }
];

export const MobileOS: ValueList[] = [
  { value: 'iOS', label: 'iOS' },
  { value: 'Android', label: 'Android' },
  { value: 'Other', label: 'Other' }
];

export const typeOfIdStatus: ValueList[] = [
  {
    label: 'Pending',
    value: 'Pending',
  }, {
    label: 'Verified',
    value: 'Verified',
  },
  {
    label: 'Not Verified',
    value: 'Not Verified',
  },
  {
    label: 'Not Provided',
    value: 'Not Provided',
  },
  {
    label: 'Not Required (as per Compliance)',
    value: 'Not Required (as per Compliance)',
  }

];

export const ReasonForDeviation: ValueList[] = [
  { value: 'Emergency (Medical, Disaster, Evacuations)', label: 'Emergency (Medical, Disaster, Evacuations)' },
  { value: 'Special Tools Availability', label: 'Special Tools Availability' },
  { value: 'Standard Tool Availability', label: 'Standard Tool Availability' },
  { value: 'No Site Access', label: 'No Site Access' },
  { value: 'Onboarding Worker Documents Pending', label: 'Onboarding Worker Documents Pending' },
  { value: 'Named Worker Availability', label: 'Named Worker Availability' },
  { value: 'Other', label: 'Other' }
];

export const includeTestAccount: ValueList[] = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" }
];

export const Checkin_Health_Status: ValueList[] = [
  { value: "Healthy", label: "Healthy" },
  { value: "Non-COVID-19 Issue", label: "Non-COVID-19 Issue" },
  { value: "COVID-19 Exposure", label: "COVID-19 Exposure" },
  { value: "COVID-19 Potential", label: "COVID-19 Potential" },
  { value: "COVID-19 Confirmed", label: "COVID-19 Confirmed" }
];

export const recieptTimezoneList: ValueList[] = [
  { value: "IST", label: "IST" },
  { value: "PST", label: "PST" },
  { value: "CST", label: "CST" },
  { value: "EST", label: "EST" }
];
export const mielstoneTaskValues: ValueList[] = [

  { value: 'With Work Order', label: 'With Work Order' },
  { value: 'Without Work Order All', label: 'Without Work Order (All)' },
  { value: 'Without Work Order Planned', label: 'Without Work Order (Planned)' },
  { value: 'Without Work Order Failed', label: 'Without Work Order (Failed to Plan)' },
];

export const formNames: ValueList[] = [
  { value: '10MHCK', label: '10MHCK' },
  { value: '5MHCK <HC-01>', label: '5MHCK <HC-01>' },
  { value: 'A1.General Site Photos<RI-10>', label: 'A1.General Site Photos<RI-10>' },
  { value: 'A4.Infrastructure Inspection <RI-09>', label: 'A4.Infrastructure Inspection <RI-09>' },
  { value: 'Asset Audit (Ad-Hoc) <RI-05>', label: 'Asset Audit (Ad-Hoc) <RI-05>' },
  { value: 'Asset Audit (DCIM) <FS-07>', label: 'Asset Audit (DCIM) <FS-07>' },
  { value: 'Asset Audit <RI-08>', label: 'Asset Audit <RI-08>' },
  { value: 'Break/fix-No Form Needed', label: 'Break/fix-No Form Needed' },
  { value: 'Circuit Audit - XConnect <PR-01>', label: 'Circuit Audit - XConnect <PR-01>' },
  { value: 'Deploy (Gizmo) - No From Needed', label: 'Deploy (Gizmo) - No From Needed' },
  { value: 'Deploy (IP) - No Form Needed', label: 'Deploy (IP) - No Form Needed' },
  { value: 'Deploy (Optical) - No From Needed', label: 'Deploy (Optical) - No From Needed' },
  { value: 'FLM (MACD) - No Form Needed', label: 'FLM (MACD) - No Form Needed' },
  { value: 'FLM Break-Fix (RMA)', label: 'FLM Break-Fix (RMA)' },
  { value: 'FLM Break-Fix - No Form Needed', label: 'FLM Break-Fix - No Form Needed' },
  { value: 'Facility Datasheet <RI-02>', label: 'Facility Datasheet <RI-02>' },
  { value: 'Household Item Inventory <RI-03>', label: 'Household Item Inventory <RI-03>' },
  { value: 'Household Item Inventory <RI-06>', label: 'Household Item Inventory <RI-06>' },
  { value: 'Houskeeping (Weekly) <FS-08>', label: 'Houskeeping (Weekly) <FS-08>' },
  { value: 'Infrastructure Inspection <RI-01>', label: 'Infrastructure Inspection <RI-01>' },
  { value: 'LASSI Audit <DE-01>', label: 'LASSI Audit <DE-01>' },
  { value: 'No Form Needed', label: 'No Form Needed' },
  { value: 'PATL Remediation <DR-01>', label: 'PATL Remediation <DR-01>' },
  { value: 'Physical Inventory (Storage Cabinet)', label: 'Physical Inventory (Storage Cabinet)' },
  { value: 'Pre-Cabling <PR-03>', label: 'Pre-Cabling <PR-03>' },
  { value: 'Priority Assessment <FS-02>', label: 'Priority Assessment <FS-02>' },
  { value: 'Priority Remediation <FS-05>', label: 'Priority Remediation <FS-05>' },
  { value: 'Provisioning (Fiber Project) - No Form Needed', label: 'Provisioning (Fiber Project) - No Form Needed' },
  { value: 'Provisioning BOPP <PR-02>', label: 'Provisioning BOPP <PR-02>' },
  { value: 'Remediation (DE)', label: 'Remediation (DE)' },
  { value: 'Remediation (PAT) - No Form Needed', label: 'Remediation (PAT) - No Form Needed' },
  { value: 'Remediation (PC)', label: 'Remediation (PC)' },
  { value: 'Remediation (Prov)-No Form Needed', label: 'Remediation (Prov)-No Form Needed' },
  { value: 'Standardization (Monthly) <FS-10>', label: 'Standardization (Monthly) <FS-10>' },
  { value: 'Standards Assessment <FS-03>', label: 'Standards Assessment <FS-03>' },
  { value: 'Standards Remediation <FS-06>', label: 'Standards Remediation <FS-06>' },
  { value: 'Storage Cabinet Inventory <RI-04>', label: 'Storage Cabinet Inventory <RI-04>' },
  { value: 'Storage Cabinet Inventory <RI-07>', label: 'Storage Cabinet Inventory <RI-07>' },
  { value: 'WAN Circuit Retrofit - No Form Needed', label: 'WAN Circuit Retrofit - No Form Needed' },
  { value: 'Wellness (Monthly) <FS-09>', label: 'Wellness (Monthly) <FS-09>' },
  { value: 'Wellness Assessment <FS-01>', label: 'Wellness Assessment <FS-01>' },
  { value: 'Wellness Remediation <FS-04>', label: 'Wellness Remediation <FS-04>' },
];

export const gender = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
];
export const userTypeList = [
  { value: 'MSP (Limited Access)', label: 'MSP (Limited Access)' },
  { value: 'Standard', label: 'Standard ' },
];
export const workerRecordTypeList = [
  { value: '0121a0000006Qj7AAE', label: 'Dedicated FTE' },
  { value: '0121a0000006Qj2AAE', label: 'Dispatch FE ' },
]

export const CustomerAppointmentReasonForDeviation: ValueList[] = [
  { value: 'Emergency (Medical, Disaster, Evacuations)', label: 'Emergency (Medical, Disaster, Evacuations)' },
  { value: 'Special Tools Availability', label: 'Special Tools Availability' },
  { value: 'Standard Tool Availability', label: 'Standard Tool Availability' },
  { value: 'No Site Access', label: 'No Site Access' },
  { value: 'Onboarding Worker Documents Pending', label: 'Onboarding Worker Documents Pending' },
  { value: 'Named Worker Availability', label: 'Named Worker Availability' },
  { value: 'Other', label: 'Other' }
];

export const interviewFeedback = [
  // { value: '', label: 'All' },
  { value: 'All (Exluding Cancelled)', label: 'All (Exluding Cancelled)' },
  { value: 'Pending', label: 'Pending' },
  { value: 'Interview Scheduled', label: 'Interview Scheduled' },
  { value: 'Shortlisted', label: 'Shortlisted' },
  { value: 'Rejected', label: 'Rejected' },
  { value: 'Waived', label: 'Waived' },
  { value: 'Not Interested', label: 'Not Interested' },
  { value: 'Not Reachable', label: 'Not Reachable' },
  { value: 'Cancelled', label: 'Cancelled' }
];

export const jobdropDown = [
  { value: 'All', label: 'All' },
  { value: 'Assigned to me', label: 'Assigned to me' },
];

export const interviewRound = [
  { value: '', label: 'All' },
  { value: 'Client Interview 1', label: 'Round 1' },
  { value: 'Client Interview 2', label: 'Round 2' },
  { value: 'Client Interview 3', label: 'Round 3' },
  { value: 'Client Interview 4', label: 'Round 4' }
];

export const communityTypeRmc = [
  { value: '', label: 'All' },
  { value: 'MSP Users (Internal)', label: 'MSP Users (Internal)' },
  { value: 'PMS Users (External)', label: 'PMS Users (External)' },
];

export const resumeFromList = [
  { value: '0', label: 'Today' },
  { value: '1440', label: '1 day' },
  { value: '2880', label: '2 days' },
  { value: '4320', label: '3 days' },
  { value: '8640', label: '1 week' },
  { value: '18720', label: '2 weeks' },
  { value: '41760', label: '1 month' },
  { value: '128160', label: '3 months' },
  { value: '257760', label: '6 months' },
  { value: '387360', label: '9 months' }
]

export const resumeToList = [
  { value: '1', label: 'Within 1 day' },
  { value: '2', label: 'Within 2 days' },
  { value: '3', label: 'Within 3 days' },
  { value: '7', label: 'Within 1 week' },
  { value: '30', label: 'Within 1 month' },
  { value: '90', label: 'Within 3 months' },
  { value: '180', label: 'Within 6 months' },
  { value: '270', label: 'Within 9 months' },
  { value: '365', label: 'Within 12 months' },
  { value: '1095', label: 'Within 36 months' },
]

export const reasonCancellationLists = [
  { value: 'Interviewer Not Available', label: 'Interviewer Not Available' },
  { value: 'Candidate Not Available', label: 'Candidate Not Available' },
  { value: 'Technical Issue', label: 'Technical Issue' },
  { value: 'Other', label: 'Other' }
]

export const cloneTaskAppointmentOptions: ValueList[] = [
  { value: '', label: '--None--' },
  { value: 'Same as Primary Task', label: 'Same as Primary Task' },
  { value: 'Every Working Day', label: 'Every Working Day' },
  { value: 'Every Day (WKND & HLDY)', label: 'Every Day (WKND & HLDY)' },
  { value: 'Weekly', label: 'Weekly' },
  { value: 'Monthly', label: 'Monthly' },
  { value: 'Quarterly', label: 'Quarterly' }
]

export const casePriority: ValueList[] = [
  { value: '', label: '--None--' },
  { value: 'Critical', label: 'Critical (Severity 1)' },
  { value: 'High', label: 'High (Severity 2)' },
  { value: 'Medium', label: 'Medium (Severity 3)' },
  { value: 'Low', label: 'Low (Severity 4)' },
  { value: 'Unknown', label: 'Unknown (Severity 5)' }
]

export const serviceType: ValueList[] = [
  { value: '', label: '--None--' },
  { value: 'Break/Fix Service', label: 'Break/Fix Service' },
  { value: 'Desk-side Support Service', label: 'Desk-side Support Service' },
  { value: 'IMAC (SOP) Service', label: 'IMAC (SOP) Service' },
  { value: 'Audit Survey (SOP & Form) Service', label: 'Audit Survey (SOP & Form) Service' },
  { value: 'Project Management - "Onsite" Service', label: 'Project Management - "Onsite" Service' },
  { value: 'Project Management - "Remote" Service', label: 'Project Management - "Remote" Service' }
]

export const serviceEngineerExpertise: ValueList[] = [
  { value: '', label: '--None--' },
  { value: 'L1', label: 'L1' },
  { value: 'L2', label: 'L2' },
  { value: 'L3', label: 'L3' }
]

export const serviceDispatchSLAPriority: ValueList[] = [
  { value: '', label: '--None--' },
  { value: 'SBD4H', label: 'P1 (SBD4H)' },
  { value: 'SBD6H', label: 'P2 (SBD6H)' },
  { value: 'NBD', label: 'P3 (NBD)' },
  { value: '2BD', label: 'P4 (2BD)' },
  { value: '3BD', label: 'P5 (3BD)' },
  { value: '5BD', label: 'P6 (5BD)' }
]

export const serviceDispatchSLAPriorities: ValueList[] = [
  { value: '', label: '--None--' },
  { value: 'P1 (SBD4H)', label: 'P1 (SBD4H)' },
  { value: 'P2 (SBD6H)', label: 'P2 (SBD6H)' },
  { value: 'P3 (NBD)', label: 'P3 (NBD)' },
  { value: 'P4 (2BD)', label: 'P4 (2BD)' },
  { value: 'P5 (3BD)', label: 'P5 (3BD)' },
  { value: 'P6 (5BD)', label: 'P6 (5BD)' },
]

export const serviceCoverageHoursType: ValueList[] = [
  { value: '', label: '--None--' },
  { value: '9H5D', label: 'Extended Coverage Plan - Standard Business Hours Coverage Plan (24x7-9H5D): Monday-Friday, 9 am to 5pm' },
  { value: '24x7-DAY', label: 'After Hours Coverage (Monday-Friday, 5pm to 9am, 24x7), 24x7-AFTH' },
  { value: '24x7-AFTH', label: 'Extended Coverage Plan - After Hours Coverage (24x7-AFTH):  Monday-Friday, 5pm to 9am' },
  { value: '24x7-WKND', label: 'Extended Coverage Plan - Weekend Hours Coverage (24x7-WKND)' },
  { value: '24x7-HLDY', label: 'Extended Coverage Plan - Holiday Hours Coverage (24x7-HLDY)' }
]

export const serviceEngineerTalentType: ValueList[] = [
  { value: '', label: '--None--' },
  { value: 'a2F1a000000ZJ6wEAG', label: 'Field Service EUC Deskside Support' },
  { value: 'a2F1a000000ZJ71EAG', label: 'Desktop Support' },
  { value: 'a2F1a000000ZJ76EAG', label: 'Desktop Support EUC' },
  { value: 'a2F1a000000ZJ7BEAW', label: 'Help Desk Voice' },
  { value: 'a2F1a000000ZJ7GEAW', label: 'Service Desk Delivery' },
  { value: 'a2F1a000000ZJ7HEAW', label: 'Microsoft Exchange' },
  { value: 'a2F1a000000ZJ7LEAW', label: 'Microsoft Azure Cloud' },
  { value: 'a2F1a000000ZJ7QEAW', label: 'Microsoft System Center OM' },
  { value: 'a2F1a000000ZJ7VEAW', label: 'Microsoft System Center CM' },
  { value: 'a2F1a000000ZJ7aEAG', label: 'Network Storage' },
  { value: 'a2F1a000000ZJ7fEAG', label: 'Network Server' },
  { value: 'a2F1a000000ZJ7kEAG', label: 'Data Network' },
  { value: 'a2F1a000000ZJ7pEAG', label: 'Network Security' },
  { value: 'a2F1a000000ZQurEAG', label: 'FTE for EUC Deskside Support' },
  { value: 'a2F1a000000ZYpjEAG', label: 'Dispatch FTE' },
  { value: 'a2F1a000000Zaz0EAC', label: 'Field Service Server/Storage HW B/F Support' },
  { value: 'a2F1a000000Zdi9EAC', label: 'Not Applicable' },
  { value: 'a2F1a000000ZdiEEAS', label: 'StorSimple Support Upgrade' },
  { value: 'a2F1a000000ZeKuEAK', label: 'Field Service Network Support/Break-Fix' },
  { value: 'a2F1a0000017hLyEAI', label: 'FTE for Server Support' },
  { value: 'a2F1a0000017hM3EAI', label: 'FTE for Network Support' },
  { value: 'a2F1a0000017jUvEAI', label: 'SFDC-SUPPORT' },
  { value: 'a2F2L000001Ox4nUAC', label: 'Field Service Fiber Optics Support' },
  { value: 'a2F2L000001SQucUAG', label: 'FTE for Windows Server Support' },
  { value: 'a2F2L000001vulXUAQ', label: 'Field Service Windows Server Software Break/Fix' },
  { value: 'a2F2L000001vulcUAA', label: 'Field Service Linux Server Software Break/Fix' },
  { value: 'a2F2L000001vulhUAA', label: 'Field Service Storage Equipment Break/Fix' },
  { value: 'a2F2L000001vulmUAA', label: 'Field Service VOIP Equipment Break/Fix' },
  { value: 'a2F2L000001vulrUAA', label: 'Field Service Decommission / Disposal' },
  { value: 'a2F2L000001vulwUAA', label: 'Field Service Site Survey' },
  { value: 'a2F4z000002FFgwEAG', label: 'Light Industrial Labor' }
]

export const vendorWorkerInfoList: ValueList[] = [
  { value: '0011a00000X9fsxAAB - a2y2L000009UQK8QAO', label: 'Demo Test Vendor 11/13/2020 - Kay Singh' }
]

export const annualSalaryIn = [
  { value: 'INR', label: 'INR' },
  { value: 'USD', label: 'USD' },
];

export const noticeList: ValueList[] = [
  { value: 'Any', label: 'Any' },
  { value: 'Currently Serving Notice Period', label: 'Currently Serving Notice Period' },
  { value: '15 days or Less', label: '15 days or Less' },
  { value: '1 month', label: '1 month' },
  { value: '2 months', label: '2 months' },
  { value: '3 months', label: '3 months' },
  { value: 'More than 3 months', label: 'More than 3 months' },
];

export const totalExperienceMin: ValueList[] = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
];

export const totalExperienceMax: ValueList[] = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
];

export const annualSalaryMin: ValueList[] = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '5', label: '5' },
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
];

export const annualSalaryMax: ValueList[] = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '5', label: '5' },
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
];

export const projectStatus: ValueList[] = [
  { value: '', label: '--None--' },
  { value: 'Inactive', label: 'Inactive' },
  { value: 'Planning', label: 'Planning' },
  { value: 'Active', label: 'Active' },
  { value: 'On hold', label: 'On hold' },
  { value: 'Completed', label: 'Completed' }

];
export const healthInsuranceContribution: ValueList[] = [
  { value: '', label: '--None--' },
  { value: '100/100', label: '100/100' },
  { value: '90/60', label: '90/60' },
  { value: '70/50', label: '70/50' },
  { value: '80/0', label: '80/0' },
  { value: '70/0', label: '70/0' },
  { value: 'LTD&STD', label: 'LTD&STD' }
];
export const jobStatusHourly: ValueList[] = [
  { value: '', label: '--None--' },
  { value: 'Hourly', label: 'Hourly' },
  { value: 'Salaried', label: 'Salaried' }
];
export const candidateNowOrInFuture: ValueList[] = [
  { value: '', label: '--None--' },
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' }
]
export const workerApprovalStatus: ValueList[] = [
  { value: '', label: '--None--' },
  { value: 'Pending', label: 'Pending' },
  { value: 'Not Applicable', label: 'Not Applicable' },
  { value: 'Approved', label: 'Approved' },
  { value: 'Rejected', label: 'Rejected' }
];

export const EmployeeTypeList: ValueList[] = [
  { value: '0121a0000006Qj7AAE', label: 'Dedicated FTE' },
  { value: '0121a0000006Qj2AAE', label: 'Dispatch FE' },
  { value: '0121a000000VllKAAS', label: 'MSP User' },
];

export const LeaveStatusTypeList: ValueList[] = [
  { value: 'Approval Pending', label: 'Approval Pending' },
  { value: 'Approved', label: 'Approved' },
  { value: 'Rejected', label: 'Rejected' },
];

export const LeaveTypeList: ValueList[] = [
  { value: 'Full Day', label: 'Full Day' },
  { value: 'First Half', label: 'First Half' },
  { value: 'Second Half', label: 'Second Half' }
 ];

export const releaseNotesTypes: ValueList[] = [
  { value: 'new features', label: 'New Feature' },
  { value: 'bugs', label: 'Bug' },
  { value: 'improvements', label: 'Improvement' }
];

export const pcUserCountList: ValueList[] = [
  { value: '1', label: 'Yes' },
  { value: '0', label: 'No'}
];

export const recordsPerPage: ValueList[] = [
  { value: '25', label: '25' },
  { value: '50', label: '50' },
];

export const radiusInMiles = [
  { value: 1, label: '1 miles' },
  { value: 5, label: '5 miles' },
  { value: 10, label: '10 miles' },
  { value: 20, label: '20 miles' },
  { value: 30, label: '30 miles' },
  { value: 40, label: '40 miles' },
  { value: 50, label: '50 miles' },
  { value: 60, label: '60 miles' },
  { value: 75, label: '75 miles' },
  { value: 100, label: '100 miles' },
];
export const standardBenifitPackage: ValueList[] = [
  { value: '', label: '--None--' },
  { value: 'BITO', label: 'BITO' },
  { value: 'BISO', label: 'BISO' },
  { value: 'BCSP', label: 'BCSP' },
  { value: 'Internal- Management', label: 'Internal- Management' },
  { value: 'Internal- Sr', label: 'Internal- Sr' },
  { value: 'Management', label: 'Management' },
  { value: 'Custom', label: 'Custom' }
];
export const orientationStatus: ValueList[] = [
  { value: '', label: '--None--' },
  { value: 'Pending', label: 'Pending' },
  { value: 'Not Applicable', label: 'Not Applicable' },
  { value: 'Completed', label: 'Completed' },
  { value: 'Rejected', label: 'Rejected' }
];
export const placementStatus: ValueList[] = [
  { value: '', label: '--None--' },
  // { value: 'Rejected', label: 'Rejected' },
  { value: 'Active', label: 'Active' },
  { value: 'Inactive', label: 'Inactive' },
  { value: 'Filled', label: 'Filled' },
  { value: 'New', label: 'New' }

];
export const placementServiceContract: ValueList[] = [
  { value: '', label: '--None--' },
  { value: 'FTE Contract - Fixed Hourly SO & Hourly PO - B1', label: 'FTE Contract - Fixed Hourly SO & Hourly PO - B1' },
  { value: 'FTE Contract - Monthly Fixed SO & Monthly Fixed PO - B2', label: 'FTE Contract - Monthly Fixed SO & Monthly Fixed PO - B2' },
  { value: 'FTE Contract - Monthly Fixed SO & Hourly PO - B3', label: 'FTE Contract - Monthly Fixed SO & Hourly PO - B3' },
  { value: 'FTE Contract - Monthly Hourly SO & Fixed PO - B4', label: 'FTE Contract - Monthly Hourly SO & Fixed PO - B4' },
];
export const vaccinationTaken: ValueList[] = [
  { value: 'Covidshied', label: 'Covishield' },
  { value: 'Covaxin', label: 'Covaxin' },
  { value: 'Sputnik', label: 'Sputnik' },
  { value: 'other', label: 'Other' }
];
export const firstVaccinationStatus: ValueList[] = [
  { value: 'taken', label: 'Taken' },
  { value: 'due', label: 'Due' }
];
export const vaccinationStatusList: ValueList[] = [
  { value: "", label: "None" },
  { value: 'Fully Vaccinated', label: 'Fully Vaccinated' },
  { value: 'Partially Vaccinated', label: 'Partially Vaccinated' },
  { value: 'Not Vaccinated', label: 'Not Vaccinated' }
];
export const secondVaccinationStatus: ValueList[] = [
  { value: 'taken', label: 'Taken' },
  { value: 'due', label: 'Due' }
];
export const educationLevel = [
  { value: -1, label: 'Unspecified' },
  { value: 1, label: 'High School or equivalent' },
  { value: 2, label: 'Certification' },
  { value: 3, label: 'Vocational' },
  { value: 4, label: 'Associate Degree' },
  { value: 5, label: 'Bachelors Degree' },
  { value: 6, label: 'Masters Degree' },
  { value: 7, label: 'Doctorate' },
  { value: 8, label: 'Professional' },
  { value: 9, label: 'Some College Coursework Completed' },
  { value: 12, label: 'Some High School Coursework' }
];

export const pageType: ValueList[] = [
  { value: 'all', label: 'All' },
  { value: 'web', label: 'Only Web' },
  { value: 'mobile', label: 'Only Mobile' },
];

export const weeklyDateRangeList: ValueList[] = [
  {"value": "Current Week","label": "Current Week"},
  {"value": "Last Week","label": "Last Week"},
  {"value": "Current Month", "label": "Current Month"},
  {"value": "Last Month", "label": "Last Month"},
  {"value": "Current Quarter", "label": "Current Quarter"},
  {"value": "Last Quarter", "label": "Last Quarter"}
];

export const vendorTypeList: ValueList[] = [
  {
    value: 'External',
    label: 'External'
  },
  {
    value: 'Internal (Vendor)',
    label: 'Internal (Vendor)'
  },
  {
    value: 'Internal (Iron)',
    label: 'Internal (Iron)'
  }
];

export const distributionTypes: ValueList[] = [
  {
    value: 'Single',
    label: 'Single'
  },
  {
    value: 'Multiple',
    label: 'Multiple'
  },
  {
    value: 'Broadcast',
    label: 'Broadcast'
  }
];

export const healthCheckParam = [
  { label: 'Number of Vendors > 3', status: 'success', field: 'Number_of_Vendors_3__c', value: '' },
  { label: 'Approved VMS Workers Available?', status: 'success', field: 'Count_of_Approved_Worker_VMS__c', value: '' },
  { label: 'Have Program?', status: 'success', field: 'Have_Program__c', value: '' },
  { label: 'Approved VFMS Workers Available?', status: 'success', field: 'Count_of_Approved_Worker_VFMS__c', value: '' },
  { label: 'At least one VFMS Worker', status: 'success', field: 'At_least_one_VFMS_Worker__c', value: '' },
  { label: 'GEO Code Available?', status: 'success', field: 'GEO_Code__c', value: '' },
  { label: 'Approved Vendor Available under APVP', status: 'success', field: 'Health_Vendor_Status__c', value: '' },
  { label: 'Is VFMS Project?', status: 'success', field: 'Is_VFMS_Project__c', value: '' },
  { label: 'Overall health Check for running APVP', status: 'success', field: ['GEO_Code__c', 'Have_Program__c'], value: '' },
];

export const healthRatings: ValueList[] = [
  {
    value: '5',
    label: '(1) Very High = 5'
  },
  {
    value: '4',
    label: '(2) High = 4'
  },
  {
    value: '3',
    label: '(3) Medium = 3'
  },
  {
    value: '2',
    label: '(4) Low = 2'
  },
  {
    value: '1',
    label: '(5) Very Low = 1'
  },
  {
    value: '0',
    label: '(6) New = 0'
  }
];

export const technicalLevelListProgramAPVP: ValueList[] = [
  {
    value: 'L1',
    label: 'L1'
  },
  {
    value: 'L2',
    label: 'L2'
  }
];

export const otherHelpdeskProjects: ValueList[] = [
  { value: '', label: '--None--'},
  { value: 'jobOrderDesk', label: 'Job Order' }
]

export const caseStatusValuesFor3PS: ValueList[] = [
  { value: 'Cancellation Request - MSP', label: 'Cancellation Request - MSP' },
  { value: 'Cancelled (Closed) - MSP', label: 'Cancelled (Closed) - MSP' },
  { value: 'Cancelled (Closed) - PMS', label: 'Cancelled (Closed) - PMS' },
  { value: 'In Progress', label: 'In Progress' },
  { value: 'New', label: 'New' },
  { value: 'Pending (SLA Paused)', label: 'Pending (SLA Paused)' },
  { value: 'Resolved (Job Closed, PMS Open SLA Paused)', label: 'Resolved (Job Closed, PMS Open SLA Paused)' },
];
export const caseStatusValuesForTriage: ValueList[] = [
  { value: 'Cancellation Request - MSP', label: 'Cancellation Request - MSP' },
  { value: 'Cancelled (Closed) - MSP', label: 'Cancelled (Closed) - MSP' },
  { value: 'Cancelled (Closed) - PMS', label: 'Cancelled (Closed) - PMS' },
  { value: 'In Progress', label: 'In Progress' },
];

export const resolutionCodeValues: ValueList[] = [
  { value: 'None', label: 'None' },
  { value: 'Solved with Change / Service Request', label: 'Solved with Change / Service Request' },
  { value: 'Duplicate Ticket', label: 'Duplicate Ticket' },
  { value: 'Capacity', label: 'Capacity' },
  { value: 'Configuration', label: 'Configuration' },
  { value: 'Solved with Workaround', label: 'Solved with Workaround' },
  { value: 'Not Reproducible', label: 'Not Reproducible' },
  { value: 'Wrong Master data', label: 'Wrong Master data' },
  { value: 'No User response', label: 'No User response' },
  { value: 'No action required', label: 'No action required' },
  { value: 'Out of Scope', label: 'Out of Scope' },
  { value: 'Unable to Solve', label: 'Unable to Solve' },
  { value: 'User Instruction / Training', label: 'User Instruction / Training' },
];
export const countryIsdCode: ValueList[] = [
  { value : '+91', label: '(+91) India' },
  { value : '+44', label: '(+44) UK' },
  { value : '+1', label: '(+1) USA' },
  { value : '+47', label: '(+47) Norway' },
  { value : '+213', label: '(+213) Algeria' },
  { value : '+376', label: '(+376) Andorra' },
  { value : '+244', label: '(+244) Angola' },
  { value : '+1264', label: '(+1264) Anguilla' },
  { value : '+1268', label: '(+1268) Antigua & Barbuda' },
  { value : '+54', label: '(+54) Argentina' },
  { value : '+374', label: '(+374) Armenia' },
  { value : '+297', label: '(+297) Aruba' },
  { value : '+61', label: '(+61) Australia' },
  { value : '+43', label: '(+43) Austria' },
  { value : '+994', label: '(+994) Azerbaijan' },
  { value : '+1242', label: '(+1242) Bahamas' },
  { value : '+973', label: '(+973) Bahrain' },
  { value : '+880', label: '(+880) Bangladesh' },
  { value : '+1246', label: '(+1246) Barbados' },
  { value : '+375', label: '(+375) Belarus' },
  { value : '+32', label: '(+32) Belgium' },
  { value : '+501', label: '(+501) Belize' },
  { value : '+229', label: '(+229) Benin' },
  { value : '+1441', label: '(+1441) Bermuda' },
  { value : '+975', label: '(+975) Bhutan' },
  { value : '+591', label: '(+591) Bolivia' },
  { value : '+387', label: '(+387) Bosnia Herzegovina' },
  { value : '+267', label: '(+267) Botswana' },
  { value : '+55', label: '(+55) Brazil' },
  { value : '+673', label: '(+673) Brunei' },
  { value : '+359', label: '(+359) Bulgaria' },
  { value : '+226', label: '(+226) BurkinaFaso' },
  { value : '+257', label: '(+257) Burundi' },
  { value : '+855', label: '(+855) Cambodia' },
  { value : '+237', label: '(+237) Cameroon' },
  { value : '+1', label: '(+1) Canada' },
  { value : '+238', label: '(+238) Cape Verde Islands' },
  { value : '+1345', label: '(+1345) Cayman Islands' },
  { value : '+236', label: '(+236) Central African Republic' },
  { value : '+56', label: '(+56) Chile' },
  { value : '+86', label: '(+86) China' },
  { value : '+57', label: '(+57) Colombia' },
  { value : '+269', label: '(+269) Comoros' },
  { value : '+242', label: '(+242) Congo' },
  { value : '+682', label: '(+682) Cook Islands' },
  { value : '+506', label: '(+506) Costa Rica' },
  { value : '+385', label: '(+385) Croatia' },
  { value : '+53', label: '(+53) Cuba' },
  { value : '+90392', label: '(+90392) Cyprus North' },
  { value : '+357', label: '(+357) Cyprus South' },
  { value : '+42', label: '(+42) Czech Republic' },
  { value : '+45', label: '(+45) Denmark' },
  { value : '+253', label: '(+253) Djibouti' },
  { value : '+1809', label: '(+1809) Dominica' },
  { value : '+1809', label: '(+1809) Dominican Republic' },
  { value : '+593', label: '(+593) Ecuador' },
  { value : '+20', label: '(+20) Egypt' },
  { value : '+503', label: '(+503) El Salvador' },
  { value : '+240', label: '(+240) Equatorial Guinea' },
  { value : '+291', label: '(+291) Eritrea' },
  { value : '+372', label: '(+372) Estonia' },
  { value : '+251', label: '(+251) Ethiopia' },
  { value : '+500', label: '(+500) Falkland Islands' },
  { value : '+298', label: '(+298) Faroe Islands' },
  { value : '+679', label: '(+679) Fiji' },
  { value : '+358', label: '(+358) Finland' },
  { value : '+33', label: '(+33) France' },
  { value : '+594', label: '(+594) French Guiana' },
  { value : '+689', label: '(+689) French Polynesia' },
  { value : '+241', label: '(+241) Gabon' },
  { value : '+220', label: '(+220) Gambia' },
  { value : '+7880', label: '(+7880) Georgia' },
  { value : '+49', label: '(+49) Germany' },
  { value : '+233', label: '(+233) Ghana' },
  { value : '+350', label: '(+350) Gibraltar' },
  { value : '+30', label: '(+30) Greece' },
  { value : '+299', label: '(+299) Greenland' },
  { value : '+1473', label: '(+1473) Grenada' },
  { value : '+590', label: '(+590) Guadeloupe' },
  { value : '+671', label: '(+671) Guam' },
  { value : '+502', label: '(+502) Guatemala' },
  { value : '+224', label: '(+224) Guinea' },
  { value : '+245', label: '(+245) Guinea - Bissau' },
  { value : '+592', label: '(+592) Guyana' },
  { value : '+509', label: '(+509) Haiti' },
  { value : '+504', label: '(+504) Honduras' },
  { value : '+852', label: '(+852) Hong Kong' },
  { value : '+36', label: '(+36) Hungary' },
  { value : '+354', label: '(+354) Iceland' },
  { value : '+62', label: '(+62) Indonesia' },
  { value : '+98', label: '(+98) Iran' },
  { value : '+964', label: '(+964) Iraq' },
  { value : '+353', label: '(+353) Ireland' },
  { value : '+972', label: '(+972) Israel' },
  { value : '+39', label: '(+39) Italy' },
  { value : '+1876', label: '(+1876) Jamaica' },
  { value : '+81', label: '(+81) Japan' },
  { value : '+962', label: '(+962) Jordan' },
  { value : '+7', label: '(+7) Kazakhstan' },
  { value : '+254', label: '(+254) Kenya' },
  { value : '+686', label: '(+686) Kiribati' },
  { value : '+850', label: '(+850) Korea North' },
  { value : '+82', label: '(+82) Korea South' },
  { value : '+965', label: '(+965) Kuwait' },
  { value : '+996', label: '(+996) Kyrgyzstan' },
  { value : '+856', label: '(+856) Laos' },
  { value : '+371', label: '(+371) Latvia' },
  { value : '+961', label: '(+961) Lebanon' },
  { value : '+266', label: '(+266) Lesotho' },
  { value : '+231', label: '(+231) Liberia' },
  { value : '+218', label: '(+218) Libya' },
  { value : '+417', label: '(+417) Liechtenstein' },
  { value : '+370', label: '(+370) Lithuania' },
  { value : '+352', label: '(+352) Luxembourg' },
  { value : '+853', label: '(+853) Macao' },
  { value : '+389', label: '(+389) Macedonia' },
  { value : '+261', label: '(+261) Madagascar' },
  { value : '+265', label: '(+265) Malawi' },
  { value : '+60', label: '(+60) Malaysia' },
  { value : '+960', label: '(+960) Maldives' },
  { value : '+223', label: '(+223) Mali' },
  { value : '+356', label: '(+356) Malta' },
  { value : '+692', label: '(+692) Marshall Islands' },
  { value : '+596', label: '(+596) Martinique' },
  { value : '+222', label: '(+222) Mauritania' },
  { value : '+269', label: '(+269) Mayotte' },
  { value : '+52', label: '(+52) Mexico' },
  { value : '+691', label: '(+691) Micronesia' },
  { value : '+373', label: '(+373) Moldova' },
  { value : '+377', label: '(+377) Monaco' },
  { value : '+976', label: '(+976) Mongolia' },
  { value : '+1664', label: '(+1664) Montserrat' },
  { value : '+212', label: '(+212) Morocco' },
  { value : '+258', label: '(+258) Mozambique' },
  { value : '+95', label: '(+95) Myanmar' },
  { value : '+264', label: '(+264) Namibia' },
  { value : '+674', label: '(+674) Nauru' },
  { value : '+977', label: '(+977) Nepal' },
  { value : '+31', label: '(+31) Netherlands' },
  { value : '+687', label: '(+687) New Caledonia' },
  { value : '+64', label: '(+64) New Zealand' },
  { value : '+505', label: '(+505) Nicaragua' },
  { value : '+227', label: '(+227) Niger' },
  { value : '+234', label: '(+234) Nigeria' },
  { value : '+683', label: '(+683) Niue' },
  { value : '+672', label: '(+672) Norfolk Islands' },
  { value : '+670', label: '(+670) Northern Marianas' },
  { value : '+47', label: '(+47) Norway' },
  { value : '+968', label: '(+968) Oman' },
  { value : '+680', label: '(+680) Palau' },
  { value : '+507', label: '(+507) Panama' },
  { value : '+675', label: '(+675) Papua New Guinea' },
  { value : '+595', label: '(+595) Paraguay' },
  { value : '+51', label: '(+51) Peru' },
  { value : '+63', label: '(+63) Philippines' },
  { value : '+48', label: '(+48) Poland' },
  { value : '+351', label: '(+351) Portugal' },
  { value : '+1787', label: '(+1787) Puerto Rico' },
  { value : '+974', label: '(+974) Qatar' },
  { value : '+262', label: '(+262) Reunion' },
  { value : '+40', label: '(+40) Romania' },
  { value : '+7', label: '(+7) Russia' },
  { value : '+250', label: '(+250) Rwanda' },
  { value : '+378', label: '(+378) San Marino' },
  { value : '+239', label: '(+239) Sao Tome & Principe' },
  { value : '+966', label: '(+966) Saudi Arabia' },
  { value : '+221', label: '(+221) Senegal' },
  { value : '+381', label: '(+381) Serbia' },
  { value : '+248', label: '(+248) Seychelles' },
  { value : '+232', label: '(+232) Sierra Leone' },
  { value : '+65', label: '(+65) Singapore' },
  { value : '+421', label: '(+421) Slovak Republic' },
  { value : '+386', label: '(+386) Slovenia' },
  { value : '+677', label: '(+677) Solomon Islands' },
  { value : '+252', label: '(+252) Somalia' },
  { value : '+27', label: '(+27) South Africa' },
  { value : '+34', label: '(+34) Spain' },
  { value : '+94', label: '(+94) Sri Lanka' },
  { value : '+290', label: '(+290) St. Helena' },
  { value : '+1869', label: '(+1869) St. Kitts' },
  { value : '+1758', label: '(+1758) St. Lucia' },
  { value : '+249', label: '(+249) Sudan' },
  { value : '+597', label: '(+597) Suriname' },
  { value : '+268', label: '(+268) Swaziland' },
  { value : '+46', label: '(+46) Sweden' },
  { value : '+41', label: '(+41) Switzerland' },
  { value : '+963', label: '(+963) Syria' },
  { value : '+886', label: '(+886) Taiwan' },
  { value : '+7', label: '(+7) Tajikstan' },
  { value : '+66', label: '(+66) Thailand' },
  { value : '+228', label: '(+228) Togo' },
  { value : '+676', label: '(+676) Tonga' },
  { value : '+1868', label: '(+1868) Trinidad & Tobago' },
  { value : '+216', label: '(+216) Tunisia' },
  { value : '+90', label: '(+90) Turkey' },
  { value : '+993', label: '(+993) Turkmenistan' },
  { value : '+1649', label: '(+1649) Turks & Caicos Islands' },
  { value : '+688', label: '(+688) Tuvalu' },
  { value : '+256', label: '(+256) Uganda' },
  { value : '+380', label: '(+380) Ukraine' },
  { value : '+971', label: '(+971) United Arab Emirates' },
  { value : '+598', label: '(+598) Uruguay' },
  { value : '+7', label: '(+7) Uzbekistan' },
  { value : '+678', label: '(+678) Vanuatu' },
  { value : '+379', label: '(+379) Vatican City' },
  { value : '+58', label: '(+58) Venezuela' },
  { value : '+84', label: '(+84) Vietnam' },
  { value : '+84', label: '(+1284) Virgin Islands - British' },
  { value : '+84', label: '(+1340) Virgin Islands - US' },
  { value : '+681', label: '(+681) Wallis & Futuna' },
  { value : '+969', label: '(+969) Yemen(North)' },
  { value : '+967', label: '(+967) Yemen(South)' },
  { value : '+260', label: '(+260) Zambia' },
  { value : '+263', label: '(+263) Zimbabwe' },
]

export const conversationSourceValues: ValueList[] = [
  { value: '', label: 'All' },
  { value: 'Web', label: 'Web' },
  { value: 'Mobile', label: 'Mobile' },
  { value: 'Others', label: 'Others' },
];

export const serviceoChatSourceValues: ValueList[] = [
  { value: 'Web', label: 'Web' },
  { value: 'Mobile', label: 'Mobile' },
  { value: 'Team', label: 'Team' },
];

export const joDemandCategory: ValueList[] = [
  { value: "(1) Projects Demand (ISG/BISO/Others)", label: "(1) Projects Demand (ISG/BISO/Others)" },
  { value: "(2) Staffing Placement Demand (GTS)", label: "(2) Staffing Placement Demand (GTS)" },
  { value: "(3) Phantom Demand", label: "(3) Phantom Demand" }
];
export const joDemandSubCategory: ValueList[] = [
  { value: "(3.1) Sales Opportunities Pipeline RFP - Project Pipeline (Phantom)", label: "(3.1) Sales Opportunities Pipeline RFP - Project Pipeline (Phantom))" },
  { value: "(3.2) Sales Forecast - Past Demand Trends (Phantom)", label: "(3.2) Sales Forecast - Past Demand Trends (Phantom)" },
  { value: "(3.3) Sales Forecast - Bench Sales (Phantom)", label: "(3.3) Sales Forecast - Bench Sales (Phantom)" },
  { value: "(3.4) Marketing Forecast - Candidate Database (Phantom)", label: "(3.4) Marketing Forecast - Candidate Database (Phantom)" }
];
export const talentLevels: ValueList[] = [
  { value: '', label: 'None' },
  { value: 'L1', label: 'L1' },
  { value: 'L2', label: 'L2' },
  { value: 'L3', label: 'L3' },
  { value: 'L4', label: 'L4' },
  { value: 'Not Applicable', label: 'Not Applicable' }
];
export const vendorAppliedList: ValueList[] = [
  { value: '1', label: 'Yes' },
  { value: '0', label: 'No' }
];
export const nptProgramList: ValueList[] = [
  { value: '1', label: 'Yes' },
  { value: '0', label: 'No' }
];
export const traningStatusList: ValueList[] = [
  { value: 'All', label: 'All' },
  { value: 'New', label: 'New' },
  { value: 'Accepted', label: 'Accepted' },
  { value: 'In Progress', label: 'In Progress' },
  { value: 'Completed', label: 'Completed' },
  { value: 'Expired', label: 'Expired' },
  { value: 'Due this week', label: 'Due this week' },
  { value: 'Due this month', label: 'Due this month' },
  { value: 'alert24to72', label: 'Expires in 24-72 hrs' }
];

export const channelTypeList: ValueList[] = [
  { value: '', label: '--Select Channel Type--'},
  { value: 'member', label: 'Member Channels – Named Rooms' },
  { value: 'team1', label: 'Team Channel - Unmanaged' },
  { value: 'team2', label: 'Team Channel - Managed (ITSM/RACI)' },
  { value: 'team3', label: 'Team Channel - Managed (ITSM/RACI+KPI)' }
];

export const passiveJobList: ValueList[] = [
  { value: 'All', label: 'All' },
  { value: '1', label: 'Yes' },
  { value: '0', label: 'No' }
];


export const smsTypeList: ValueList[] = [
  { value: 'Appointment Change', label: 'Appointment Change' },
  { value: 'Change Assigned Worker', label: 'Change Assigned Worker' },
  { value: 'Check Worker Availability', label: 'Check Worker Availability' },
  { value: 'New Assign Worker', label: 'New Assign Worker'},
  { value: 'Manual', label: 'Manual'}
];

export const smsStatusList: ValueList[] = [
  { value: 'Scheduled', label: 'Scheduled' },
  { value: 'Sent', label: 'Sent' },
  { value: 'delivered', label: 'Delivered' },
  { value: 'undelivered', label: 'Undelivered' },
  { value: 'failed', label: 'Failed' },
  { value: 'Processing', label: 'Processing' },
  { value: 'Processing-Test', label: 'Processing-Test' },
];

export const communityList: ValueList[] = [
  { value: '001VMS', label: '001VMS' },
  { value: '002VMS', label: '002VMS' },
  { value: '003VMS', label: '003VMS' },
  { value: '013VMS', label: '013VMS' },
  { value: '001CVMS', label: '001CVMS' },
];

export const CommunityDetailsList ={
  '001VMS': 'Worker Dispatch Job Assignment and Job Details Notification',
  '002VMS' : 'Worker Pre-visit Job Assignment and Job Details Notification',
  '003VMS' : 'Worker Dispatch Job & Pre-Visit Job Appointment Reminder',
  '013VMS' : 'Obtain Worker Availability before assigning a Job',
  '001CVMS' : 'Send Manual Message to one or more Phone Numbers',
}

export const hrHelpdeskCategories: ValueList[] = [
  { value: 'payrollTaxation', label: 'Payroll and Taxation' },
  { value: 'performanceMeasurement', label: 'Performance Measurement' },
  { value: 'attendance', label: 'Attendance' },
  { value: 'onboarding', label: 'Onboarding' },
  { value: 'separation', label: 'Separation' },
  { value: 'employeeGrievance', label: 'Employee Grievance' },
  { value: 'generalQuery', label: 'General Query' }
]

export const defaultPageOptionsList = [
  { label: 'Dashboard' ,value:'/vms'},
  { label: 'Checkin-Checkout' , value:'/app/workforce-checkin-checkout'},
  { label: 'Global Chat - Task Conversation Console' , value:'/pms/task-conversation'},
  { label: 'Global Chat v1.1.0 - Personal Conversation' , value:'/app/my-workplace/conversation/personal'},
  { label: 'Job Manager - Job Manager List' , value:'/vms/jobs'},
  { label: 'Recruitment Manager - Recruitment Jobs' , value:'/app/msp-marketplace-recruitment-jobs'},
  { label: 'Delivery Manager - Helpdesk Console' , value:'/pms/help-desk-console-new?consoleType=HelpDesk'},
  { label: 'Delivery Manager - Recruitment Manager Console' , value:'/pms/recruitment-manager-console-new?consoleType=RecruitmentManager'},
  { label: 'Delivery Manager - Task2order Console' , value:'/pms/project-taskCase-management-console?consoleType=TaskCase'},
  { label: 'Service Manager - Dispatch FSE Console' , value:'/pms/service-manager/fse-console'},
  { label: 'VMS Billing Manager - Dispatch Jobs' , value:'/pms/vms-billing-manager?activeId=dispatchJobs'},
  { label: 'WLMS Console - My Training' , value:'/app/training'},
];

export const CandidateNoteType = [
  {
    label: "Shortlisting Status",
    value: ["Recruiter Shortlisted", "Team Lead Shortlisted", "Project Manager Shortlisted", "Rejected"]
  },
  {
    label: "Candidate Contacted",
    value: ["Called", "Emailed", "Texted", "Requested not to Call", "Candidate backed out"]
  },
  {
    label: "Interview Scheduled",
    value: ["R1 interview", "Client interview", "In-Person Interview"]
  },
  {
    label: "Interview Feedback",
    value: ["Positive Interview", "Negative Interview"]
  },
  {
    label: "Follow-up Activities",
    value: ["Follow-up Needed", "Awaiting Response"]
  },
  {
    label: "Offer Process",
    value: ["Selected for Offer", "Offer Accepted", "Offer Extended", "Offer Declined", "Offer Revoked"]
  },
  {
    label: "Background Check",
    value: ["Background Check Initiated", "Background Check Cleared", "Background Check Pending", "Background Check Failed", "HR Blacklist"]
  },
  {
    label: "Onboarding",
    value: ["Onboarding Process Started", "Onboarding Documents Sent", "Onboarding Completed"]
  },
  // {
  //   label: "DNC Notes",
  //   value: ["Enable", "Disable"]
  // },
  {
    label: "Other Notes",
    value: ["Other Notes"]
  }
]